import React from "react";
import core1 from "./Images/Core1.png";
import core2 from "./Images/Core2.png";
import core3 from "./Images/Core3.png";
import core4 from "./Images/Core4.png";
import core5 from "./Images/Core5.png";
import core6 from "./Images/Core6.png";
const Blogdetails6 = () => {
  return (
    <>
      <div className="container blogdetails">
        <div className="article-header mt-5">
          <h1>Core Interface</h1>
          <br />
          <div className="bloginfo">
            <p>
              <strong>Author Name:</strong>Avadhut Adake
            </p>
            <p>Publish Date: September 11, 2024</p>
          </div>
          <p>CORE INTERFACE</p>
          <p>
            SAP APO, integrated with ERP systems like SAP R/3 or SAP ECC,
            optimizes supply chain processes through planning and optimization.
            The Core Interface (CIF) bridges the source (ERP) and target (APO)
            systems, facilitating the transfer of master and transactional data
            using integration models. This ensures seamless data exchange
            between the systems, enabling efficient planning and execution of
            business processes across the supply chain.
          </p>
          <br />
          <p>
            SAP APO is a software solution utilized for the efficient planning
            and optimization of various business processes across the entire
            supply chain.
            <br />
            Typically, SAP APO is integrated with an ERP system such as SAP R/3
            or SAP ECC, which supplies it with essential master data. After the
            planning phase, results are then transferred back to the ERP system
            for subsequent processing and execution.
          </p>
          <br />
          <p>
            In this blog, we will see an overview about the Core Interface with
            below points: -
          </p>
          <br />
          <p>1. What is Core Interface?</p>
          <br />
          <p>2. Functions of Core Interface?</p>
          <br />
          <p>3. Integration Model</p>
          <br />
          <p>1.  What is Core Interface?</p><br/>
          <p>The Core Interface (CIF) is the interface that enables data to be exchanged between an ERP system, in other words, SAP R/3, SAP R/3 Enterprise, or SAP ERP Central Component (SAP ECC) and a connected SCM system.</p><br/>
          <p>CIF is an Interface between ECC and SCM-APO. Its purpose is to transfer    transactional data and master data between the two systems and report it.</p><br/>
          <img src={core1}/>
          <p><b>2. What are the Functions of Core Interface?</b></p><br/>
          <p> Core Interface (CIF) provides following central functions:</p><br/>
          <p>
            <ul>
                <li>it helps to find the exact source and target systems within different complex system environments.</li>
                <li>Initial Master data transfer from ECC system to connected APO system.</li>
                <li>Particularly, if the SCM system to which the data is being transferred is APO system, CIF offers additional functions like to Publish planning results Transaction data (TD) from APO to ECC and vice versa (New/Changes/Deletion).</li>
                <li>It is simple tool for administrating the data transfer. </li>
                <li>RFCs are used to connect both the system</li>
                <li>Data flows in the form of Data Queues created with CIF Integration.</li>
            </ul>
          </p>
          <p>3.Integration Model: -</p>
          <br/>
          <p>
            <ul>
                <li>The transfer of master data and transactional data is regulated by the integration model.</li>
                <li>This model is created within the ERP system and encompasses all the data that needs to be transferred to the SCM system.
                </li>
                <li>Its identification is based on a unique combination of name and application.
                </li>
                <li>To create Integration model,
                </li>
                <li>Type the Transaction code – CFM1.
                </li>
                <li>Enter the relevant details like Model name, Logical system and APO application name.
                </li>
                <li>Here for example if Material master data is need to be sent to APO system, click on Material checkbox.
                </li>
                <li>Also enter the General selection options for Materials such as materials, plants on which the master data to be transferred and material type XO as it is being planned in APO etc.
                </li>
                <li>After entering all the required details, click on Execute.
                </li>
                <img src={core2}/>
                <p>It will be redirected to next screen on which select the line item and click on Generate IM so that the integration model will be generated immediately.</p>
                <img src={core3}/>
                <img src={core4}/>
                <p>Activation of Integration Model: -</p>
                <p>
                    <ul>
                        <li>The initial transfer occurs when the integration models are activated. If you are using SAP APO, the online transfer of transaction data is permitted.
                        </li>
                        <li>By default, the activation of integration models is compared to the already active integration models.
                        </li>
                        <li>This ensures that only the differences are transferred, meaning that only data for filter objects not included in an active integration model is transferred.
                        </li>
                        <li>Multiple integration models can be generated, but only one version can be activated for each model. It is possible to activate and deactivate multiple integration models at the same time.
                        </li>
                        <li>To enable online transfer, the integration models must remain active.
                        </li>
                        <li>Transaction code for Activation of Integration model is CFM2.
                        </li>
                        <li>Simply click on Execute after entering into the transaction. On next screen, click the recent line item of Integration model and start the activation process.
                        </li>
                    </ul>
                </p>
                <img src={core5}/>
                <img src={core6}/>
            </ul>
          </p>
          <p>
            <b>Conclusion</b>
          </p>
          <p>
           <ul>
            <li>The Core Interface (CIF) serves as a crucial integration tool that facilitates the transfer of both Master data and Transactional data between the ECC and APO systems.
            </li>
            <li>Acting as a bridge between the source and target systems, the Core Interface (CIF) plays a vital role in transferring data using Integration models.
            </li>
           </ul>
          </p>
          <p>
            <b>Created by Avadhut Adake</b>
          </p>
        </div>
      </div>
    </>
  );
};

export default Blogdetails6;
