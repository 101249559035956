import React from "react";
import { Image } from "react-bootstrap";
import sap from "./Images/SAP.png";
import logo11 from "./Images/arrow_outward.png";
import { useState } from "react";
import sapcloud from "./Images/cloud.png";
import scloud from "./Images/SCloud.png";
import circle from "./Images/check_circle.png";
import SAP1 from "./Images/saphanacloud.jpeg";
import sapadv from "./Images/SapAdv.jpeg";
import Modal1 from "./Modal1";
const SAPCloud = () => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  return (
    <>
      <div className="container sap">
        <div className="row nativerow">
          <div className="col-md-3">
            <img src={sap} />
          </div>
          <div className="col-md-9">
            <h2>SAP Cloud Services</h2>
          </div>
        </div>
      </div>
      <div className="container-fluid sapcolor">
        <div className="container sapinfo">
          <h2>Introduction To SAP S/4 Cloud Services</h2>
          <p>
            SAP Fiori provides a consistent, intuitive user experience across
            different devices and platforms, including desktop computers,
            laptops, tablets, and smartphones. This can make it easier for users
            to access SAP S/4HANA from anywhere and on any device. SAP Fiori
            offers a range of applications for different business processes and
            tasks, such as financial planning, purchasing, and HR management.
            This can help users find the right tool for their needs and get work
            done more efficiently. SAP S/4HANA also offers personalized,
            role-based dashboards that provide users with relevant information
            and tasks, helping them focus on what's most important to them. SAP
            S/4HANA integrates with other SAP and non-SAP systems, providing a
            seamless user experience and enabling users to access and work with
            data from multiple sources. SAP S/4HANA also offers a range of
            customization options, allowing organizations to tailor the system
            to their specific needs and preferences. This can further improve
            the ease of use for users, as they can work with a system that is
            tailored to their needs and preferences. Another major benefit of
            migrating to SAP S/4HANA is the enhanced approach to updates. In the
            past, new features had to be manually activated by in-house teams,
            but with S/4HANA cloud systems, upgrades are applied quarterly,
            eliminating the potential for updates to be delayed due to a
            shortage of in-house personnel. This ensures that organizations are
            always running the latest version of the software and can take
            advantage of the latest features and innovations. This can help
            organizations stay competitive and ready for the future, as they can
            quickly and easily adopt new technologies and capabilities as they
            become available
            {!isExpanded ? (
              <span className="dots">...</span>
            ) : (
              <span className="more">
                By running the latest version of SAP S/4HANA, organizations can
                benefit from ongoing improvements and enhancements to the
                system, such as new features, functionality, and performance
                improvements. The cloud-based nature of SAP S/4HANA also means
                that organizations don't have to worry about maintaining and
                upgrading their own infrastructure, as this is handled by SAP.
                This can save time and resources, allowing organizations to
                focus on their core business. SAP S/4HANA is designed to be
                scalable and flexible, allowing organizations to easily adapt to
                changing business needs and requirements. This can help
                organizations stay agile and responsive to market changes. SAP
                S/4HANA also offers a range of integrations and partnerships
                with other leading technology providers, such as Microsoft,
                Google, and Amazon Web Services. This can help organizations
                take advantage of the latest technologies and innovations from
                these companies, further enhancing their future-proof
                capabilities.
              </span>
            )}
          </p>
          <button className="sapbtn" onClick={() => toggleExpand(0)}>
            {isExpanded ? "Read Less" : "Read More"}
            <Image src={logo11} className="img0" />
          </button>
        </div>
      </div>
      <div className="container about">
        <h2>SAP S/4HANA Cloud - Empowering Your Business</h2>
        <div className="row aboutusrow">
          <div className="col-md-6">
            <img src={sapcloud} />
          </div>
          <div className="col-md-6">
            <p>
              Bestowal's SAP S/4HANA Cloud Services usher in the next generation
              of innovations and simplifications across fundamental business
              operations. This next-generation cloud ERP leverages in-memory
              computing technology, serving as a digital core that connects
              companies with customers, business networks, the Internet of
              Things, big data, and other essential resources. It offers
              seamless scalability, straightforward management, and robust
              security inherent to cloud systems. By streamlining implementation
              compared to on-premises versions and eliminating internal IT
              requirements, S/4HANA Cloud maximizes software potential.
            </p>
          </div>
        </div>
      </div>
      <div className=" container-fluid sapibpcolor">
        <div className=" container sapibp">
          <h2>Why Migrate To SAP S/4HANA Cloud?</h2>
          <div className=" row listgrp">
            <div className="col-md-6">
              <div className="sapinfo1">
                <h2>01) Continuous Innovation: </h2>

                <p>
                  Our specialists provide a comprehensive SaaS solution that
                  receives continuous updates, ensuring access to the latest
                  innovations.
                </p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="sapinfo1">
                <h2>02) Flexible Subscription Models:</h2>

                <p>
                  Industry-leading best practice processes are ready-to-run with
                  flexible subscription models that support scalability and
                  adaptability.
                </p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="sapinfo1">
                <h2>03) Advanced Analytics:</h2>

                <p>
                  Powerful contextual analytics offer instant and predictive
                  insights, enhancing user experience through Fiori.
                </p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="sapinfo1">
                <h2>04) Accelerated Implementation: </h2>

                <p>
                  Utilize the Activate methodology for accelerated global and
                  local implementations, ensuring rapid deployment and adoption.
                </p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="sapinfo1">
                <h2>05) Enhanced Security and Compliance: </h2>

                <p>
                  Built-in security features and compliance measures safeguard
                  your data and operations, meeting stringent regulatory
                  requirements.{" "}
                </p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="sapinfo1">
                <h2>06) Cost Efficiency: </h2>
                <p>
                  Reduce IT overheads with a subscription-based model, avoiding
                  upfront infrastructure costs and leveraging operational
                  expenditure for scalability{" "}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container sapibp">
        <h2>SAP S/4HANA Professional Service Cloud</h2>
        <img src={scloud} />
      </div>
      <div className="container-fluid seo">
        <div className=" container sapinfo">
          <h2>Why Bestowal For SAP Cloud Services?</h2>
          <div className="row androidrow">
            <div className="col-md-12">
              <div className="androidframe">
                <div className="CaptioN0">
                  <div className="androidinfo">
                    <img src={circle} className="circle-img" />
                    <h4>Continuous Innovation:</h4>
                  </div>
                  <p>
                    Our specialists provide a comprehensive SaaS solution that
                    receives continuous updates, ensuring access to the latest
                    innovations.
                  </p>
                </div>
                <div className="CaptioN0">
                  <div className="androidinfo">
                    <img src={circle} className="circle-img" />
                    <h4>Flexible Subscription Models:</h4>
                  </div>
                  <p>
                    Industry-leading best practice processes are ready-to-run
                    with flexible subscription models that support scalability
                    and adaptability.
                  </p>
                </div>
                <div className="CaptioN0">
                  <div className="androidinfo">
                    <img src={circle} className="circle-img" />
                    <h4>Advanced Analytics</h4>
                  </div>
                  <p>
                    Powerful contextual analytics offer instant and predictive
                    insights, enhancing user experience through Fiori.
                  </p>
                </div>
                <div className="CaptioN0">
                  <div className="androidinfo">
                    <img src={circle} className="circle-img" />
                    <h4>Accelerated Implementation:</h4>
                  </div>
                  <p>
                    Utilize the Activate methodology for accelerated global and
                    local implementations, ensuring rapid deployment and
                    adoption.
                  </p>
                </div>
                <div className="CaptioN0">
                  <div className="androidinfo">
                    <img src={circle} className="circle-img" />
                    <h4>Enhanced Security and Compliance:</h4>
                  </div>
                  <p>
                    Built-in security features and compliance measures safeguard
                    your data and operations, meeting stringent regulatory
                    requirements.
                  </p>
                </div>
                <div className="CaptioN0">
                  <div className="androidinfo">
                    <img src={circle} className="circle-img" />
                    <h4>Cost Efficiency: </h4>
                  </div>
                  <p>
                    Reduce IT overheads with a subscription-based model,
                    avoiding upfront infrastructure costs and leveraging
                    operational expenditure for scalability{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <button onClick={handleOpenModal}>
            Get In Touch <Image src={logo11} className="img6" alt="Arrow" />
          </button>
          {isModalOpen && <Modal1 onClose={handleCloseModal} />}
        </div>
      </div>
      <div className="container about">
        <h2>Simplicity Of SAP S/4 HANA:</h2>
        <div className="row aboutusrow">
          <div className="col-md-5">
            <p>
              The simplified data model of SAP S/4HANA accelerates data
              processing and querying, enhancing business process speed and
              enabling more timely decision-making. This streamlined model also
              reduces memory and storage requirements, cutting infrastructure
              costs and boosting resource efficiency. With fewer tables and
              structures, SAP S/4HANA offers a more intuitive user experience,
              enhancing productivity and adoption rates. Additionally, the SAP
              Fiori interface provides a modern, user-friendly platform for
              interaction. Combined with simplified business processes and
              streamlined workflows, SAP S/4HANA helps organizations reduce
              complexity and improve operational efficiency.
            </p>
          </div>
          <div className="col-md-7">
            <img src={sapadv} />
          </div>
        </div>
      </div>
    </>
  );
};

export default SAPCloud;
