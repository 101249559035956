import React from "react";
import { Image } from "react-bootstrap";
import sap from "./Images/SAP.png";
import { useState } from "react";
import logo11 from "./Images/arrow_outward.png";
import sapmonitoring from "./Images/SapMonitoringAndSupportServices.png";
const SAPMonitoring = () => {
    const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };
  return (
    <>
      <div className="container sapmonitoring">
        <div className="row nativerow">
          <div className="col-md-3">
            <img src={sap}/>
          </div>
          <div className="col-md-9">
            <h2>SAP S/4 Monitoring & Support</h2>
          </div>
        </div>
      </div>
      <div className="sapcolor">
      <div className="container about">
        <div className="row aboutusrow">
          <div className="col-md-6">
            <img src={sapmonitoring}/>
          </div>
          <div className="col-md-6">
            <p>
              Monitoring past and current information about the performance of
              the SAP HANA database is crucial for preventing performance issues
              and conducting root-cause analysis of problems. SAP systems can be
              fully S/4HANA or a combination of S/4HANA and other SAP platforms
              such as Business Suite 7/ECC6. Bestowal offers seamless SAP
              support and maintenance services for all client SAP systems,
              regardless of configuration.<br/> We can help stabilize operations on
              current S/4HANA versions and minimize the inevitable changes that
              come with continuous upgrades and updates as a developing S/4HANA
              system matures over time. Our team of highly qualified
              professionals with years of experience in SAP application
              maintenance and support allows clients to shift their focus and
              resources away from low-value recurring upgrades and towards
              investing in high-value business initiatives that drive
              competitive advantage and growth.
              {!isExpanded ? (
                <span className="dots">...</span>
              ) : (
                <span className="more">
                  Monitoring services involve monitoring the performance and
                  health of the SAP HANA database, as well as any other SAP
                  systems in the organization's IT landscape, in order to
                  identify potential issues and address them before they become
                  major problems. Support services involve providing assistance
                  to users of SAP systems, such as troubleshooting errors,
                  answering questions, and providing guidance on how to use the
                  systems effectively. Bestowal's SAP support and maintenance
                  services can be customized to meet the specific needs of each
                  client, including the level of support needed, the types of
                  systems being supported, and the level of complexity of the
                  organization's IT landscape.<br/> Support and maintenance services
                  can help organizations ensure that their SAP systems are
                  running smoothly, maximize the value they get from their SAP
                  investments, and free up internal resources to focus on more
                  strategic initiatives. Bestowal's team of SAP experts has
                  years of experience in supporting and maintaining SAP systems,
                  and is equipped to handle a wide range of issues and
                  challenges. Regular maintenance and support services can help
                  organizations avoid unplanned downtime, ensure that their
                  systems are up to date with the latest features and security
                  patches, and stay compliant with licensing and support
                  agreements.
                </span>
              )}
            </p>
            <button className="sapbtn" onClick={() => toggleExpand(0)}>
                    {isExpanded ? "Read Less" : "Read More"}
                    <span className="material-symbols-outlined">east</span>
                  </button>
          </div>
        </div>
      </div>
      </div>
      <div className="container android">
        <h2>Key Features Of Our Support & Monitoring Services</h2>
        <div className="row androidrow1">
          <div className="col-md-6">
            <div className="sapsubinfo">
              <div className="sapsubinfo1">
              <h2>Comprehensive Solutions:</h2>
                  <p>
                  We approach SEO as both an art and science, balancing various elements for exceptional, sustainable results.</p></div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="sapsubinfo">
              <div className="sapsubinfo1">
              <h2>Reliability and Transparency</h2>
                  <p>
                  We prioritize transparency in our strategies, ensuring clients are well-informed and confident in our methods.
</p></div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="sapsubinfo">
              <div className="sapsubinfo1">
              <h2>Enhance ROI and Sales</h2>
                  <p>
                  Our SEO consulting in India guarantees business benefits through improved sales and ROI, driven by effective SEO strategies.</p>
              </div>
            </div>
          </div>         
        </div>

        </div>
    </>
  );
};

export default SAPMonitoring;
