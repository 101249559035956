import React from "react";
import reviews from "./reviews";
import Carousel from "@itseasy21/react-elastic-carousel";

function WCS() {
  const breakpoints = [
    { width: 2, itemsToShow: 1 },
    { width: 530, itemsToShow: 2 },
    { width: 760, itemsToShow: 3 },
    // { width: 1200, itemsToShow: 4 },
  ];

  return (
    <>
    <div className="container-fluid abc">
      <div className="container">
      <div className="main8">
      <div className="title123">
        <h1>What Client Says !</h1>
        <p>
          Discover why our clients rave about their experiences with us. See
          what they have to say!
        </p>
      </div>
      <div className="cls1">
        <Carousel breakPoints={breakpoints}>
          {reviews.map((item, i) => (
            <div key={i} className="cardClass">
              <img src={item.image} className="cardimg" />
              <div className="dd1">
                <p className="contentStyle">{item.content}</p>
                <div className="ncm">
                  <div className="nnam">
                    <p className="nameStyle">{item.author}</p>
                  </div>
                  <div className="conm">
                    <p className="companyStyle">{item.company}</p>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </Carousel>
      </div>
      </div>
      </div>
    </div>
    </>
  );
}
export default WCS;
