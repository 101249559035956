import React from "react";
import { Link } from "react-router-dom";
import Engineeringcloud from "./Images/EngineeringCloud.jpg";
import ElectronicsIndustry from "./Images/ElectronicsIndustry.jpg";
import ChemicalIndustry from "./Images/ChemicalIndustry.jpg";
import SupplyIndustry from "./Images/SupplyIndustry.jpg";
import PharmaIndustry from "./Images/PharmaIndustry.jpg";
import SemiconductorIndustry from "./Images/Semiconductor.jpg";
const IndustryLanding = () => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <>
      <div className="container-fluid servicess">
        <div className="container our-services">
          <h1>Industries</h1>
          <p>
            &bull;<a href="/engineering">Engineering Cloud</a>&bull;
            <a href="/electronics">Electronics Industry</a>&bull;
            <a href="/chemical">Chemical Industry</a>
            <br />
            &bull;<a href="/supply">Supply Industry</a>&bull;
            <a href="/pharma">Pharma Industry</a>&bull;
            <a href="/semiconductor">Semicondustor Industry</a>&bull;
            {/* <a href="/dotnet">Dot Net Development</a>&bull;
  <a href="/php">PHP Development</a><br />
  &bull;<a href="/angular">Angular JS Development</a>&bull;
  <a href="/wordpress">WordPress Development</a> */}
          </p>
        </div>
      </div>
      {/* <div class="container services1">
        <div className="row" style={{ paddingTop: '50px' }}>
    <div className="col-md-5">
      <img src={Engineeringcloud} className="img-fluids" alt="Engineering Cloud" title="Engineering Cloud"/>
    </div>
    <div className="col-md-7 text-start">
      <h2>Engineering Cloud</h2>
      <p>
      SAP IBP allows you to make strategic and operational data-driven decisions in all aspects of
your supply chain. Don’t have an SCM solution yet? Consider moving to IBP and S/4HANA, with
Sales and Operations planning as a possible starting point.      </p>
<Link to="/engineering">
      <button className="service-button">
        Know More
      </button>
    </Link>
    </div>
  </div>

 
  <div className="row" style={{ paddingTop: '50px' }}>
    <div className="col-md-7 text-end">
      <h2>Electronics Industry</h2>
      <p>
      The electronics industry heavily relies on SAP solutions to streamline operations, enhance supply chain visibility, and improve demand forecasting. Technologies like SAP IBP and SAP ECC help companies manage complex planning processes, ensuring efficient resource allocation and reduced time-to-market. This integration fosters innovation and competitiveness in a rapidly evolving sector.      </p>
<Link to="/electronics">
      <button className="service-button">
        Know More
      </button>
    </Link>
    </div>
    <div className="col-md-5">
      <img src={ElectronicsIndustry} className="img-fluids" alt="Electronics Industry" title="Electronics Industry"/>
    </div>
  </div>


  <div className="row" style={{ paddingTop: '50px' }}>
    <div className="col-md-5">
      <img src={ChemicalIndustry} className="img-fluids" alt="Chemical Industry" title="Chemical Industry"/>
    </div>
    <div className="col-md-7 text-start">
      <h2>Chemical Industry</h2>
      <p>
      In the chemical industry, SAP solutions are vital for managing complex supply chains and enhancing operational efficiency. Tools like SAP IBP and SAP ECC enable companies to streamline production planning, improve forecasting accuracy, and maintain regulatory compliance.   </p>
      <Link to="/chemical">
      <button className="service-button">
        Know More
      </button>
    </Link>
    </div>
  </div>


  <div className="row" style={{ paddingTop: '50px' }}>
    <div className="col-md-7 text-end">
      <h2>Supply Industry</h2>
      <p>
      In the supply industry, SAP solutions are essential for enhancing supply chain management and ensuring effective coordination between demand and supply. Tools like SAP IBP streamline planning processes, allowing companies to manage complex product portfolios and respond quickly to market fluctuations.      </p>
<Link to="/supply">
      <button className="service-button">
        Know More
      </button>
    </Link>
    </div>
    <div className="col-md-5">
      <img src={SupplyIndustry} className="img-fluids" alt="Supply Industry" title="Supply Industry" />
    </div>
  </div>


  <div className="row" style={{ paddingTop: '50px' }}>
    <div className="col-md-5">
      <img src={PharmaIndustry} className="img-fluids" alt="Pharma Industry" title="Pharma Industry"/>
    </div>
    <div className="col-md-7 text-start">
      <h2>Pharma Industry</h2>
      <p>
      In the pharmaceutical industry, SAP solutions play a critical role in optimizing supply chain processes and ensuring compliance with regulatory standards. Tools like SAP IBP and ECC enable effective demand planning, capacity management, and data integration across global operations.      </p>
<Link to="/pharma">
      <button className="service-button">
        Know More
      </button>
    </Link>
    </div>
  </div>


  <div className="row" style={{ padding: '50px 0px' }}>
    <div className="col-md-7 text-end">
      <h2>Semiconductor Industry</h2>
      <p>
      In the semiconductor industry, SAP solutions are crucial for managing complex supply chains and optimizing production planning. Technologies like SAP IBP and APO facilitate efficient sales forecasting, scenario planning, and inventory management, ensuring alignment across teams.    </p>
<Link to="/semiconductor">
      <button className="service-button">
        Know More
      </button>
    </Link>
    </div>
    <div className="col-md-5">
      <img src={SemiconductorIndustry} className="img-fluids" alt="Semiconductor Industry" title="Semiconductor Industry" />
    </div>
  </div>

        </div> */}
      <div className="container services1">
        {/* Row 1 */}
        <div className="row align-items-center" style={{ paddingTop: "50px" }}>
          <div className="col-md-5 order-1 order-md-1">
            <img
              src={Engineeringcloud}
              className="img-fluids"
              alt="SAP IBP"
              title="SAP IBP"
            />
          </div>
          <div className="col-md-7 order-2 order-md-2 text-start">
            <h2>Engineering Cloud</h2>
            <p>
              SAP IBP allows you to make strategic and operational data-driven
              decisions in all aspects of your supply chain. Don’t have an SCM
              solution yet? Consider moving to IBP and S/4HANA, with Sales and
              Operations planning as a possible starting point.{" "}
            </p>
            <Link to="/engineering" onClick={scrollToTop}>
              <button
                className="service-button"
                alt="Know More"
                title="Know More"
              >
                Know More
              </button>
            </Link>
          </div>
        </div>

        {/* Row 2 */}
        <div className="row align-items-center" style={{ paddingTop: "50px" }}>
          <div className="col-md-7 order-2 order-md-1 text-end">
            <h2>Electronics Industry</h2>
            <p>
              The electronics industry heavily relies on SAP solutions to
              streamline operations, enhance supply chain visibility, and
              improve demand forecasting. Technologies like SAP IBP and SAP ECC
              help companies manage complex planning processes, ensuring
              efficient resource allocation and reduced time-to-market. This
              integration fosters innovation and competitiveness in a rapidly
              evolving sector.{" "}
            </p>
            <Link to="/electronics" onClick={scrollToTop}>
              <button
                className="service-button"
                alt="Know More"
                title="Know More"
              >
                Know More
              </button>
            </Link>
          </div>
          <div className="col-md-5 order-1 order-md-2">
            <img
              src={ElectronicsIndustry}
              className="img-fluids"
              alt="SAP S/4Hana Implementation"
              title="SAP S/4Hana Implementation"
            />
          </div>
        </div>

        {/* Row 3 */}
        <div className="row align-items-center" style={{ paddingTop: "50px" }}>
          <div className="col-md-5 order-1 order-md-1">
            <img
              src={ChemicalIndustry}
              className="img-fluids"
              alt="SAP S/4 Monitoring and Support"
              title="SAP S/4 Monitoring and Support"
            />
          </div>
          <div className="col-md-7 order-2 order-md-2 text-start">
            <h2>Chemical Industry</h2>
            <p>
              In the chemical industry, SAP solutions are vital for managing
              complex supply chains and enhancing operational efficiency. Tools
              like SAP IBP and SAP ECC enable companies to streamline production
              planning, improve forecasting accuracy, and maintain regulatory
              compliance.{" "}
            </p>
            <Link to="/chemical" onClick={scrollToTop}>
              <button
                className="service-button"
                alt="Know More"
                title="Know More"
              >
                Know More
              </button>
            </Link>
          </div>
        </div>

        {/* Row 4 */}
        <div className="row align-items-center" style={{ paddingTop: "50px" }}>
          <div className="col-md-7 order-2 order-md-1 text-end">
            <h2>Supply Industry</h2>
            <p>
              In the supply industry, SAP solutions are essential for enhancing
              supply chain management and ensuring effective coordination
              between demand and supply. Tools like SAP IBP streamline planning
              processes, allowing companies to manage complex product portfolios
              and respond quickly to market fluctuations.{" "}
            </p>
            <Link to="/supply" onClick={scrollToTop}>
              <button
                className="service-button"
                alt="Know More"
                title="Know More"
              >
                Know More
              </button>
            </Link>
          </div>
          <div className="col-md-5 order-1 order-md-2">
            <img
              src={SupplyIndustry}
              className="img-fluids"
              alt="React Native"
              title="React Native"
            />
          </div>
        </div>

        {/* Row 5 */}
        <div className="row align-items-center" style={{ paddingTop: "50px" }}>
          <div className="col-md-5 order-1 order-md-1">
            <img
              src={PharmaIndustry}
              className="img-fluids"
              alt="NodeJS Development"
              title="NodeJS Development"
            />
          </div>
          <div className="col-md-7 order-2 order-md-2 text-start">
            <h2>Pharma Industry</h2>
            <p>
              In the pharmaceutical industry, SAP solutions play a critical role
              in optimizing supply chain processes and ensuring compliance with
              regulatory standards. Tools like SAP IBP and ECC enable effective
              demand planning, capacity management, and data integration across
              global operations.{" "}
            </p>
            <Link to="/pharma" onClick={scrollToTop}>
              <button
                className="service-button"
                alt="Know More"
                title="Know More"
              >
                Know More
              </button>
            </Link>
          </div>
        </div>

        {/* Row 6 */}
        <div className="row align-items-center" style={{ paddingTop: "50px" }}>
          <div className="col-md-7 order-2 order-md-1 text-end">
            <h2>Semiconductor Industry</h2>
            <p>
              In the semiconductor industry, SAP solutions are crucial for
              managing complex supply chains and optimizing production planning.
              Technologies like SAP IBP and APO facilitate efficient sales
              forecasting, scenario planning, and inventory management, ensuring
              alignment across teams.{" "}
            </p>
            <Link to="/semiconductor" onClick={scrollToTop}>
              <button
                className="service-button"
                alt="Know More"
                title="Know More"
              >
                Know More
              </button>
            </Link>
          </div>
          <div className="col-md-5 order-1 order-md-2">
            <img
              src={SemiconductorIndustry}
              className="img-fluids"
              alt="Java Development"
              title="Java Development"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default IndustryLanding;
