

// import React, { useState, useEffect } from "react";
// import { NavLink, Link } from "react-router-dom";
// import { IoIosArrowDown, IoIosSearch } from "react-icons/io";
// import logo from "./Images/BestowalHeader.png";

// const Navbar = () => {
//   const [isNavbarCollapsed, setIsNavbarCollapsed] = useState(true);
//   const [isSearchVisible, setIsSearchVisible] = useState(false);
//   const [searchQuery, setSearchQuery] = useState("");
//   const [showResults, setShowResults] = useState(false);

//   const services = [
//     { name: "SAP IBP", path: "/sapibp" },
//     { name: "SAP S/4HANA Implementation", path: "/saphana" },
//     { name: "SAP S/4 Monitoring & Support", path: "/saphana" },
//     { name: "SAP Cloud Service", path: "/saphana" },
//     { name: "SAP Business one", path: "/saphana" },
//     { name: "Node JS Development", path: "/saphana" },
//     { name: "React Native Development", path: "/saphana" },
//     { name: "Android Development", path: "/saphana" },
//     { name: "iOS Development", path: "/saphana" },
//     { name: "Java Development", path: "/saphana" },
//     { name: "React JS Development", path: "/reactjs" },
//     { name: "Dot Net Development", path: "/saphana" },
//     { name: "PHP Development", path: "/saphana" },
//     { name: "Angular JS Development", path: "/saphana" },
//     { name: "Resource Solution", path: "/saphana" },
//     { name: "RPA Implementation", path: "/saphana" },
//     { name: "Digital Marketing", path: "/saphana" },
//     { name: "Search Engine Oprimization", path: "/saphana" },
    
//     // ... (other services)
//   ];

//   const industries = [
//     { name: "Engineering Cloud", path: "/engineering" },
//     { name: "Electronics Industry", path: "/manufacture" },
//     { name: "Chemical Industry", path: "/manufacture" },
//     { name: "Supply Industry", path: "/manufacture" },
//     { name: "Pharma Industry", path: "/manufacture" },
//     { name: "Semiconductor Industry", path: "/manufacture" },
//   ];

//   const company = [
//     { name: "Life at Bestowal", path: "/life" },
//     { name: "Career Page", path: "/carrier" },
//     { name: "History of Bestowal", path: "/saphana" },
//   ];

//   const combinedData = [...services, ...industries, ...company];

//   const filteredData = combinedData.filter((item) =>
//     item.name.toLowerCase().includes(searchQuery.toLowerCase())
//   );

//   const toggleSearch = () => {
//     setIsSearchVisible(!isSearchVisible);
//   };

//   const handleLinkClick = () => {
//     setIsNavbarCollapsed(true); // Collapse the navbar on link click
//   };

//   const handleDropdownClick = (event) => {
//     event.stopPropagation();
//   };

//   const handleSearchChange = (e) => {
//     setSearchQuery(e.target.value);
//     setShowResults(true);
//   };

//   const handleSelect = () => {
//     setSearchQuery("");
//     setShowResults(false);
//   };

//   useEffect(() => {
//     const handleWindowResize = () => {
//       if (window.innerWidth >= 992) {
//         setIsNavbarCollapsed(true); // Always collapse the navbar on larger screens
//       }
//     };

//     window.addEventListener("resize", handleWindowResize);

//     return () => {
//       window.removeEventListener("resize", handleWindowResize);
//     };
//   }, []);

//   return (
//     <nav className="navbar navbar-expand-lg navbar-custom fixed-top">
//       <Link to="/home">
//         <img src={logo} alt="Bestowal Header Logo" title="Bestowal Logo" className="Logo" />
//       </Link>
//       <button
//         className="navbar-toggler"
//         type="button"
//         onClick={() => setIsNavbarCollapsed(!isNavbarCollapsed)}
//         aria-controls="navbarNav"
//         aria-expanded={!isNavbarCollapsed}
//         aria-label="Toggle navigation"
//       >
//         <span className="navbar-toggler-icon"></span>
//       </button>
//       <div
//         className={`collapse navbar-collapse ${isNavbarCollapsed ? "" : "show"}`}
//         id="navbarNav"
//       >
//         <ul className="navbar-nav mx-auto">
//           <li className="nav-item">
//             <NavLink
//               className="nav-link nav-link-hover"
//               to="/home"
//               onClick={handleLinkClick}  // Add handleLinkClick here
//               activeClassName="active"
//             >
//               Home
//             </NavLink>
//           </li>
//           <li className="nav-item">
//             <NavLink
//               className="nav-link nav-link-hover"
//               // role="button"
//               // data-toggle="dropdown"
//               // aria-haspopup="true"
//               // aria-expanded="false"
//               onClick={handleDropdownClick}
//               activeClassName="active"
//               to="/services"
//             >
//               Services 
//               {/* <IoIosArrowDown className="dropdown-icon" /> */}
//             </NavLink>
//             {/* <div className="dropdown-menu" aria-labelledby="servicesDropdown">
//               <div className="dropdown-submenu">
//                 <a
//                   className="dropdown-item"
//                   href="#"
//                   id="sapServicesDropdown"
//                   role="button"
//                   data-toggle="dropdown"
//                   aria-haspopup="true"
//                   aria-expanded="false"
//                   onClick={handleDropdownClick}
//                 >
//                   SAP Services <IoIosArrowDown className="dropdown-icons" />
//                 </a>
//                 <div
//                   className="dropdown-menu"
//                   aria-labelledby="sapServicesDropdown"
//                 >
//                   <NavLink className="dropdown-item" to="/service" onClick={handleLinkClick}>
//                     Service
//                   </NavLink>
//                   <NavLink className="dropdown-item" to="/sapibp" onClick={handleLinkClick}>
//                     SAP IBP
//                   </NavLink>
//                   <NavLink className="dropdown-item" to="/saphana" onClick={handleLinkClick}>
//                     SAP S/4HANA Implementation
//                   </NavLink>
//                   <NavLink className="dropdown-item" to="/monitoring" onClick={handleLinkClick}>
//                     SAP S/4 Monitoring & Support
//                   </NavLink>
//                   <NavLink className="dropdown-item" to="/cloud" onClick={handleLinkClick}>
//                     SAP Cloud Service
//                   </NavLink>
//                   <NavLink className="dropdown-item" to="/sapbusiness" onClick={handleLinkClick}>
//                     SAP Business One
//                   </NavLink>
//                 </div>
//               </div>
//               <div className="dropdown-submenu">
//                 <a
//                   className="dropdown-item"
//                   href="#"
//                   id="appServicesDropdown"
//                   role="button"
//                   data-toggle="dropdown"
//                   aria-haspopup="true"
//                   aria-expanded="false"
//                   onClick={handleDropdownClick}
//                 >
//                   Mobile Services <IoIosArrowDown className="dropdown-icons" />
//                 </a>
//                 <div
//                   className="dropdown-menu"
//                   aria-labelledby="appServicesDropdown"
//                 >
//                   <NavLink className="dropdown-item" to="/node" onClick={handleLinkClick}>
//                     Node Js Development
//                   </NavLink>
//                   <NavLink className="dropdown-item" to="/native" onClick={handleLinkClick}>
//                     React Native Development
//                   </NavLink>
//                   <NavLink className="dropdown-item" to="/android" onClick={handleLinkClick}>
//                     Android Development
//                   </NavLink>
//                   <NavLink className="dropdown-item" to="/ios" onClick={handleLinkClick}>
//                     iOS Development
//                   </NavLink>
//                 </div>
//               </div>
//               <div className="dropdown-submenu">
//                 <a
//                   className="dropdown-item"
//                   href="#"
//                   id="webServicesDropdown"
//                   role="button"
//                   data-toggle="dropdown"
//                   aria-haspopup="true"
//                   aria-expanded="false"
//                   onClick={handleDropdownClick}
//                 >
//                   Web Services <IoIosArrowDown className="dropdown-icons" />
//                 </a>
//                 <div
//                   className="dropdown-menu"
//                   aria-labelledby="webServicesDropdown"
//                 >
//                   <NavLink className="dropdown-item" to="/java" onClick={handleLinkClick}>
//                     Java Development
//                   </NavLink>
//                   <NavLink className="dropdown-item" to="/reactjs" onClick={handleLinkClick}>
//                     React JS Development
//                   </NavLink>
//                   <NavLink className="dropdown-item" to="/dotnet" onClick={handleLinkClick}>
//                     Dot Net Development
//                   </NavLink>
//                   <NavLink className="dropdown-item" to="/php" onClick={handleLinkClick}>
//                     PHP Development
//                   </NavLink>
//                   <NavLink className="dropdown-item" to="/angular" onClick={handleLinkClick}>
//                     Angular JS Development
//                   </NavLink>
//                 </div>
//               </div>
//               <NavLink className="dropdown-item" to="/resource" onClick={handleLinkClick}>
//                 Resource Solution
//               </NavLink>
//               <NavLink className="dropdown-item" to="/rpa" onClick={handleLinkClick}>
//                 RPA Implementation
//               </NavLink>
//               <NavLink className="dropdown-item" to="/digital" onClick={handleLinkClick}>
//                 Digital Marketing
//               </NavLink>
//               <NavLink className="dropdown-item" to="/seo" onClick={handleLinkClick}>
//                 Search Engine Optimization
//               </NavLink>
//             </div> */}
//           </li>
//           <li className="nav-item dropdown">
//             <a
//               className="nav-link nav-link-hover"
//               href="#"
//               role="button"
//               data-toggle="dropdown"
//               aria-haspopup="true"
//               aria-expanded="false"
//               onClick={handleDropdownClick}
//             >
//               Industries 
//               {/* <IoIosArrowDown className="dropdown-icon" /> */}
//             </a>
//             {/* <div className="dropdown-menu" aria-labelledby="industryDropdown">
//               <NavLink className="dropdown-item" to="/engineering" onClick={handleLinkClick}>
//                 Engineering Cloud
//               </NavLink>
//               <NavLink className="dropdown-item" to="/electronics" onClick={handleLinkClick}>
//                 Electronics Industry
//               </NavLink>
//               <NavLink className="dropdown-item" to="/chemical" onClick={handleLinkClick}>
//                 Chemical Industry
//               </NavLink>
//               <NavLink className="dropdown-item" to="/supply" onClick={handleLinkClick}>
//                 Supply Industry
//               </NavLink>
//               <NavLink className="dropdown-item" to="/pharma" onClick={handleLinkClick}>
//                 Pharma Industry
//               </NavLink>
//               <NavLink className="dropdown-item" to="/semiconductor" onClick={handleLinkClick}>
//                 Semiconductor Industry
//               </NavLink>
//             </div> */}
//           </li>
    
//           <li className="nav-item">
//             <NavLink
//               className="nav-link nav-link-hover"
//               to="./about"
//               // href="#"
//               // role="button"
//               // data-toggle="dropdown"
//               // aria-haspopup="true"
//               // aria-expanded="false"
//               activeClassName="active"
//               onClick={handleDropdownClick}
//             >
//               About us
//               {/* <IoIosArrowDown className="dropdown-icon" /> */}
//             </NavLink>
//             {/* <div className="dropdown-menu" aria-labelledby="companyDropdown">
//               <NavLink className="dropdown-item" to="/life" onClick={handleLinkClick}>
//                 Life at Bestowal
//               </NavLink>
//               <NavLink className="dropdown-item" to="/carrier" onClick={handleLinkClick}>
//                 Career Page
//               </NavLink>
//               <NavLink className="dropdown-item" to="/history" onClick={handleLinkClick}>
//                 History of Bestowal
//               </NavLink>
//             </div> */}
//           </li>
//           <li className="nav-item">
//             <NavLink
//               className="nav-link nav-link-hover"
//               to="/career"
//               onClick={handleLinkClick}
//               activeClassName="active"
//             >
//               Career
//             </NavLink>
//           </li>
//           <li className="nav-item">
//             <NavLink
//               className="nav-link nav-link-hover"
//               to="/blog"
//               onClick={handleLinkClick}
//               activeClassName="active"
//             >
//               Blog
//             </NavLink>
//           </li>
//           <li className="nav-item">
//             <NavLink className="nav-link nav-link-hover"
//             to="./contact-us"
//             onClick={handleLinkClick}
//             activeClassName="active"
//             >
//               Contact us
//             </NavLink>

//           </li>
//         </ul>
//         <div className="contact-button-container">
   
      
//    <a href="#contact-section" className="btn contact-button" title="Let's Talk">
// Let's Talk
// </a>
// </div>
//       </div>
//     </nav>
//   );
// };

// export default Navbar;

import React, { useState, useEffect } from "react";
import { NavLink, Link } from "react-router-dom";
import { IoIosArrowDown, IoIosSearch } from "react-icons/io";
import logo from "./Images/BestowalHeader.png";

const Header = () => {
  const [isNavbarCollapsed, setIsNavbarCollapsed] = useState(true);
  const [isSearchVisible, setIsSearchVisible] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [showResults, setShowResults] = useState(false);

  const services = [
    { name: "SAP IBP", path: "/sapibp" },
    { name: "SAP S/4HANA Implementation", path: "/saphana" },
    { name: "SAP S/4 Monitoring & Support", path: "/saphana" },
    { name: "SAP Cloud Service", path: "/saphana" },
    { name: "SAP Business one", path: "/saphana" },
    { name: "Node JS Development", path: "/saphana" },
    { name: "React Native Development", path: "/saphana" },
    { name: "Android Development", path: "/saphana" },
    { name: "iOS Development", path: "/saphana" },
    { name: "Java Development", path: "/saphana" },
    { name: "React JS Development", path: "/reactjs" },
    { name: "Dot Net Development", path: "/saphana" },
    { name: "PHP Development", path: "/saphana" },
    { name: "Angular JS Development", path: "/saphana" },
    { name: "Resource Solution", path: "/saphana" },
    { name: "RPA Implementation", path: "/saphana" },
    { name: "Digital Marketing", path: "/saphana" },
    { name: "Search Engine Optimization", path: "/saphana" },
  ];

  const industries = [
    { name: "Engineering Cloud", path: "/engineering" },
    { name: "Electronics Industry", path: "/manufacture" },
    { name: "Chemical Industry", path: "/manufacture" },
    { name: "Supply Industry", path: "/manufacture" },
    { name: "Pharma Industry", path: "/manufacture" },
    { name: "Semiconductor Industry", path: "/manufacture" },
  ];

  const company = [
    { name: "Life at Bestowal", path: "/life" },
    { name: "Career Page", path: "/career" },
    { name: "History of Bestowal", path: "/saphana" },
  ];

  const combinedData = [...services, ...industries, ...company];

  const filteredData = combinedData.filter((item) =>
    item.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const toggleSearch = () => {
    setIsSearchVisible(!isSearchVisible);
  };

  const handleLinkClick = () => {
    setIsNavbarCollapsed(true); // Collapse the navbar on link click
  };

  const handleDropdownClick = (event) => {
    event.stopPropagation();
    setIsNavbarCollapsed(false); // Keep the dropdown open when clicking
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    setShowResults(true);
  };

  const handleSelect = () => {
    setSearchQuery("");
    setShowResults(false);
  };

  useEffect(() => {
    const handleWindowResize = () => {
      if (window.innerWidth >= 992) {
        setIsNavbarCollapsed(true); // Always collapse the navbar on larger screens
      }
    };

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  return (
    <nav className="navbar navbar-expand-lg navbar-custom fixed-top">
      <Link to="/tabbing">
        <img src={logo} alt="Bestowal Header Logo" title="Bestowal Logo" className="Logo" />
      </Link>
      <button
        className="navbar-toggler"
        type="button"
        onClick={() => setIsNavbarCollapsed(!isNavbarCollapsed)}
        aria-controls="navbarNav"
        aria-expanded={!isNavbarCollapsed}
        aria-label="Toggle navigation"
      >
        <span className="navbar-toggler-icon"></span>
      </button>
      <div
        className={`collapse navbar-collapse ${isNavbarCollapsed ? "" : "show"}`}
        id="navbarNav"
      >
        <ul className="navbar-nav mx-auto">
          <li className="nav-item">
            <NavLink
              className="nav-link nav-link-hover"
              to="/home"
              onClick={handleLinkClick}
              activeClassName="active"
            >
              Home
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink
              className="nav-link nav-link-hover"
              onClick={handleLinkClick}
              to="/services"
            >
              Services
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink
              className="nav-link nav-link-hover"
              onClick={handleLinkClick}
              to="/industrylanding"
            >
              Industries
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink
              className="nav-link nav-link-hover"
              onClick={handleLinkClick}
              to="/products"
            >
              Products
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink
              className="nav-link nav-link-hover"
              onClick={handleLinkClick}
              to="/about"
            >
              About us
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink
              className="nav-link nav-link-hover"
              onClick={handleLinkClick}
              // to="/career"
              to="/careerlanding"
            >
              Career
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink
              className="nav-link nav-link-hover"
              onClick={handleLinkClick}
              to="/blog"
            >
              Blog
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink
              className="nav-link nav-link-hover"
              onClick={handleLinkClick}
              to="/contact-us"
            >
              Contact us
            </NavLink>
          </li>
        </ul>
        <div className="contact-button-container">
          <Link  className="btn contact-button" to="/contact-us"  title="Let's Talk">
            Let's Talk
          </Link>
        </div>
      </div>
    </nav>
  );
};

export default Header;
