import React from 'react'
import SAPAPO1 from "./Images/SAPAPO1.png";
import SAPAPO2 from "./Images/SAPAPO2.png";
import SAPAPO3 from "./Images/SAPAPO3.png";
import SAPAPO4 from "./Images/SAPAPO4.png";
import SAPAPO5 from "./Images/SAPAPO5.png";
import SAPAPO6 from "./Images/SAPAPO6.png";
import SAPAPO7 from "./Images/SAPAPO7.png";
import SAPAPO8 from "./Images/SAPAPO8.png";
import SAPAPO9 from "./Images/SAPAPO9.png";
const Blogdetails8 = () => {
  return (
    <>
      <div className="container blogdetails">
        <div className="article-header mt-5">
          <h1>SAP APO MASTER DATA</h1>
          <br />
          <div className="bloginfo">
            <p>
              <strong>Author Name:</strong>Chetali Jeur
            </p>
            <p>Publish Date: September 11, 2024</p>
          </div>
          <p>SAP APO MASTER DATA</p>
          <p>Master data in SAP APO plays a crucial role, just like master data in SAP ECC. Accurate setup of master data, including customer, product, and plant information, is essential for conducting ATP checks in SAP APO.</p><br/>
          <p>Typically, ECC serves as the client of record, and the master data is replicated from ECC to APO. The transfer of this data can be accomplished through the use of the Core Interface (CIF) to the SAP APO module.</p><br/>
          <p>Within the master data integration model, the ability to specify the transfer of various types of master data to the SAP APO module such as</p><br/>
          <p>
            <ul>
                <li>Location</li>
                <li>Product</li>
                <li>Resource</li>
                <li>Production Data Structure</li>
                <li>Transportation Lane</li>
            </ul>
          </p>
          <br/>
          <p><b>1. Location Master Data</b></p>
          <p><b>T Code: /SAPAPO/LOC3</b></p>
          <br/>
          <p>The location represents the specific area where products or resources are supervised. You have the ability to generate, modify, or view the primary information associated with locations.</p><br/>
          <p>To generate, modify, or view the primary information, navigate to SCM Basis → Master Data → Location → Location Master Data Home Screen.</p><br/>
          <img src={SAPAPO1}/>
          <p>Based on location type, location is identified, such as below</p>
          <img src={SAPAPO2}/>
          <p><b>2. Product Master Data</b></p><br/>
          <p><b> T Code: /SAPAPO/MAT1</b></p><br/>
          <p>Product master data can be accessed in SCM basis, and it can be utilized across various applications including SAP APO. The maintenance of product master data is done under the master data section in SCM basis. You have the ability to add, display, modify, or remove products.</p><br/>
          <p>The product master of SCM can also be utilized in other master data categories such as locations or documents. You can manage both global product master and location-specific product master.</p><br/>
          <p>To access the global product master, navigate to SCM Basis → Master Data → Product.</p>
          <img src={SAPAPO3}/>
          <p>The table below helps to find relevant data required for planning.</p><br/>
          <img src={SAPAPO4}/>
          <p><b>3. Resource</b></p><br/>
          <p><b>T Code: /SAPAPO/RES01</b></p><br/>
          <p>The work center and associated capacities are replicated to SAP APO as resources. Resources in SAP APO have capacities and working times defined for machines, means of transport, and warehouses. There are four different types of resource categories: Production, Transportation, Warehouse and Handling Unit.</p><br/>
          <p>Resource data is relevant to the planning of order dates taking working times and the available capacities of the resources into account. </p>
          <img src={SAPAPO5}/>
          <p>In SAP APO, system saves routing data of a Production data structure (PDS). The resource at which the activity is carried out, is assigned to each activity in a PDS in the relevant mode. The system uses the resource data to schedule the activities.</p><br/>
          <p><b>4. Production Data Structure (PDS)</b></p><br/>
          <p><b>T Code: /SAPAPO/CURTO_SIMU</b></p><br/>
          <p>Production Data Structure (PDS) is a combination of BOM and Routing.</p><br/>
          <p>Bill of Material (BOM) carries components require to produce the finished good whereas Routing is the sequence of operation, together they form PDS in APO.</p><br/>
          <p>When BOM and Routing are transferred from ECC to APO through integration model, PDS is created in APO.</p>
          <img src={SAPAPO6}/>
          <p> Display of PDS in APO</p>
          <img src={SAPAPO7}/>
          <p>If PDS is once available in APO and it is then deleted, it should be transferred via CURTO_CRTEATE to create PDS in APO, for that an existing integration model must be existing for PDS</p><br/>
          <p>If PDS is not available in APO, SNP Planned Orders will be generated; however, there will be no source of Supply. Conversely, if PDS is available but invalid, SNP Planned Orders will not be generated.</p><br/>
          <br/>
          <p><b>5. TRANSPORTATION LANE</b></p><br/>
          <p><b>  T Code: /SAPAPO/TL1</b></p><br/>
          <p>The T-Lane serves as a vital source of essential data for organizations when it comes to procuring a particular material from a vendor. It encompasses crucial information such as pricing conditions, units for under and over-delivery, availability period, and planned delivery time for a specific material or product.</p><br/>
          <p>With the help of T-Lane, businesses can effectively manage and maintain detailed information about their suppliers, including pricing, lead times, quality ratings, and delivery performance. This comprehensive record empowers organizations to evaluate and select suppliers more efficiently, enabling them to make well-informed procurement decisions</p><br/>
          <p>When transferring purchase info records, scheduling agreements, or contracts from ECC to APO, a Transportation Lane (T-Lane) is created in APO. However, it is necessary to create Means of Transport (MOT) in APO</p>
          <img src={SAPAPO8}/>
          <p>The various procurement types within the SAP Purchasing Info Record (PIR) platform:</p>
          <p>
            <ol>
                <li>  Standard Info Record</li>
                <li>Subcontracting Info Record</li>
                <li>Consignment Info Record</li>
                <li>Pipeline Info Record</li>
            </ol>
          </p>
          <img src={SAPAPO9}/>
          <p>If T-Lane is not available in APO, Stock Transport Requisition (STR) will be generated; however, there will be no source of Supply. Conversely, if T-Lane is available but invalid, Stock Transport Requisition (STR) will not be generated.Transport duration and means of transport description (Ex 001-Truck, 002-Rail) is mentioned in Means of Transport</p><br/>
          <p><b>Conclusion:</b></p><br/>
          <p>
            <ul>
                <li>Accurate and efficient supply chain planning heavily relies on the proficient management of master data in SAP APO. The aspect guarantees that the system possesses the vital information required to make well-informed decisions and streamline processes.</li>
                <li>Consistently maintaining and updating the master data is of utmost importance to accurately reflect any alterations in the supply chain environment.</li><br/>
            </ul>
          </p>
          <p><b>Created by Chetali Jeur</b></p>
        </div>
    </div>
    </>
  )
}

export default Blogdetails8
