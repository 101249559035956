// import React from 'react';
// import { Col, Row, Container, Image } from 'react-bootstrap';
// import Card from 'react-bootstrap/Card';
// import rpa1 from "./Images/rpa1.png";
// import rpa2 from "./Images/rpa2.png";
// import rpa3 from "./Images/rpa3.png";
// export default function RpaImplementation() {
//     return (
//         <>
//         <header className="container-fluid headerpro">
//                 <h4 className='react-reveal text-center'>RPA Implementation</h4>
//             </header>
//             <section className='wrap p-2'>
//                 <Container className='content mt-5'>
//                     <h3 className='mb-3'>Robotic Process Automation</h3>
//                     <p>
//                         As one of today's fastest-growing technologies, RPA automates tedious, back-office tasks, enabling your business to operate more efficiently and with greater employee satisfaction.
//                     </p>
//                     <Row>
//                         <Col md={{ span: 6 }}>
//                             <Card className='single-card'>
//                                 <Card.Body className='text-center h-100 d-flex flex-column justify-content-center align-items-center'>
//                                     <Card.Title className='text-muted fs-4'>ENHANCED PRODUCTIVITY</Card.Title>
//                                     <Card.Text className='h-50 mt-3'>
//                                         RPA automates repetitive, time-consuming tasks, it allows your workforce to concentrate on more critical activities relevant to your company's growth.
//                                     </Card.Text>
//                                 </Card.Body>
//                             </Card>
//                         </Col>
//                         <Col md={{ span: 6 }}>
//                             <Card className='single-card'>
//                                 <Card.Body className='text-center h-100 d-flex flex-column justify-content-center align-items-center'>
//                                     <Card.Title className='text-muted fs-4'>INSTANT ROI</Card.Title>
//                                     <Card.Text className='h-50 mt-3'>
//                                         Automating rule-based tasks will result in a rapid return on investment, as time will be saved, manual effort will be reduced, and cost savings will total nearly 30%.
//                                     </Card.Text>
//                                 </Card.Body>
//                             </Card>
//                         </Col>
//                     </Row>
//                     <h3 className='mt-5 mb-3'>Productivity Key</h3>
//                     <p>
//                         RPA makes use of software robots to relieve employees of the burden of doing menial jobs so they may concentrate on more difficult activities. RPA bots do these routine jobs more quickly and accurately, maintaining the effectiveness of your operations and lowering operational risks.
//                     </p>
//                     <h3 className='mt-5 mb-3'>Advantages Of RPA</h3>
//                     <p>
//                         These advantages of utilizing RPA Implementation are very typical and do not adequately convey the range and depth of automation capabilities RPA provides.
//                     </p>
//                     <p>
//                         The following are some of the less frequently discussed outcomes of RPA.
//                     </p>
//                     <h3 className='mt-5 mb-3'>Improved Compatibility</h3>
//                     <p>
//                         RPA closes data gaps between diverse sources and keeps a log of every step the software robots take when automating processes. This enables staff members to continuously conduct internal evaluations and proactively identify and manage any compliance issues. These RPA features serve businesses in a variety of industries, whether they are insurance providers required to comply with legal requirements, healthcare providers required to adhere to HIPAA privacy laws, or financial services organizations required to be PCI compliant.
//                     </p>
//                     <h3 className='mt-5 mb-3'>Non-Intrusiveness And Compatibility With Already-Installed Systems</h3>
//                     <p>
//                         RPA interacts with data on the presentation layer of platforms and applications because it imitates human activities. RPA now behaves at the user interface exactly as a human would. Because of this, organizations deploying RPA do not need to make changes to their current legacy systems. This is advantageous because it enables businesses to employ RPA in a nondisruptive manner, setting it apart from other forms of automation. It also lessens the need for staff to be proficient coders and for frequent IT participation.
//                     </p>
//                     <h3 className='mt-5 mb-3'>Improved Managerial Skills</h3>
//                     <p>
//                         Organizations can remotely model, monitor, control, schedule, and carry out the deployment of RPA software robots thanks to a single administration platform. Additionally, it enables auditing and analytics to take place throughout one location. RPA enables enterprises to achieve greater governance so that business processes may be managed more effectively since specific needs can be incorporated into automation rules. High-security security standards can also be preserved by giving the software robots reverse proxy control.
//                     </p>
//                     <h3 className='mt-5 mb-3'>Enhanced Customer Experience</h3>
//                     <p>
//                         RPA is typically thought of as a solution for businesses to relieve people of the stress of repetitive, high-volume operations like processing claims and issuing purchase orders. RPA can lead to improvements for consumers even though it may appear that the automation of back-office chores has no effect on the front office. RPA enables businesses to provide clients with higher-quality services faster because of its automation capabilities.
//                     </p>
//                     <h3 className='mt-5'>Utilize Bestowal's RPA Services To Automate Your Workflow And Processes.</h3>
//                     <div className="container-fluid d-flex flex-column align-items-center">
//                         <img className='w-100 h-auto' src={rpa1} alt="" />
//                         <img className='w-100' src={rpa2} alt="" />
//                         <img className='w-100' src={rpa3} alt="" />
//                     </div>
//                 </Container>
//             </section>

//         </>
//     )
// }

import React,{useState} from "react";
import RPA1 from "./Images/RPA.jpeg";
import { Image } from "react-bootstrap";
import logo11 from "./Images/arrow_outward.png";
const RPA = () => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <>
      <div className="container rpa">
        <div className="row nativerow">
          <div className="col-md-4">
            <img src={RPA1} height={300}/>
          </div>
          <div className="col-md-8">
            <h2>RPA Implementation</h2>
          </div>
        </div>
      </div>
      <div className="container-fluid rpacolor">
        <div className=" container rpainfo">
          <h2>What is RPA ?</h2>
          <p>
          "RPA, which stands for Robotic Process Automation, is a technology that utilizes software robots to automate repetitive tasks typically performed by humans on computers. These tasks include activities such as data entry, file movement, and form filling. Businesses find RPA invaluable as it frees up employees to focus on more critical tasks. Additionally, RPA enhances accuracy and efficiency since robots do not make human errors.                   It falls under the category of business process automation
          {!isExpanded ? (
              <span className="dots">...</span>
            ) : (
              <span className="more">
 (BPA) but differs from AI (Artificial Intelligence) as it operates on rules rather than machine learning."
              </span>
            )}
          </p>
          <button className="sapbtn" onClick={toggleExpand}>
            {isExpanded ? "Read Less" : "Read More"}
            <Image src={logo11} className="img0" alt="Read more icon" />
          </button>
        </div>
      </div>
      <div className="container rpa2">
        <h2>How RPA Boosts Your Business</h2>
        <p>
          Let's move beyond the basics! RPA offers a much wider range of
          automation capabilities than what's being mentioned here.
        </p>
        <div className="row rparow">
          <div className="rparow1">
            <div className="col-md-12">
              <div className="rpainfo1">
                {" "}
                <div className="rpasubinfo1">
                  <h4>Reliability and Transparency</h4>
                  <p>
                    RPA closes data gaps between diverse sources and keeps a log
                    of every step the software robots take when automating
                    processes. This enables staff members to continuously
                    conduct internal evaluations and proactively identify and
                    manage any compliance issues. These RPA features serve
                    businesses in a variety of industries, whether they are
                    insurance providers required to comply with legal
                    requirements, healthcare providers required to adhere to
                    HIPAA privacy laws, or financial services organizations
                    required to be PCI compliant.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <div className="rpainfo1">
                {" "}
                <div className="rpasubinfo1">
                  <h4>Non-Intrusiveness And Compatibility With Already-Installed Systems</h4>
                  <p>
                  RPA interacts with data on the presentation layer of platforms and applications because it imitates human activities. RPA now behaves at the user interface exactly as a human would. Because of this, organizations deploying RPA do not need to make changes to their current legacy systems. This is advantageous because it enables businesses to employ RPA in a nondisruptive manner, setting it apart from other forms of automation. It also lessens the need for staff to be proficient coders and for frequent IT participation.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <div className="rpainfo1">
                {" "}
                <div className="rpasubinfo1">
                  <h4>Improved Managerial Skills</h4>
                  <p>
                  Organizations can remotely model, monitor, control, schedule, and carry out the deployment of RPA software robots thanks to a single administration platform. Additionally, it enables auditing and analytics to take place throughout one location. RPA enables enterprises to achieve greater governance so that business processes may be managed more effectively since specific needs can be incorporated into automation rules. High-security security standards can also be preserved by giving the software robots reverse proxy control.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <div className="rpainfo1">
                {" "}
                <div className="rpasubinfo1">
                  <h4>Enhanced Customer Experience</h4>
                  <p>
                  RPA is typically thought of as a solution for businesses to relieve people of the stress of repetitive, high-volume operations like processing claims and issuing purchase orders. RPA can lead to improvements for consumers even though it may appear that the automation of back-office chores has no effect on the front office. RPA enables businesses to provide clients with higher-quality services faster because of its automation capabilities.                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RPA;
