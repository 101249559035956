// import React, { useState } from 'react';
// import { Form, InputGroup } from 'react-bootstrap';

// function YourComponent() {
//   const [services, setServices] = useState('');
//   const [showDropdown, setShowDropdown] = useState(false);

//   const handleInputChange = (e) => {
//     setServices(e.target.value);
//   };

//   const handleDropdownItemClick = (value) => {
//     setServices(value);
//     setShowDropdown(false);
//   };

//   return (
//     <div className="input-container">
//       <label htmlFor="fname" className="fsn">
//         Services
//       </label>
//       <InputGroup>
//         <Form.Control
//           type="text"
//           id="fname"
//           name="Services"
//           placeholder="Services"
//           value={services}
//           onChange={handleInputChange}
//           onFocus={() => setShowDropdown(true)}
//           onBlur={() => setShowDropdown(false)}
//           required
//         />
//         {showDropdown && (
//           <div className="dropdown-content">
//             <p onClick={() => handleDropdownItemClick('Service 1')}>Service 1</p>
//             <p onClick={() => handleDropdownItemClick('Service 2')}>Service 2</p>
//             <p onClick={() => handleDropdownItemClick('Service 3')}>Service 3</p>
//           </div>
//         )}
//       </InputGroup>
//     </div>
//   );
// }

// export default YourComponent;


import React from 'react'
import IBPService from "./Images/IBPService.jpg";
import HanaServices from "./Images/HanaService.jpg";
import MonitoringService from "./Images/MonitoringService.jpg";
import NodeJsService from "./Images/NodeJsService.jpg";
import JavaService from "./Images/JavaService.jpg";
import ReactjsService from "./Images/ReactjsService.jpg";
import DotNetService from "./Images/DotNetService.jpg";
import PHPService from "./Images/PHPService.jpg";
import AngularService from "./Images/AngularService.jpg";
import WordpressService from "./Images/WordpressService.jpg";
import { Link } from "react-router-dom";
import All from "./Images/img-body.jpg";
import map from "./Images/map.png";
import TM from "./Images/TM.png";
import Infosys from "./Images/Infosys.png";
import GC from "./Images/gitacloud.png";
import Dynpro from "./Images/dynpro.png";
import sai from "./Images/sai.png";
import sapture from "./Images/saptrue.png";
import wudza from "./Images/wudza.png";
import HIC from "./Images/HIC.png";
import ats from "./Images/ats.png";
import Aysmtech from "./Images/aysmtech.png";
import crave from "./Images/crave.png";
import acnovate from "./Images/acnovate.png";
import DG from "./Images/daynilgroup.png";
import crafsol from "./Images/crafsol.png";
import medha from "./Images/medha.png";
import yash from "./Images/yash.png";
import ST from "./Images/ST.png";
import Linksap from "./Images/Linksap.png";
import logix from "./Images/logix.png";
import Oxford from "./Images/Oxford.png";
import Hays from "./Images/Hays.png";
import SiyaTech from "./Images/SiyaTech.png";
import rudersoft from "./Images/rudersoft.png";
import contact from "./Images/img-contact.png";
import company from "./Images/ic-company.png";
import email from "./Images/ic-email.png";
import message from "./Images/ic-message.png";
import phone from "./Images/ic-phone.png";
import services from "./Images/ic-services.png";
import user from "./Images/ic-user.png";
import ContactForm from './ContactForm.js'; 

const Services = () => {
  const services = [
    { label: 'Web Development', value: 'web-development' },
    { label: 'App Development', value: 'app-development' },
    { label: 'SEO Services', value: 'seo-services' },
    { label: 'Cloud Solutions', value: 'cloud-solutions' },
  ];

  // Handle form submission success callback
  const handleFormSubmitSuccess = () => {
    console.log('Form successfully submitted on Contact Page');
  };
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };
  return (
    <>
    <div className='container-fluid servicess'>
            <div className='container our-services'>
        <h1>Our Services</h1>
        <p>
        &bull;<a href="/sap">SAP IBP</a>&bull;
  <a href="/saphana">SAP S4/HANA Implementation</a>&bull;
  <a href="/monitoring">SAP S4/Monitoring & Support</a><br/>
  &bull;<a href="/node">Node JS Development</a>&bull;
  <a href="/java">Java Development</a>&bull;
  <a href="/reactjs">React JS Development</a>&bull;
  <a href="/dotnet">Dot Net Development</a>&bull;
  <a href="/php">PHP Development</a><br />
  &bull;<a href="/angular">Angular JS Development</a>&bull;
  <a href="/wordpress">WordPress Development</a>
</p>
  
</div>
</div>
<div className="container services1">
      {/* Row 1 */}
      <div className="row align-items-center" style={{ paddingTop: '50px' }}>
        <div className="col-md-5 order-1 order-md-1">
          <img src={IBPService} className="img-fluids" alt="SAP IBP" title="SAP IBP" />
        </div>
        <div className="col-md-7 order-2 order-md-2 text-start">
          <h2>SAP IBP</h2>
          <p>
            SAP IBP allows you to make strategic and operational data-driven decisions in all aspects of your supply chain.
            Don’t have an SCM solution yet? Consider moving to IBP and S/4HANA, with Sales and Operations planning as a possible starting point.
          </p>
          <Link to="/sap" onClick={scrollToTop}>
            <button className="service-button" alt="Know More" title="Know More">
              Know More
            </button>
          </Link>
        </div>
      </div>

      {/* Row 2 */}
      <div className="row align-items-center" style={{ paddingTop: '50px' }}>
        <div className="col-md-7 order-2 order-md-1 text-end">
          <h2>SAP S/4HANA Implementation</h2>
          <p>
            S/4 HANA is a next-generation business suite meant to make digital transformation easier for businesses.
            The intelligent suite features a customizable user experience with SAP Fiori and is based on the powerful in-memory database SAP HANA.
          </p>
          <Link to="/saphana" onClick={scrollToTop}>
            <button className="service-button" alt="Know More" title="Know More">
              Know More
            </button>
          </Link>
        </div>
        <div className="col-md-5 order-1 order-md-2">
          <img src={HanaServices} className="img-fluids" alt="SAP S/4Hana Implementation" title="SAP S/4Hana Implementation" />
        </div>
      </div>

      {/* Row 3 */}
      <div className="row align-items-center" style={{ paddingTop: '50px' }}>
        <div className="col-md-5 order-1 order-md-1">
          <img src={MonitoringService} className="img-fluids" alt="SAP S/4 Monitoring and Support" title="SAP S/4 Monitoring and Support" />
        </div>
        <div className="col-md-7 order-2 order-md-2 text-start">
          <h2>SAP S/4 Monitoring & Support</h2>
          <p>
            Monitoring past and current information about the performance of the SAP HANA database is important to prevent
            performance issues and for root-cause analysis of problems.
          </p>
          <Link to="/monitoring" onClick={scrollToTop}>
            <button className="service-button" alt="Know More" title="Know More">
              Know More
            </button>
          </Link>
        </div>
      </div>

      {/* Row 4 */}
      <div className="row align-items-center" style={{ paddingTop: '50px' }}>
        <div className="col-md-7 order-2 order-md-1 text-end">
        <h2>React Native Development</h2>
          <p>
          At Bestowal, our dedicated React Native development team leverages the latest React
                JavaScript framework to create high-quality native apps for both Android and iOS. Our skilled
                designers and developers collaborate closely to build seamless cross-platform applications
                using React Native.
          </p>
          <Link to="/native" onClick={scrollToTop}>
            <button className="service-button" alt="Know More" title="Know More">
              Know More
            </button>
          </Link>
        </div>
        <div className="col-md-5 order-1 order-md-2">
          <img src={All} className="img-fluids" alt="React Native" title="React Native" />
        </div>
      </div>

      {/* Row 5 */}
      <div className="row align-items-center" style={{ paddingTop: '50px' }}>
        <div className="col-md-5 order-1 order-md-1">
          <img src={NodeJsService} className="img-fluids" alt="NodeJS Development" title="NodeJS Development" />
        </div>
        <div className="col-md-7 order-2 order-md-2 text-start">
        <h2>Node JS Development</h2>
          <p>
          As a specialized Node.js development company, we provide comprehensive application
                development and maintenance services, ensuring exceptional reliability and scalability for your
                business needs. Node.js is an open-source, cross-platform runtime environment known for its
                speed and efficiency in developing robust server tools and network applications.
          </p>
          <Link to="/node" onClick={scrollToTop}>
            <button className="service-button" alt="Know More" title="Know More">
              Know More
            </button>
          </Link>
        </div>
      </div>

      {/* Row 6 */}
      <div className="row align-items-center" style={{ paddingTop: '50px' }}>
        <div className="col-md-7 order-2 order-md-1 text-end">
        <h2>Java Development</h2>
          <p>
          In today’s tech-driven world, applications play a crucial role in various tasks across different
                platforms and operating systems. Java stands out as a versatile solution, enabling developers
                to create applications for both desktop and mobile devices seamlessly.
          </p>
          <Link to="/java" onClick={scrollToTop}>
            <button className="service-button" alt="Know More" title="Know More">
              Know More
            </button>
          </Link>
        </div>
        <div className="col-md-5 order-1 order-md-2">
          <img src={JavaService} className="img-fluids" alt="Java Development" title="Java Development" />
        </div>
      </div>

      {/* Row 7 */}
      <div className="row align-items-center" style={{ paddingTop: '50px' }}>
        <div className="col-md-5 order-1 order-md-1">
          <img src={AngularService} className="img-fluids" alt="AngularJS Development" title="AngularJS Development" />
        </div>
        <div className="col-md-7 order-2 order-md-2 text-start">
        <h2>Angular JS Development</h2>
          <p>
          Our developers excel in building rich user interfaces and creating responsive single-page web
applications using AngularJS. To leverage the full potential of AngularJS, hire our AngularJS
developers today. We offer expertise in:
 AngularJS Development with server-side technologies like ASP.NET, JSP/JAVA, PHP, Ruby
on Rails, etc.
 Portal Development
 Web Application Development

 E-Commerce and Shopping Cart Development
 Plug-in Development 
          </p>
          <Link to="/angular" onClick={scrollToTop}>
            <button className="service-button" alt="Know More" title="Know More">
              Know More
            </button>
          </Link>
        </div>
      </div>

      {/* Row 8 */}
      <div className="row align-items-center" style={{ paddingTop: '50px' }}>
        <div className="col-md-7 order-2 order-md-1 text-end">
        <h2>React JS Development</h2>
          <p>
          React.JS is powering 45% of websites on the internet. Many big brands like Netflix, Facebook,
      Instagram etc. Here you will find highly experienced React JS
Developers that have extensive knowledge and intense practice in utilizing the world’s most popular
JavaScript Framework- ReactJS. Our React Developers are highly experienced technical-beings with
great know-how knowledge of React JS Development. 
          </p>
          <Link to="/reactjs" onClick={scrollToTop}>
            <button className="service-button" alt="Know More" title="Know More">
              Know More
            </button>
          </Link>
        </div>
        <div className="col-md-5 order-1 order-md-2">
          <img src={ReactjsService} className="img-fluids" alt="ReactJS Development" title="ReactJS Development" />
        </div>
      </div>

      {/* Row 9 */}
      <div className="row align-items-center" style={{ paddingTop: '50px' }}>
        <div className="col-md-5 order-1 order-md-1">
          <img src={DotNetService} className="img-fluids" alt="DotNet Development" title="DotNet Development" />
        </div>
        <div className="col-md-7 order-2 order-md-2 text-start">
        <h2>Dot Net Development</h2>
          <p>
          Bestowal Systems and Services Private Limited is your ideal choice for .NET development. We
specialize in creating robust, multi-functional web applications, .NET-based applications, and both
internet and intranet websites. With years of experience and a commitment to the latest Microsoft
technologies, our experts deliver practical, up-to-date solutions across various industries.  
          </p>
          <Link to="/dotnet" onClick={scrollToTop}>
            <button className="service-button" alt="Know More" title="Know More">
              Know More
            </button>
          </Link>
        </div>
      </div>

      {/* Row 10 */}
      <div className="row align-items-center" style={{ paddingTop: '50px' }}>
        <div className="col-md-7 order-2 order-md-1 text-end">
        <h2>PHP Development</h2>
          <p>
          We use PHP Language in all the web development because PHP is an open-source server-side
scripting language which can be easily embedded into the HTML and CSS.PHP installation and
configuration is very easy so it doesn’t make any problem in the PHP Development. PHP provides a
high security which doesn’t require big changes to be done during the project development that why it
is chosen by the many developers and the companies.
          </p>
          <Link to="/php" onClick={scrollToTop}>
            <button className="service-button" alt="Know More" title="Know More">
              Know More
            </button>
          </Link>
        </div>
        <div className="col-md-5 order-1 order-md-2">
          <img src={PHPService} className="img-fluids" alt="PHP Development" title="PHP Development" />
        </div>
      </div>

      {/* Row 11 */}
      <div className="row align-items-center" style={{ paddingTop: '50px' }}>
        <div className="col-md-5 order-1 order-md-1">
          <img src={WordpressService} className="img-fluids" alt="Wordpress Development" title="Wordpress Development" />
        </div>
        <div className="col-md-7 order-2 order-md-2 text-start">
        <h2>WordPress Development</h2>
          <p>
          Whether you're launching a new site or revamping an existing one, our team delivers high-performance, customized WordPress solutions tailored to your needs. We offer custom themes with unique, responsive designs that perfectly capture your brand's essence. Our expertise in plugin development ensures that your site functions optimally with tailored features, and we provide reliable maintenance to keep everything running smoothly.   
          </p>
          <Link to="/wordpress" onClick={scrollToTop}>
            <button className="service-button" alt="Know More" title="Know More">
              Know More
            </button>
          </Link>
        </div>
      </div>
    </div>
        <div className="container Journey">
        <h1>Our Clients Journey Map</h1>
      </div>
      <div className="container-fluid map">
        <img src={map} alt="client journey map" title="Our Clients"/>
      </div>
      <div className="container client-section">
        <h2 className="client-heading">Our Clients</h2>
        <p className="client-description">
          Empowering Success Stories: Meet Our Valued Clients
        </p>
      </div>
      {/* <div className="container-fluid marquee">
  <div className="marquee-content">
    <a href="https://www.techmahindra.com/en-in/?f=1415427774" target="_blank" rel="noopener noreferrer">
      <img src={TM} alt="Tech Mahindra" title="Tech Mahindra" className="client-logo" />
    </a>
    <a href="https://www.infosys.com/" target="_blank" rel="noopener noreferrer">
      <img src={Infosys} alt="Infosys" title="Infosys" className="client-logo" />
    </a>
    <a href="https://www.gitacloud.com/" target="_blank" rel="noopener noreferrer">
      <img src={GC} alt="Gita Cloud" title="Gita Cloud" className="client-logo" />
    </a>
    <a href="https://www.dynproindia.com/" target="_blank" rel="noopener noreferrer">
      <img src={Dynpro} alt="Dynpro" title="Dynpro" className="client-logo" />
    </a>
    <a href="https://www.acnovate.com/" target="_blank" rel="noopener noreferrer">
      <img src={acnovate} alt="Acnovate" title="Acconvate" className="client-logo" />
    </a>
    <a href="https://www.sapture.com.au/" target="_blank" rel="noopener noreferrer">
      <img src={sapture} alt="sapture" title="Sapture" className="client-logo" />
    </a>
    <a href="https://www.wudza.com/" target="_blank" rel="noopener noreferrer">
      <img src={wudza} alt="Wudza" title="Wudza" className="client-logo" />
    </a>
    <a href="https://hicglobalsolutions.com/" target="_blank" rel="noopener noreferrer">
      <img src={HIC} alt="HIC Global" title="HIC Global" className="client-logo" />
    </a>
    <a href="https://www.ats-global.com/services/ats-consulting/" target="_blank" rel="noopener noreferrer">
      <img src={ats} alt="ATS Global" title="ATS Global" className="client-logo" />
    </a>
    <a href="https://aasymtech.com/" target="_blank" rel="noopener noreferrer">
      <img src={Aysmtech} alt="Aysmtech" title="Aysmtech" className="client-logo" />
    </a>
    <a href="https://www.craveinfotech.com/" target="_blank" rel="noopener noreferrer">
      <img src={crave} alt="Crave Infotech" title="Crave Infotech" className="client-logo" />
    </a>

    <a href="https://daynilgroup.com/" target="_blank" rel="noopener noreferrer">
      <img src={DG} alt="Daynilgroup" title="Daynilgroup" className="client-logo" />
    </a>
    <a href="https://crafsol.com/" target="_blank" rel="noopener noreferrer">
      <img src={crafsol} alt="Crafsol" title="Crafsol" className="client-logo" />
    </a>
    <a href="https://medha.eu/" target="_blank" rel="noopener noreferrer">
      <img src={medha} alt="Medha Partners" title="Medha Partners" className="client-logo" />
    </a>
    <a href="https://www.yash.com/contact-us/" target="_blank" rel="noopener noreferrer">
      <img src={yash} alt="Yash Technology" title="Yash Technology" className="client-logo" />
    </a>
    <a href="https://scandinaviantech.se/" target="_blank" rel="noopener noreferrer">
      <img src={ST} alt="Scandinaviantech" title="Scandinaviantech" className="client-logo" />
    </a>
    <a href="https://www.ruddersoft.com/" target="_blank" rel="noopener noreferrer">
      <img src={rudersoft} alt="Rudersoft" title="Rudersoft" className="client-logo" />
    </a>
    <a href="https://www.techmahindra.com/en-in/?f=1415427774" target="_blank" rel="noopener noreferrer">
      <img src={TM} alt="Tech Mahindra" title="Tech Mahindra" className="client-logo" />
    </a>
    <a href="https://www.infosys.com/" target="_blank" rel="noopener noreferrer">
      <img src={Infosys} alt="Infosys" title="Infosys" className="client-logo" />
    </a>
    <a href="https://www.gitacloud.com/" target="_blank" rel="noopener noreferrer">
      <img src={GC} alt="Gita Cloud" title="Gita Cloud" className="client-logo" />
    </a>
    <a href="https://www.dynproindia.com/" target="_blank" rel="noopener noreferrer">
      <img src={Dynpro} alt="Dynpro" title="Dynpro" className="client-logo" />
    </a>
    <a href="https://www.acnovate.com/" target="_blank" rel="noopener noreferrer">
      <img src={acnovate} alt="Acnovate" title="Acconvate" className="client-logo" />
    </a>
    <a href="https://www.sapture.com.au/" target="_blank" rel="noopener noreferrer">
      <img src={sapture} alt="sapture" title="Sapture" className="client-logo" />
    </a>
    <a href="https://www.wudza.com/" target="_blank" rel="noopener noreferrer">
      <img src={wudza} alt="Wudza" title="Wudza" className="client-logo" />
    </a>
    <a href="https://hicglobalsolutions.com/" target="_blank" rel="noopener noreferrer">
      <img src={HIC} alt="HIC Global" title="HIC Global" className="client-logo" />
    </a>
    <a href="https://www.ats-global.com/services/ats-consulting/" target="_blank" rel="noopener noreferrer">
      <img src={ats} alt="ATS Global" title="ATS Global" className="client-logo" />
    </a>
    <a href="https://aasymtech.com/" target="_blank" rel="noopener noreferrer">
      <img src={Aysmtech} alt="Aysmtech" title="Aysmtech" className="client-logo" />
    </a>
    <a href="http://linksapjobs.eu/" target="_blank" rel="noopener noreferrer">
      <img src={Linksap} alt="Crave Infotech" title="Crave Infotech" className="client-logo" />
    </a>
    <a href="http://www.logix-tech.com/" target="_blank" rel="noopener noreferrer">
      <img src={logix} alt="Crave Infotech" title="Crave Infotech" className="client-logo" />
    </a>
    <a href="https://www.oxfordcorp.com/en/" target="_blank" rel="noopener noreferrer">
      <img src={Oxford} alt="Crave Infotech" title="Crave Infotech" className="client-logo" />
    </a>
    <a href="http://hays.de/" target="_blank" rel="noopener noreferrer">
      <img src={Hays} alt="Crave Infotech" title="Crave Infotech" className="client-logo" />
    </a>
    <a href="http://siatech.asia/" target="_blank" rel="noopener noreferrer">
      <img src={SiyaTech} alt="Crave Infotech" title="Crave Infotech" className="client-logo" />
    </a>
    <a href="https://www.craveinfotech.com/" target="_blank" rel="noopener noreferrer">
      <img src={crave} alt="Crave Infotech" title="Crave Infotech" className="client-logo" />
    </a>

    <a href="https://daynilgroup.com/" target="_blank" rel="noopener noreferrer">
      <img src={DG} alt="Daynilgroup" title="Daynilgroup" className="client-logo" />
    </a>
    <a href="https://crafsol.com/" target="_blank" rel="noopener noreferrer">
      <img src={crafsol} alt="Crafsol" title="Crafsol" className="client-logo" />
    </a>
    <a href="https://medha.eu/" target="_blank" rel="noopener noreferrer">
      <img src={medha} alt="Medha Partners" title="Medha Partners" className="client-logo" />
    </a>
    <a href="https://www.yash.com/contact-us/" target="_blank" rel="noopener noreferrer">
      <img src={yash} alt="Yash Technology" title="Yash Technology" className="client-logo" />
    </a>
    <a href="https://scandinaviantech.se/" target="_blank" rel="noopener noreferrer">
      <img src={ST} alt="Scandinaviantech" title="Scandinaviantech" className="client-logo" />
    </a>
    <a href="https://www.ruddersoft.com/" target="_blank" rel="noopener noreferrer">
      <img src={rudersoft} alt="Rudersoft" title="Rudersoft" className="client-logo" />
    </a>
  </div>
     </div> */}
      <div className="container-fluid marquee">
      <marquee behavior="scroll" direction="start" scrollamount="10">
          <a
            href="https://www.techmahindra.com/en-in/?f=1415427774"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={TM}
              alt="Tech Mahindra"
              title="Tech Mahindra"
              className="client-logo"
              style={{ marginRight: '24px' }}
            />
          </a>
          <a
            href="https://www.infosys.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={Infosys}
              alt="Infosys"
              title="Infosys"
              className="client-logo"
              style={{ marginRight: '24px' }}
            />
          </a>
          <a
            href="https://www.gitacloud.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={GC}
              alt="Gita Cloud"
              title="Gita Cloud"
              className="client-logo"
              style={{ marginRight: '24px' }}
            />
          </a>
          <a
            href="https://www.dynproindia.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={Dynpro}
              alt="Dynpro"
              title="Dynpro"
              className="client-logo"
              style={{ marginRight: '24px' }}
            />
          </a>
          <a
            href="https://www.acnovate.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={acnovate}
              alt="Acnovate"
              title="Acconvate"
              className="client-logo"
              style={{ marginRight: '24px' }}
            />
          </a>
          <a
            href="https://www.sapture.com.au/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={sapture}
              alt="sapture"
              title="Sapture"
              className="client-logo"
              style={{ marginRight: '24px' }}
            />
          </a>
          <a
            href="https://www.wudza.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={wudza}
              alt="Wudza"
              title="Wudza"
              className="client-logo"
              style={{ marginRight: '24px' }}
            />
          </a>
          <a
            href="https://hicglobalsolutions.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={HIC}
              alt="HIC Global"
              title="HIC Global"
              className="client-logo"
              style={{ marginRight: '24px' }}
            />
          </a>
          <a
            href="https://www.ats-global.com/services/ats-consulting/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={ats}
              alt="ATS Global"
              title="ATS Global"
              className="client-logo"
              style={{ marginRight: '24px' }}
            />
          </a>
          <a
            href="https://aasymtech.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={Aysmtech}
              alt="Aysmtech"
              title="Aysmtech"
              className="client-logo"
              style={{ marginRight: '24px' }}
            />
          </a>
          <a
            href="https://www.craveinfotech.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={crave}
              alt="Crave Infotech"
              title="Crave Infotech"
              className="client-logo"
              style={{ marginRight: '24px' }}
            />
          </a>

          <a
            href="https://daynilgroup.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={DG}
              alt="Daynilgroup"
              title="Daynilgroup"
              className="client-logo"
              style={{ marginRight: '24px' }}
            />
          </a>
          <a
            href="https://crafsol.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={crafsol}
              alt="Crafsol"
              title="Crafsol"
              className="client-logo"
              style={{ marginRight: '24px' }}
            />
          </a>
          <a href="https://medha.eu/" target="_blank" rel="noopener noreferrer">
            <img
              src={medha}
              alt="Medha Partners"
              title="Medha Partners"
              className="client-logo"
              style={{ marginRight: '24px' }}
            />
          </a>
          <a
            href="https://www.yash.com/contact-us/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={yash}
              alt="Yash Technology"
              title="Yash Technology"
              className="client-logo"
              style={{ marginRight: '24px' }}
            />
          </a>
          <a
            href="https://scandinaviantech.se/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={ST}
              alt="Scandinaviantech"
              title="Scandinaviantech"
              className="client-logo"
              style={{ marginRight: '24px' }}
            />
          </a>
          <a
            href="https://www.ruddersoft.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={rudersoft}
              alt="Rudersoft"
              title="Rudersoft"
              className="client-logo"
              style={{ marginRight: '24px' }}
            />
          </a>
          <a
            href="https://www.techmahindra.com/en-in/?f=1415427774"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={TM}
              alt="Tech Mahindra"
              title="Tech Mahindra"
              className="client-logo"
              style={{ marginRight: '24px' }}
            />
          </a>
          <a
            href="https://www.infosys.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={Infosys}
              alt="Infosys"
              title="Infosys"
              className="client-logo"
              style={{ marginRight: '24px' }}
            />
          </a>
          <a
            href="https://www.gitacloud.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={GC}
              alt="Gita Cloud"
              title="Gita Cloud"
              className="client-logo"
              style={{ marginRight: '24px' }}
            />
          </a>
          <a
            href="https://www.dynproindia.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={Dynpro}
              alt="Dynpro"
              title="Dynpro"
              className="client-logo"
              style={{ marginRight: '24px' }}
            />
          </a>
          <a
            href="https://www.acnovate.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={acnovate}
              alt="Acnovate"
              title="Acconvate"
              className="client-logo"
              style={{ marginRight: '24px' }}
            />
          </a>
          <a
            href="https://www.sapture.com.au/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={sapture}
              alt="sapture"
              title="Sapture"
              className="client-logo"
              style={{ marginRight: '24px' }}
            />
          </a>
          <a
            href="https://www.wudza.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={wudza}
              alt="Wudza"
              title="Wudza"
              className="client-logo"
              style={{ marginRight: '24px' }}
            />
          </a>
          <a
            href="https://hicglobalsolutions.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={HIC}
              alt="HIC Global"
              title="HIC Global"
              className="client-logo"
              style={{ marginRight: '24px' }}
            />
          </a>
          <a
            href="https://www.ats-global.com/services/ats-consulting/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={ats}
              alt="ATS Global"
              title="ATS Global"
              className="client-logo"
              style={{ marginRight: '24px' }}
            />
          </a>
          <a
            href="https://aasymtech.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={Aysmtech}
              alt="Aysmtech"
              title="Aysmtech"
              className="client-logo"
              style={{ marginRight: '24px' }}
            />
          </a>
          <a
            href="http://linksapjobs.eu/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={Linksap}
              alt="Crave Infotech"
              title="Crave Infotech"
              className="client-logo"
              style={{ marginRight: '24px' }}
            />
          </a>
          <a
            href="http://www.logix-tech.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={logix}
              alt="Crave Infotech"
              title="Crave Infotech"
              className="client-logo"
              style={{ marginRight: '24px' }}
            />
          </a>
          <a
            href="https://www.oxfordcorp.com/en/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={Oxford}
              alt="Crave Infotech"
              title="Crave Infotech"
              className="client-logo"
              style={{ marginRight: '24px' }}
            />
          </a>
          <a href="http://hays.de/" target="_blank" rel="noopener noreferrer">
            <img
              src={Hays}
              alt="Crave Infotech"
              title="Crave Infotech"
              className="client-logo"
              style={{ marginRight: '24px' }}
            />
          </a>
          <a
            href="http://siatech.asia/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={SiyaTech}
              alt="Crave Infotech"
              title="Crave Infotech"
              className="client-logo"
              style={{ marginRight: '24px' }}
            />
          </a>
          <a
            href="https://www.craveinfotech.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={crave}
              alt="Crave Infotech"
              title="Crave Infotech"
              className="client-logo"
              style={{ marginRight: '24px' }}
            />
          </a>

          <a
            href="https://daynilgroup.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={DG}
              alt="Daynilgroup"
              title="Daynilgroup"
              className="client-logo"
              style={{ marginRight: '24px' }}
            />
          </a>
          <a
            href="https://crafsol.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={crafsol}
              alt="Crafsol"
              title="Crafsol"
              className="client-logo"
              style={{ marginRight: '24px' }}
            />
          </a>
          <a href="https://medha.eu/" target="_blank" rel="noopener noreferrer">
            <img
              src={medha}
              alt="Medha Partners"
              title="Medha Partners"
              className="client-logo"
              style={{ marginRight: '24px' }}
            />
          </a>
          <a
            href="https://www.yash.com/contact-us/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={yash}
              alt="Yash Technology"
              title="Yash Technology"
              className="client-logo"
              style={{ marginRight: '24px' }}
            />
          </a>
          <a
            href="https://scandinaviantech.se/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={ST}
              alt="Scandinaviantech"
              title="Scandinaviantech"
              className="client-logo"
              style={{ marginRight: '24px' }}
            />
          </a>
          <a
            href="https://www.ruddersoft.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={rudersoft}
              alt="Rudersoft"
              title="Rudersoft"
              className="client-logo"
              style={{ marginRight: '24px' }}
            />
          </a>
        </marquee>
      </div>
        <div className="container-fluid contact-section" id="contact-section">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-12">
                <ContactForm
                  services={services} 
                  onSubmitSuccess={handleFormSubmitSuccess}
                />
              </div>
            </div>
          </div>
        </div>
    </>
  )
}

export default Services