import React from 'react'
import { useState } from 'react';
import { useNavigate } from "react-router-dom";
const ECommerce = () => {
  const [activeTab, setActiveTab] = useState("All");
  const navigate = useNavigate();
  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };
  const goBack = () => {
    navigate(-1);
  };
  return (
    <>
            <div className="container tabbing">
        <ul className="nav nav-tabs">
          {/* Add a Back button */}
          <li className="tab-item">
            <button
              className="back-button"
              onClick={goBack}
              style={{
                color: "#fff",
                position: "absolute",
                left: "0%",
                marginRight: "20px",
                top: "21%",
                transform: "translateX(-460%)",
              }} // Add margin to create space
            >
              Back
            </button>
          </li>

          {/* Render Tabs */}
          {["All", "React", "Wordpress", "PHP", "E-Commerce", "CMS"].map(
            (tab) => (
              <li className="tab-item" key={tab}>
                <a
                  className={`tab-link ${activeTab === tab ? "active" : ""}`}
                  href="#"
                  data-filter={tab}
                  onClick={() => handleTabClick(tab)}
                >
                  {tab}
                </a>
              </li>
            )
          )}
        </ul>
      </div>
      <hr className="full-width-hr" />
      <div className="container-fluid bestotabbing">
        <div className="container bestotab">
          <h1>E-Commerce</h1>
          <br />
          <p>
            <b>Technology</b>:-&nbsp;React JS
          </p>
          <br />
          <p>
            <b>Used Skills</b>:-&nbsp;PHP,HTML5,CSS3,jQuery,Responsive,UI
          </p>
        </div>
      </div>
    </>
  )
}

export default ECommerce
