// import React from "react";
// import BenefitsOfSEO from "./Images/BenefitsOfSEO.png";
// import Card from "react-bootstrap/Card";
// import Row from "react-bootstrap/Row";
// import Col from "react-bootstrap/Col";
// import globalScope from "./Images/globalScope.png";
// import nationalSco1 from "./Images/nationalSco1.png";
// import localSco from "./Images/localSco.png";
// import ecomsco from "./Images/ecomsco.png";
// import LinkSeo from "./Images/LinkSeo.png";
// import technicalsco from "./Images/technicalsco.png";
// import WhatIsSEO from "./Images/WhatIsSEO.png";
// import ProcessToDevelopSEO from "./Images/ProcessToDevelopSEO.png";
// const SEO = () => {
//   return (
//     <>
//       <header className="container-fluid headerpro">
//         <h4 className="react-reveal text-center">Search Engine Optimization</h4>
//       </header>
//       <div className="container">
//         <div className="wrap">
//           <div className="content">
//             <h3>Acquire Top-Notch SEO Services For Your Company's Needs</h3>
//             <p>
//               A reputable marketing and finest SEO company with headquarters in
//               Pune, Bestowal Systems & Services offers both local and
//               international companies a full spectrum of search engine
//               optimization solutions. With our assistance, you can increase your
//               consumer base and achieve excellent results on search engines like
//               Google, Bing, Yahoo, Baidu, etc.We have established ourselves as a
//               leading SEO agency in Pune. We have become the trusted partner for
//               start-ups, medium-sized businesses, and international brands in
//               India for their paid and organic marketing needs. We have
//               established ourselves as reliable SEO, SMM, and PPC partners for
//               start-ups, medium-sized companies, and international brands in
//               India
//             </p>
//             <p>
//               Our SEO specialists can help you drive more traffic to your
//               website, take your business to the next level and reach a wider
//               audience. Search Engine Optimization (SEO) is no longer a secret
//               strategy because most firms recognise its importance. It's
//               possible that different businesses have distinct needs. But they
//               all need to be exposed to the online environment. Although people
//               recognize the importance of SEO, they may not be willing to
//               increase their marketing budget for it. Such individuals need to
//               alter how they view SEO. They should be aware that search engine
//               optimization is an effective investment rather than an expense.
//               Being in the top three slots on the first page of search results
//               can bring significant returns on your investment in SEO.
//             </p>
//             <img src={BenefitsOfSEO} />
//             <h3>Know Ins And Outs Of SEO</h3>
//             <p>
//               SEO is an acronym for "search engine optimization". SEO is a
//               process that helps to generate organic traffic from search engines
//               by improving the visibility of a website in search engine results
//               pages. SEO involves optimizing various elements of a website, such
//               as its content, structure, and backlinks, to make it more
//               attractive to search engines. Keywords are important for SEO as
//               they help users find your website through search engines. However,
//               it's important not to overuse keywords as it can make the content
//               difficult to read and discourage users from visiting the site
//               again. Meta tags are titles of code that provide search engines
//               with information about your website that needs to be displayed.
//               Backlinks are created when another website includes a link to your
//               website. The quality and relevance of the shared link also matter
//               in SEO. Sharing your website on social media platforms, such as
//               Twitter, Facebook, Instagram, LinkedIn, YouTube, or Pinterest, can
//               also help to improve your SEO.
//             </p>
//             <p>
//               SEO is a crucial part of a comprehensive digital marketing
//               strategy, as it helps businesses reach potential customers through
//               search engines, which are often the first point of contact for
//               people looking for products or services online. SEO involves both
//               on-page optimization (making changes to a website's content and
//               structure to improve its ranking) and off-page optimization
//               (building backlinks from other websites to increase the
//               credibility and authority of a website). The importance of SEO has
//               grown in recent years as more and more businesses have recognized
//               the value of being found online and the impact it can have on
//               their bottom line. Effective SEO can lead to higher search engine
//               rankings, increased traffic to a website, improved user
//               experience, and ultimately more sales and revenue for a business
//             </p>
//             <h3>How Can Professional SEO Services Help Your Business?</h3>
//             <p>
//               There are many benefits of SEO, which is why many businesses
//               invest in affordable SEO services. A reputable digital marketing
//               company like Bestowal Systems & Services can help you take
//               advantage of these benefits by providing efficient and
//               well-planned SEO services. Some of the benefits of SEO include a
//               competitive edge, improved local search ranking, strengthened
//               brand identity, and valuable insights into customer behavior.
//               Additionally, a results-driven SEO strategy can drive long-term
//               business growth and profitability. Keep in mind that SEO takes
//               time and doesn't provide instant results, but the long-term payoff
//               is well worth the investment.
//             </p>
//             <Row>
//               <Col md={{ span: 6 }}>
//                 <Card className="singlecard">
//                   <Card.Body className="text-center h-100 d-flex flex-column justify-content-center align-items-center">
//                     <Card.Title className="text-muted fs-4">
//                       Raising Credibility
//                     </Card.Title>
//                     <Card.Text className="h-50 mt-3">
//                       <ul>
//                         <li className="my-2">
//                           Your brand value will increase with better content.
//                         </li>
//                         <li className="my-2">
//                           Your brand value will increase with better content.
//                         </li>
//                         <li className="my-2">
//                           Higher rankings from search engines boost credibility
//                           and authority
//                         </li>
//                         <li>
//                           Efficient SEO services improve website speed and
//                           enhance content quality.
//                         </li>
//                       </ul>
//                     </Card.Text>
//                   </Card.Body>
//                 </Card>
//               </Col>
//               <Col md={{ span: 6 }}>
//                 <Card className="singlecard">
//                   <Card.Body className="text-center h-100 d-flex flex-column justify-content-center align-items-center">
//                     <Card.Title className="text-muted fs-4">
//                       Raising Credibility
//                     </Card.Title>
//                     <Card.Text className="h-50 mt-3">
//                       <ul>
//                         <li className="my-2">
//                           Traffic depends on user experience.
//                         </li>
//                         <li className="my-2">
//                           Relevant information improves visitor engagement and
//                           extends visit duration.
//                         </li>
//                         <li className="my-2">
//                           Optimized title and meta tags increase interaction
//                         </li>
//                         <li>Mobile-friendliness enhances user experience.</li>
//                       </ul>
//                     </Card.Text>
//                   </Card.Body>
//                 </Card>
//               </Col>
//               <Col md={{ span: 6 }}>
//                 <Card className="singlecard">
//                   <Card.Body className="text-center h-100 d-flex flex-column justify-content-center align-items-center">
//                     <Card.Title className="text-muted fs-4">
//                       Increases Conversion Rates
//                     </Card.Title>
//                     <Card.Text className="h-50 mt-3">
//                       <ul>
//                         <li className="my-2">
//                           SEO services enhance revenue through lead generation
//                           and higher conversion rates.
//                         </li>
//                         <li className="my-2">
//                           Observing customer activity provides insights into
//                           their needs and purchase patterns.
//                         </li>
//                         <li>Allows direct communication with target market.</li>
//                       </ul>
//                     </Card.Text>
//                   </Card.Body>
//                 </Card>
//               </Col>
//               <Col md={{ span: 6 }}>
//                 <Card className="singlecard">
//                   <Card.Body className="text-center h-100 d-flex flex-column justify-content-center align-items-center">
//                     <Card.Title className="text-muted fs-4">
//                       Enhances User Interaction
//                     </Card.Title>
//                     <Card.Text className="h-50 mt-3">
//                       <ul>
//                         <li className="my-2">
//                           Traffic depends on user experience.
//                         </li>
//                         <li className="my-2">
//                           Your brand value will increase with better content.
//                         </li>
//                         <li className="my-2">
//                           Higher rankings from search engines boost credibility
//                           and authority
//                         </li>
//                         <li>
//                           Efficient SEO services improve website speed and
//                           enhance content quality.
//                         </li>
//                       </ul>
//                     </Card.Text>
//                   </Card.Body>
//                 </Card>
//               </Col>
//             </Row>
//             <h3>Cutting - Edge Search Engine Optimization Services</h3>
//             <p>
//               Regardless of your target market or niche, we are convinced that
//               our wide range of marketing services can help you succeed. No
//               matter how big your company is, our skilled SEO services in India
//               will help it expand. To see how we may help, view our list of
//               internet marketing services :
//             </p>
//             <div className="row seorow">
//             <div className="col-md-4">
//               <div class="flip-card">
//                 <div class="flip-card-inner">
//                   <div class="flip-card-front">
//                     <img src={globalScope} className="seoimg" />
//                     <h3>Global SEO </h3>
//                   </div>
//                   <div class="flip-card-back">
//                     <p>
//                       Search engine optimization initiatives that target a
//                       global audience are referred to as global or international
//                       SEO campaigns. Utilize our SEO services to expand your
//                       business and reach a global audience. These services
//                       guarantee greater business growth and higher company
//                       revenue.
//                     </p>
//                   </div>
//                 </div>
//               </div>
//             </div>
//             <div className="col-md-4">
//               <div class="flip-card">
//                 <div class="flip-card-inner">
//                   <div class="flip-card-front">
//                     <img src={nationalSco1} className="seoimg" />
//                     <h3>National SEO</h3>
//                   </div>
//                   <div class="flip-card-back">
//                     <p>
//                       You might be able to swiftly position yourself as a
//                       national leader thanks to the extensive market expertise
//                       of our highly skilled SEO experts. We put a lot of effort
//                       into positioning the website of your business for a
//                       variety of nationwide niche terms.
//                     </p>
//                   </div>
//                 </div>
//               </div>
//             </div>
//             <div className="col-md-4">
//               <div class="flip-card">
//                 <div class="flip-card-inner">
//                   <div class="flip-card-front">
//                     <img src={localSco} className="seoimg" />
//                     <h3>Local SEO</h3>
//                   </div>
//                   <div class="flip-card-back">
//                     <p>
//                       Leverage our SEO services to reach out to your local
//                       market and expand your company. These services ensure
//                       greater business expansion and increased revenue for your
//                       company. We offer the ideal solution for you, whether you
//                       work in the retail, hospitality, eCommerce, or educational
//                       sectors, among others.
//                     </p>
//                   </div>
//                 </div>
//               </div>
//             </div>
//             <div className="col-md-4">
//               <div class="flip-card">
//                 <div class="flip-card-inner">
//                   <div class="flip-card-front">
//                     <img src={ecomsco} className="seoimg" />
//                     <h3>E commerce SEO</h3>
//                   </div>
//                   <div class="flip-card-back">
//                     <p>
//                       Your ecommerce store's optimization is crucial. Your
//                       retail website will rank higher in the search results
//                       thanks to e-commerce engine optimization services. This
//                       gives you the option to promote your internet business and
//                       sell things.
//                     </p>
//                   </div>
//                 </div>
//               </div>
//             </div>
//             <div className="col-md-4">
//               <div class="flip-card">
//                 <div class="flip-card-inner">
//                   <div class="flip-card-front">
//                     <img src={LinkSeo} className="seoimg" />
//                     <h3>Link Building</h3>
//                   </div>
//                   <div class="flip-card-back">
//                     <p>
//                       We use the optimum mix of tried-and-true, smart, and
//                       cutting-edge link-building strategies in order to rank
//                       highly in Google, Bing, and other search engines. In order
//                       for your business to have a strong online presence, we
//                       also make sure that your social media pages, website, and
//                       any other online properties are in line with those goals.
//                     </p>
//                   </div>
//                 </div>
//               </div>
//             </div>
//             <div className="col-md-4">
//               <div class="flip-card">
//                 <div class="flip-card-inner">
//                   <div class="flip-card-front">
//                     <img src={technicalsco} className="seoimg" />
//                     <h3>Technical SEO</h3>
//                   </div>
//                   <div class="flip-card-back">
//                     <p>
//                       This involves optimizing the technical aspects of a
//                       website to make it more attractive to search engines. This
//                       includes optimizing the website's loading speed, ensuring
//                       that it's easy to navigate, and making it easy for search
//                       engines to crawl and index the website's pages.
//                     </p>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//           <h3 className="dotneth3">Why Partner With The Best SEO Company</h3>
//           <p>With years of expertise providing SEO services in Pune, Bestowal Systems & Services takes a conscientious approach to every project it undertakes, no matter how big or small. Because they are familiar with the process of creating custom websites, our developers and designers work diligently to produce high-quality products on schedule. Additionally, we offer complete online solutions and assist businesses in growing their audiences. In order to ensure that the end result is pleasing to the clients, our developers pay special attention to their needs and expectations. We have become a major player in the world of website building thanks to our combined expertise in the field and experience in the industry.</p>
//           <p>
//               <strong>Simple CRM Tools :-  </strong> By giving updates on ongoing work, we use CRM systems to assist develop and manage connections with our clients. The tool is straightforward and user-friendly.
//             </p>
//             <p>
//               <strong>Enhance ROI and Sales :-  </strong> Our SEO consulting services in India guarantee that a business gains from an SEO strategy. We boost your sales and return on investment with our SEO techniques.
//             </p>
//             <p>
//               <strong>Dedicated Project Manager :-</strong> For improved coordination and communication, we offer a dedicated account manager since we comprehend the value of a human touch.
//             </p>
//             <p>
//               <strong>Keyword Targeting :- </strong>We focus on identifying keywords that are pertinent to your business, product, or service and that also meet the needs of your target market.
//             </p>
//             <p>
//               <strong>Reasonably Priced Services :- </strong> We offer moderately cost services, and you can be confident that you will always get what you paid for. Hidden fees with shockingly high prices are not something we support.
//             </p>
//             <p>
//               <strong>Reliability and Transparency :- </strong>We believe that you should always be aware of the strategies we are implementing for your business. We do this because we want you to fully trust what we are doing. As a result, we make care to keep you informed about everything.
//             </p>
//             <p>
//               <strong>360-Degree Solutions :- </strong> In our opinion, search engine optimization (SEO) is both an art and a science. As a result, in order to give you results that are both exceptional and sturdy, we strike a balance between a number of elements
//             </p>
//             <p>
//               <strong>Performance Evaluation :-  </strong> Our daily, weekly, and monthly reports let you know how your website is doing and what is scheduled for the upcoming weeks.

//             </p><br/>
//             <img src={ WhatIsSEO} className="seoimg1"/>
//             <h3>Our SEO Protocols For Your Company's Growth</h3>
//             <p>
//               <strong>Business Evaluation :-</strong>First, we examine the nature of your company, including its offerings in terms of goods and services. We then visit your website and research your sector further. We examine your objectives because doing so enables us to plan our keyword research and determine what will be most effective for your company.
//             </p>
//             <p>
//               <strong>Planning :- </strong>We create both short-term and long-term execution strategies as soon as we fully comprehend your company. We create plans for keyword rankings, content, outreach, and any other service you may have chosen from us using a data-driven method. After everything is ready, we finally move on to the execution phase.
//             </p>
//             <p>
//               <strong>Adaptive Execution :- </strong> Your assignment is now being handled by one of our specialised teams. For instance, our content team instantly starts working on developing SEO-friendly content for your website. Our social media team will start building your brand today and expanding your social media exposure. The SEO team has access to outreach options and link-building tactics. All of our teams collaborate to give you an effective execution that is sure to produce results as we increase your online presence. We keep working till the end result is not achieved.
//             </p>
//             <p>
//               <strong>Perpetual Assistance :- </strong> We won't be finished until your objectives are met. We at Bestowal Systems & Services are by your side every step of the process. Weekly updates will be given to you so you can keep track of your development. We would be pleased to dispel any questions you may have. We also discuss with you the future marketing initiatives strategies. Additionally, we are interested in learning about your long-term objectives so that we can, if necessary, adjust our existing strategy.
//             </p>
//             <p>
//               <strong>Tracking :- </strong> We keep you updated on the status of your campaign at all times. For this reason, we also give all of our clients monthly reports. We use a number of different tracking systems in addition to Basecamp for real-time communication and document sharing. Additionally, we create clear reports so you can see how our efforts have impacted your bottom line.
//             </p>
//             <p>
//               <strong>Prolonged Growth :-</strong>Our efforts may yield benefits quickly or gradually, depending on a number of variables. However, your website should start to undergo certain adjustments. We continue to implement long-term strategies while keeping an eye on these growth variables to propel and keep you at the top.
//             </p>
//             <img src={ProcessToDevelopSEO} className="seoimg1"/>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };

// export default SEO;

import React,{useState} from "react";
import Android from "./Images/Android.jpeg";
import { Image } from "react-bootstrap";
import logo11 from "./Images/arrow_outward.png";
import circle from "./Images/check_circle.png";
import node1 from "./Images/node1.jpeg";
import group from "./Images/Group.png";
import group1 from "./Images/Group1.png";
import SEO from "./Images/SEO.png";
import search1 from "./Images/search1.png";
import SEO1 from "./Images/digital_wellbeing.png";
import SEO2 from "./Images/add_task.png";
import SEO3 from "./Images/SEO3.png";
import SEO4 from "./Images/diversity_1.png";
import SEO5 from "./Images/SEO5.png";
import SEO6 from "./Images/SEO6.png";
import Accordion from "react-bootstrap/Accordion";
import Modal1 from "./Modal1";
const IOS = () => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };
  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  return (
    <>
      <div className="container native1">
        <div className="row nativerow">
          <div className="col-md-4">
            <img src={SEO}/>
          </div>
          <div className="col-md-8">
            <h2>Search Engine Optimization</h2>
          </div>
        </div>
      </div>
      <div className="container-fluid nativecolor">
        <div className="container native">
          <h2>Dominate Search Results: Unleash the Power of SEO</h2>
          <p>
          Bestowal Systems & Services is a dynamic digital marketing agency renowned for its strategic approach and proven expertise in driving online success for small businesses. We excel in creating tailored digital strategies that encompass SEO, content marketing, social media management, and comprehensive analytics. Our team of specialists is dedicated to maximizing client ROI by implementing data-driven strategies that resonate with target audiences and elevate brand visibility. Unlike cookie-cutter solutions, we prioritize personalized client partnerships, ensuring that every campaign is meticulously crafted to meet unique business objectives. With a commitment to transparency and measurable results, we empower businesses to thrive in the digital landscape through continuous optimization
          {!isExpanded ? (
              <span className="dots">...</span>
            ) : (
              <span className="more">
               and adaptation to industry trends. Whether it's enhancing online presence, boosting conversions, or scaling growth, Bestowal Systems & Services delivers impactful digital marketing solutions that drive lasting success.
              </span>
            )}
          </p>
          <button className="sapbtn" onClick={toggleExpand}>
            {isExpanded ? "Read Less" : "Read More"}
            <Image src={logo11} className="img0" alt="Read more icon" />
          </button>
        </div>
      </div>
      <div className=" container android">
        <h2>Solutions for Your Needs</h2>
        <div className="row">
          <div className="col-md-4">
            <div className="process1">
              <span className="digitalspan1">
                {" "}
                <img src={SEO1} className="seoimg" />
              </span>
              <h4>Value For Business</h4>
            </div>
          </div>
          <div className="col-md-4">
            <div className="process1">
              <span className="digitalspan1">
                {" "}
                <img src={SEO2} className="seoimg" />
              </span>
              <h4>Proven Result</h4>
            </div>
          </div>
          <div className="col-md-4">
            <div className="process1">
              <span className="digitalspan1">
                {" "}
                <img src={SEO3} className="seoimg" />
              </span>
              <h4>Industry Experience</h4>
            </div>
          </div>
          <div className="col-md-4">
            <div className="process1">
              <span className="digitalspan1">
                {" "}
                <img src={SEO4} className="seoimg" />
              </span>
              <h4>Team Of Experts</h4>
            </div>
          </div>
          <div className="col-md-4">
            <div className="process1">
              <span className="digitalspan1">
                {" "}
                <img src={SEO5} className="seoimg" />
              </span>
              <h4>Cutting Edge Tools</h4>
            </div>
          </div>
          <div className="col-md-4">
            <div className="process1">
              <span className="digitalspan1">
                {" "}
                <img src={SEO6} className="seoimg" />
              </span>
              <h4>100% Transparency</h4>
            </div>
          </div>
        </div>
        <button onClick={handleOpenModal}>
            Get In Touch <Image src={logo11} className="img6" alt="Arrow" />
          </button>
          {isModalOpen && <Modal1 onClose={handleCloseModal} />}
      </div>
      <div className="container-fluid seo">
        <div className="container android">
          <h2>Boost Your Business Growth with Professional SEO</h2>
          <p>
            Invest in SEO services for a competitive edge, better local ranking,
            brand growth, and customer insights. SEO takes time but delivers
            long-term benefits.
          </p>
          <div class="container accordion">
            <div class="accordion-container">
              {/* <div class="accordion">
                <input type="checkbox" id="accordion-item-1" class="accordion-checkbox"/>
                <label for="accordion-item-1" class="accordion-header">
                    <span>01</span>
                    <p>Raising Credibility</p>
                    <div class="arrow"></div>
                </label>
                <div class="accordion-body">
                    <ul class="custom-list">
                        <li>Your brand value will increase with better content.</li>
                        <li>Your website will draw more visitors if it is educational and up-to-date.</li>
                        <li>Higher rankings from search engines boost credibility and authority.</li>
                        <li>Efficient SEO services improve website speed and enhance content quality.</li>
                    </ul>
                </div>
            </div> */}
              <div class="accordion">
                <input
                  type="checkbox"
                  id="accordion-item-1"
                  class="accordion-checkbox"
                />
                <label for="accordion-item-1" class="accordion-header">
                  <span>01</span>
                  <p>Raising Credibility</p>
                  <div class="arrow"></div>
                </label>
                <div class="accordion-body">
                  <ul class="custom-list">
                    <li>Your brand value will increase with better content.</li>
                    <li>
                      Your website will draw more visitors if it is educational
                      and up-to-date.
                    </li>
                    <li>
                      Higher rankings from search engines boost credibility and
                      authority.
                    </li>
                    <li>
                      Efficient SEO services improve website speed and enhance
                      content quality.
                    </li>
                  </ul>
                </div>
              </div>

              <div class="accordion">
                <input
                  type="checkbox"
                  id="accordion-item-2"
                  class="accordion-checkbox"
                />
                <label for="accordion-item-2" class="accordion-header">
                  <span>02</span>
                  <p>Enhances User Interaction</p>
                  <div class="arrow"></div>
                </label>
                <div class="accordion-body">
                  <ul class="custom-list">
                    <li>Your brand value will increase with better content.</li>
                    <li>
                      Your website will draw more visitors if it is educational
                      and up-to-date.
                    </li>
                    <li>
                      Higher rankings from search engines boost credibility and
                      authority.
                    </li>
                    <li>
                      Efficient SEO services improve website speed and enhance
                      content quality.
                    </li>
                  </ul>
                </div>
              </div>
              <div class="accordion">
                <input
                  type="checkbox"
                  id="accordion-item-3"
                  class="accordion-checkbox"
                />
                <label for="accordion-item-3" class="accordion-header">
                  <span>03</span>
                  <p>Increases Conversion Rates</p>
                  <div class="arrow"></div>
                </label>
                <div class="accordion-body">
                  <ul class="custom-list">
                    <li>Your brand value will increase with better content.</li>
                    <li>
                      Your website will draw more visitors if it is educational
                      and up-to-date.
                    </li>
                    <li>
                      Higher rankings from search engines boost credibility and
                      authority.
                    </li>
                    <li>
                      Efficient SEO services improve website speed and enhance
                      content quality.
                    </li>
                  </ul>
                </div>
              </div>
              <div class="accordion">
                <input
                  type="checkbox"
                  id="accordion-item-4"
                  class="accordion-checkbox"
                />
                <label for="accordion-item-4" class="accordion-header">
                  <span>04</span>
                  <p>Business Recognition</p>
                  <div class="arrow"></div>
                </label>
                <div class="accordion-body">
                  <ul class="custom-list">
                    <li>Your brand value will increase with better content.</li>
                    <li>
                      Your website will draw more visitors if it is educational
                      and up-to-date.
                    </li>
                    <li>
                      Higher rankings from search engines boost credibility and
                      authority.
                    </li>
                    <li>
                      Efficient SEO services improve website speed and enhance
                      content quality.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container android">
        <div className="row androidrow1">
          <div className="col-md-4">
            <div className="nodeinfo1">
              <div className="subinfo1">
              <h2>360-Degree Solutions</h2>
                  <p>
                  In our opinion, search engine optimization (SEO) is both an art and a science. As a result, in order to give you results that are both exceptional and sturdy, we strike a balance between a number of elements.                  </p>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="nodeinfo1">
              <div className="subinfo1">
              <h2>Reliability and Transparency</h2>
                  <p>
                  We believe that you should always be aware of the strategies we are implementing for your business. We do this because we want you to fully trust what we are doing. As a result, we make care to keep you informed about everything.                  </p>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="nodeinfo1">
              <div className="subinfo1">
              <h2>Enhance ROI and Sales</h2>
                  <p>
                  Our SEO consulting services in India guarantee that a business gains from an SEO strategy. We boost your sales and return on investment with our SEO techniques.                  </p>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="nodeinfo1">
              <div className="subinfo1">
              <h2>Keyword Targeting</h2>
                  <p>
                  We focus on identifying keywords that are pertinent to your business, product, or service and that also meet the needs of your target market.                  </p>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="nodeinfo1">
              <div className="subinfo1">
              <h2>Performance Evaluation</h2>
                  <p>
                  Our daily, weekly, and monthly reports let you know how your website is doing and what is scheduled for the upcoming weeks.                  </p>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="nodeinfo1">
              <div className="subinfo1">
              <h2>Dedicated Project Manager</h2>
                  <p>
                  For improved coordination and communication, we offer a dedicated account manager since we comprehend the value of a human touch.                  </p>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="nodeinfo1">
              <div className="subinfo1">
              <h2>Reasonably Priced Services</h2>
                  <p>
                  We offer moderately cost services, and you can be confident that you will always get what you paid for. Hidden fees with shockingly high prices are not something we support.                  </p>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="nodeinfo1">
              <div className="subinfo1">
              <h2>Simple CRM Tools</h2>
                  <p>
                  By giving updates on ongoing work, we use CRM systems to assist develop and manage connections with our clients. The tool is straightforward and user-friendly.                  </p>
              </div>
            </div>
          </div>
        </div>

        </div>
    </>
  );
};

export default IOS;
