import React,{useState} from "react";
import { Image } from "react-bootstrap";
import logo11 from "./Images/arrow_outward.png";
import native from "./Images/rnative.png";
import search1 from "./Images/research1.png";
import lamp from "./Images/lamp.png";
import curve from "./Images/curve.png";
import goal from "./Images/goal.png";
import Card from "react-bootstrap/Card";
import logo5 from "./Images/devices_other.png";
import logo from "./Images/rule_settings.png";
import action from "./Images/action_key.png";
import sub from "./Images/subject.png";
import account from "./Images/account_tree.png";
import emoji from "./Images/emoji_objects.png";
const Native = () => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };
  return (
    <>
      <div className="container native1">
        <div className="row nativerow">
          <div className="col-md-7">
            <img src={native}/>
          </div>
          <div className="col-md-5">
            <h2>React Native Development</h2>
          </div>
        </div>
      </div>
      <div className="container-fluid nativecolor">
        <div className="container native">
          <h2>Understanding React Native</h2>
          <p>
            Bestowal has a dedicated React Native development team that uses the
            latest React JavaScript framework and libraries to develop native
            Android and iOS apps. Our competent designers and proficient
            developers collaborate closely to build cross-platform Android and
            iOS applications using the React Native platform. Organizations
            worldwide are rapidly adopting React Native, one of the most popular
            frameworks, to create engaging, feature-rich, and native user
            experiences across platforms. If you're looking to develop apps that
            will revolutionize both Android and iOS markets, React Native
            Development is the way to go.React
            {!isExpanded ? (
              <span className="dots">.....</span>
            ) : (
              <span className="more">
               Native is a framework that allows developers to build native mobile apps using JavaScript. It enables the creation of applications for multiple platforms with a single codebase. This approach can significantly reduce the development time and overall cost of building a mobile app. Additionally, it eliminates the need for two separate teams for native development, as a single React Native team can efficiently handle both platforms.
              </span>
            )}
          </p>
          <button className="sapbtn" onClick={() => toggleExpand(0)}>
            {isExpanded ? "Read Less" : "Read More"}
            <span className="material-symbols-outlined">east</span>
          </button>
        </div>
      </div>
      <div className="container native">
        <h2>Key Benefits Of React Native</h2>
        <div className="row">
          <div className="col-md-6">
            <div className="nodeinfo">
              <div className="nodecontent">
                <div className="subinfo1">
                  <h2>Astounding Performance</h2>
                  <p>
                    Choosing React Native programming has this as its main
                    advantage. This open-source framework allows for faster page
                    loading than traditional JavaScript. This indicates that as
                    more consumers are likely to open your app, you will
                    benefit.
                  </p>
                </div>

                <p></p>
              </div>
            </div>
            <div className="nodeinfo">
              <div className="nodecontent">
                <div className="subinfo1">
                  <h2>Reusable Code</h2>
                  <p>
                  React Native allows developers to reuse code across iOS and Android platforms, reducing development and maintenance time significantly.
                  </p>
                </div>

                <p></p>
              </div>
            </div>
            <div className="nodeinfo">
              <div className="nodecontent">
                <div className="subinfo1">
                  <h2>User-Friendly and High-Performing UI:</h2>
                  <p>
                  React Native simplifies the development of mobile UI features, ensuring apps perform optimally and provide a smooth user experience.
                  </p>
                </div>

                <p></p>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="nodeinfo">
              <div className="nodecontent">
                <div className="subinfo1">
                  <h2>Adaptable and Modular</h2>
                  <p>
                  React Native's modular architecture facilitates seamless collaboration among developers, accelerating app development and enabling quick updates.
                  </p>
                </div>

                <p></p>
              </div>
            </div>
            <div className="nodeinfo">
              <div className="nodecontent">
                <div className="subinfo1">
                  <h2>Component Reusability: </h2>
                  <p>
                  React Native promotes the reuse of components, enhancing development efficiency. These components can also be compiled into native apps swiftly.
                  </p>
                </div>

                <p></p>
              </div>
            </div>
            <div className="nodeinfo">
              <div className="nodecontent">
                <div className="subinfo1">
                  <h2>Rich Ecosystem</h2>
                  <p>
                  With its open-source nature, React Native offers a rich ecosystem of tools, libraries, and frameworks, enabling developers to create powerful cross-platform applications economically.
                  </p>
                </div>

                <p></p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="fluid-container dgcolor">
        <div className="container process">
          <h2>Navigate Our Work Process</h2>

          <div className="processframe">
            <div className="process1">
              <span className="digitalspan1">
                {" "}
                <img src={search1} className="d111" />
              </span>
              <h4>Research</h4>
            </div>
            <div className="process1">
              <span className="digitalspan1">
                {" "}
                <img src={lamp} className="d111" />
              </span>
              <h4>Find Ideas</h4>
            </div>
            <div className="process1">
              <span className="digitalspan1">
                {" "}
                <img src={curve} className="d111" />
              </span>
              <h4>Optimization</h4>
            </div>
            <div className="process1">
              <span className="digitalspan1">
                {" "}
                <img src={goal} className="d111" />
              </span>
              <h4>Reach Target</h4>
            </div>
          </div>
        </div>
      </div>
      <div className="container native">
        <h2>React Native Road Map</h2>
        <div className="row nativeframe">
          <div className="nativecontent">
            <span className="nativespan">
              <img src={action} className="nativeimg" />
            </span>
            <h3>Define</h3>
            <p> Define the scope and objectives of the project.</p>
          </div>
          <div className="nativecontent">
            <span className="nativespan">
              <img src={sub} className="nativeimg" />
            </span>
            <h3>Research</h3>
            <p>Conduct Market research and competitive analysis.</p>
          </div>
        </div>
        <div className="row nativeframe1">
          <div className="nativecontent">
            <span className="nativespan">
              <img src={sub} className="nativeimg" />
            </span>
            <h3>Test</h3>
            <p>
              Test the app thoroughly to ensure that it is stable and performs
              as expected on all targeted platforms.
            </p>
          </div>
          <div className="nativecontent">
            <span className="nativespan">
              <img src={account} className="nativeimg" />
            </span>
            <h3>Development</h3>
            <p>
              Set up the development environment and configure any necessary
              tools and dependencies.
            </p>
          </div>
          <div className="nativecontent">
            <span className="nativespan">
              <img src={emoji} className="nativeimg" />
            </span>
            <h3>Wireframe</h3>
            <p>Create wireframes and a detailed design plan for the app</p>
          </div>
        </div>
        <div className="row nativeframe">
          <div className="nativecontent">
            <span className="nativespan">
              <img src={sub} className="nativeimg" />
            </span>
            <h3>Deploy</h3>
            <p>
               Deploy the app to the relevant app stores (Google Play, App
              Store) and make any necessary updates or bug fixes.
            </p>
          </div>
          <div className="nativecontent">
            <span className="nativespan">
              <img src={action} className="nativeimg" />
            </span>
            <h3>Launch</h3>
            <p> Launch the app & Monitor the app's performance</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Native;
