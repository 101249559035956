// // import React, { useState } from "react";
// // import phpimg from "./Images/whyphp1.png";
// import processdevimg from "./Images/ProcessToDevelopPHP.png";
// import backendlogo from "./Images/javafram1.png";
// import frontendlogo from "./Images/frontend.png";
// import database from "./Images/database.png";
// import helper from "./Images/helper.png";
// import testing from "./Images/testing.png";
// import os from "./Images/os.png";
// import Card from "react-bootstrap/Card";
// // import Button from "react-bootstrap/Button";
// // const PHP = () => {
// //   const [isExpanded, setIsExpanded] = useState([
// //     false,
// //     false,
// //     false,
// //     false,
// //     false,
// //     false,
// //   ]);

// //   // const toggleExpand = (index) => {
// //   //   const newExpandedState = [...isExpanded];
// //   //   newExpandedState[index] = !newExpandedState[index];
// //   //   setIsExpanded(newExpandedState);
// //   // };
// //   const toggleExpand = () => {
// //     setIsExpanded(!isExpanded);
// //   };
// //   return (
// //     <>
// //       <div className="headerpro">
// //         <h1 className="react-reveal">PHP Development</h1>
// //       </div>
// //       <div className="container">
// //         <section className="Wrap">
// //           <div className="content">
// //             <h3 className="h3-head">
// //               Offshore PHP Development With Bestowal Systems & Services
// //             </h3>
// //             <p>
// //               We specialize in creating dynamic, user-friendly websites using
// //               the PHP programming language. With a team of experienced
// //               developers, we can bring your ideas to life and deliver
// //               high-quality solutions that meet your specific needs.PHP is a
// //               widely-used, open-source programming language that is particularly
// //               well-suited for web development. It allows for the creation of
// //               interactive, data-driven websites and is a popular choice for
// //               businesses of all sizes.
// //               <br />
// //               <br />
// //               Our PHP web development services include:Custom website
// //               development , E-commerce website development , Content management
// //               systems (CMS) , Website redesign and maintenance , API
// //               developmentand more.We also stay up-to-date with the latest trends
// //               and technologies in PHP web development, including popular
// //               frameworks such as Laravel, CodeIgniter, and Yii, and use industry
// //               best practices to ensure that your website is secure, reliable,
// //               and scalable.In addition, we also provide other services like SEO,
// //               UI/UX Design, Responsive Design, and Digital Marketing.PHP is a
// //               cost-effective solution for web development, as it can save you
// //               money both during the development process and for ongoing
// //               maintenance. Being open-source, there are no additional costs for
// //               downloading or licensing, and hosting expenses are typically
// //               lower. Additionally, the ease of entry for PHP developers leads to
// //               increased competition in the market, driving down prices.
// //               <br />
// //               <br />
// //               We are one of the top PHP development companies with a track
// //               record of completing the best PHP projects that are outsourced.
// //               Our dedication to providing high-quality solutions geared on
// //               resolving the particular problems our clients experience has been
// //               a major factor in our success. Never forget that we are always
// //               adding the brightest minds to our team in order to give each of
// //               our customers experiences that are unparalleled in addition to our
// //               wonderful PHP goods.For small to large organisations, we provide
// //               excellent offshore PHP development services and solutions. Because
// //               of our extensive knowledge and experience with several PHP-based
// //               frameworks, we can provide you with reliable, scalable, and
// //               affordable PHP programming services.
// //             </p>
// //             <h3 className="h3-head">What Justifies PHP Development?</h3>
// //             <p>
// //               Because of its outstanding interoperability with other
// //               technologies, dynamic and collaborative inviting design, support
// //               for all major browsers, and database connectivity, PHP is the most
// //               widely used programming language. It enables programmers to design
// //               PHP web applications that are interesting while spending little
// //               money or time on them. Through PHP Web Development Services, it
// //               creates web applications in PHP, which adds dynamism and agility.
// //               "PHP is a cost-effective solution for web development, as it can
// //               save you money both during the development process and for ongoing
// //               maintenance. Being open-source, there are no additional costs for
// //               downloading or licensing, and hosting expenses are typically
// //               lower. Additionally, the ease of entry for PHP developers leads to
// //               increased competition in the market, driving down prices.Today,
// //               companies are trying to improve the user experience by fusing
// //               highly functional and feature-rich components. One of the
// //               quickest, most powerful, most dynamic frameworks for web
// //               development is PHP.
// //             </p>
// //             <img
// //               src={phpimg}
// //               title="Why Choose PHP For Web Development"
// //               className="phpimg"
// //             />
// //           </div>
// //         </section>
// //         <h3 className="h3-head">
// //           Why We Pride In Our Custom PHP Development Services?
// //         </h3>
// //         <div>
// //           {/* <div className="container">
// //             <div className="row rowphp">
// //               <div className="col-md-4">
// //                 <Card style={{ width: "18rem" }} className="cardphp">
// //                   <Card.Body>
// //                     <Card.Title>Expertise In Agile Methodology</Card.Title>
// //                     <Card.Text>
// //                       Our seasoned PHP developers have extensive experience in
// //                       creating multi-user, high-performance, secure, and
// //                       high-quality websites and{" "}
// //                       {!isExpanded ? (
// //                         <span className="dots">...</span>
// //                       ) : (
// //                         <span className="more">
// //                           Our team possesses strong technical abilities and are
// //                           well-versed in the latest technological trends and
// //                           advancements.
// //                         </span>
// //                       )}
// //                     </Card.Text>
// //                     <span onClick={toggleExpand} className="myBtn">
// //                       {isExpanded ? "Read less" : "Read more"}
// //                     </span>
// //                   </Card.Body>
// //                 </Card>
// //                 <Card style={{ width: "18rem" }} className="cardphp">
// //                   <Card.Body>
// //                     <Card.Title>Expertise In Agile Methodology</Card.Title>
// //                     <Card.Text>
// //                       Our seasoned PHP developers have extensive experience in
// //                       creating multi-user, high-performance, secure, and
// //                       high-quality websites and{" "}
// //                       {!isExpanded ? (
// //                         <span className="dots">...</span>
// //                       ) : (
// //                         <span className="more">
// //                           Our team possesses strong technical abilities and are
// //                           well-versed in the latest technological trends and
// //                           advancements.
// //                         </span>
// //                       )}
// //                     </Card.Text>
// //                     <span onClick={toggleExpand} className="myBtn">
// //                       {isExpanded ? "Read less" : "Read more"}
// //                     </span>
// //                   </Card.Body>
// //                 </Card>
// //                 <Card style={{ width: "18rem" }} className="cardphp">
// //                   <Card.Body>
// //                     <Card.Title>Expertise In Agile Methodology</Card.Title>
// //                     <Card.Text>
// //                       Our seasoned PHP developers have extensive experience in
// //                       creating multi-user, high-performance, secure, and
// //                       high-quality websites and{" "}
// //                       {!isExpanded ? (
// //                         <span className="dots">...</span>
// //                       ) : (
// //                         <span className="more">
// //                           Our team possesses strong technical abilities and are
// //                           well-versed in the latest technological trends and
// //                           advancements.
// //                         </span>
// //                       )}
// //                     </Card.Text>
// //                     <span onClick={toggleExpand} className="myBtn">
// //                       {isExpanded ? "Read less" : "Read more"}
// //                     </span>
// //                   </Card.Body>
// //                 </Card>
// //                 <Card style={{ width: "18rem" }} className="cardphp">
// //                   <Card.Body>
// //                     <Card.Title>Expertise In Agile Methodology</Card.Title>
// //                     <Card.Text>
// //                       Our seasoned PHP developers have extensive experience in
// //                       creating multi-user, high-performance, secure, and
// //                       high-quality websites and{" "}
// //                       {!isExpanded ? (
// //                         <span className="dots">...</span>
// //                       ) : (
// //                         <span className="more">
// //                           Our team possesses strong technical abilities and are
// //                           well-versed in the latest technological trends and
// //                           advancements.
// //                         </span>
// //                       )}
// //                     </Card.Text>
// //                     <span onClick={toggleExpand} className="myBtn">
// //                       {isExpanded ? "Read less" : "Read more"}
// //                     </span>
// //                   </Card.Body>
// //                 </Card>
// //                 <Card style={{ width: "18rem" }} className="cardphp">
// //                   <Card.Body>
// //                     <Card.Title>Expertise In Agile Methodology</Card.Title>
// //                     <Card.Text>
// //                       Our seasoned PHP developers have extensive experience in
// //                       creating multi-user, high-performance, secure, and
// //                       high-quality websites and{" "}
// //                       {!isExpanded ? (
// //                         <span className="dots">...</span>
// //                       ) : (
// //                         <span className="more">
// //                           Our team possesses strong technical abilities and are
// //                           well-versed in the latest technological trends and
// //                           advancements.
// //                         </span>
// //                       )}
// //                     </Card.Text>
// //                     <span onClick={toggleExpand} className="myBtn">
// //                       {isExpanded ? "Read less" : "Read more"}
// //                     </span>
// //                   </Card.Body>
// //                 </Card>
// //                 <Card style={{ width: "18rem" }} className="cardphp">
// //                   <Card.Body>
// //                     <Card.Title>Expertise In Agile Methodology</Card.Title>
// //                     <Card.Text>
// //                       Our seasoned PHP developers have extensive experience in
// //                       creating multi-user, high-performance, secure, and
// //                       high-quality websites and{" "}
// //                       {!isExpanded ? (
// //                         <span className="dots">...</span>
// //                       ) : (
// //                         <span className="more">
// //                           Our team possesses strong technical abilities and are
// //                           well-versed in the latest technological trends and
// //                           advancements.
// //                         </span>
// //                       )}
// //                     </Card.Text>
// //                     <span onClick={toggleExpand} className="myBtn">
// //                       {isExpanded ? "Read less" : "Read more"}
// //                     </span>
// //                   </Card.Body>
// //                 </Card>
// //               </div>
// //             </div>
// //           </div> */}
// //           <div className="container">
// //             <div className="row">
// //               <Card style={{ width: "18rem" }} className="cardphp">
// //                 <Card.Body>
// //                   <Card.Title>Expertise In Agile Methodology</Card.Title>
// //                   <Card.Text>
// //                     Our seasoned PHP developers have extensive experience in
// //                     creating multi-user, high-performance, secure, and
// //                     high-quality websites and{" "}
// //                     {!isExpanded ? (
// //                       <span className="dots">...</span>
// //                     ) : (
// //                       <span className="more">
// //                         Our team possesses strong technical abilities and are
// //                         well-versed in the latest technological trends and
// //                         advancements.
// //                       </span>
// //                     )}
// //                   </Card.Text>
// //                   <span onClick={toggleExpand} className="myBtn">
// //                     {isExpanded ? "Read less" : "Read more"}
// //                   </span>
// //                 </Card.Body>
// //               </Card>
// //               <Card style={{ width: "18rem" }} className="cardphp">
// //                 <Card.Body>
// //                   <Card.Title>Expertise In Agile Methodology</Card.Title>
// //                   <Card.Text>
// //                     Our seasoned PHP developers have extensive experience in
// //                     creating multi-user, high-performance, secure, and
// //                     high-quality websites and{" "}
// //                     {!isExpanded ? (
// //                       <span className="dots">...</span>
// //                     ) : (
// //                       <span className="more">
// //                         Our team possesses strong technical abilities and are
// //                         well-versed in the latest technological trends and
// //                         advancements.
// //                       </span>
// //                     )}
// //                   </Card.Text>
// //                   <span onClick={toggleExpand} className="myBtn">
// //                     {isExpanded ? "Read less" : "Read more"}
// //                   </span>
// //                 </Card.Body>
// //               </Card>{" "}
// //               <Card style={{ width: "18rem" }} className="cardphp">
// //                 <Card.Body>
// //                   <Card.Title>Expertise In Agile Methodology</Card.Title>
// //                   <Card.Text>
// //                     Our seasoned PHP developers have extensive experience in
// //                     creating multi-user, high-performance, secure, and
// //                     high-quality websites and{" "}
// //                     {!isExpanded ? (
// //                       <span className="dots">...</span>
// //                     ) : (
// //                       <span className="more">
// //                         Our team possesses strong technical abilities and are
// //                         well-versed in the latest technological trends and
// //                         advancements.
// //                       </span>
// //                     )}
// //                   </Card.Text>
// //                   <span onClick={toggleExpand} className="myBtn">
// //                     {isExpanded ? "Read less" : "Read more"}
// //                   </span>
// //                 </Card.Body>
// //               </Card>{" "}
// //               <Card style={{ width: "18rem" }} className="cardphp">
// //                 <Card.Body>
// //                   <Card.Title>Expertise In Agile Methodology</Card.Title>
// //                   <Card.Text>
// //                     Our seasoned PHP developers have extensive experience in
// //                     creating multi-user, high-performance, secure, and
// //                     high-quality websites and{" "}
// //                     {!isExpanded ? (
// //                       <span className="dots">...</span>
// //                     ) : (
// //                       <span className="more">
// //                         Our team possesses strong technical abilities and are
// //                         well-versed in the latest technological trends and
// //                         advancements.
// //                       </span>
// //                     )}
// //                   </Card.Text>
// //                   <span onClick={toggleExpand} className="myBtn">
// //                     {isExpanded ? "Read less" : "Read more"}
// //                   </span>
// //                 </Card.Body>
// //               </Card>{" "}
// //               <Card style={{ width: "18rem" }} className="cardphp">
// //                 <Card.Body>
// //                   <Card.Title>Expertise In Agile Methodology</Card.Title>
// //                   <Card.Text>
// //                     Our seasoned PHP developers have extensive experience in
// //                     creating multi-user, high-performance, secure, and
// //                     high-quality websites and{" "}
// //                     {!isExpanded ? (
// //                       <span className="dots">...</span>
// //                     ) : (
// //                       <span className="more">
// //                         Our team possesses strong technical abilities and are
// //                         well-versed in the latest technological trends and
// //                         advancements.
// //                       </span>
// //                     )}
// //                   </Card.Text>
// //                   <span onClick={toggleExpand} className="myBtn">
// //                     {isExpanded ? "Read less" : "Read more"}
// //                   </span>
// //                 </Card.Body>
// //               </Card>{" "}
// //               <Card style={{ width: "18rem" }} className="cardphp">
// //                 <Card.Body>
// //                   <Card.Title>Expertise In Agile Methodology</Card.Title>
// //                   <Card.Text>
// //                     Our seasoned PHP developers have extensive experience in
// //                     creating multi-user, high-performance, secure, and
// //                     high-quality websites and{" "}
// //                     {!isExpanded ? (
// //                       <span className="dots">...</span>
// //                     ) : (
// //                       <span className="more">
// //                         Our team possesses strong technical abilities and are
// //                         well-versed in the latest technological trends and
// //                         advancements.
// //                       </span>
// //                     )}
// //                   </Card.Text>
// //                   <span onClick={toggleExpand} className="myBtn">
// //                     {isExpanded ? "Read less" : "Read more"}
// //                   </span>
// //                 </Card.Body>
// //               </Card>
// //             </div>
// //           </div>
// //         </div>
// //         <h3 className="h3-head">Customized PHP Solutions To Suit Your Needs</h3>
// //         <p>
// //           <strong>Custom PHP Apps :</strong> Our team utilizes their extensive
// //           knowledge of object-oriented programming, MVC, and core PHP to create
// //           and deliver a range of custom PHP web applications. Our PHP
// //           development company employs the most appropriate PHP frameworks, such
// //           as CodeIgniter, CakePHP, Laravel, Zend, Yii, and Symfony, to develop
// //           enterprise-grade applications
// //         </p>
// //         <p>
// //           <strong>Custom eCommerce Solutions with PHP :</strong> Our team has
// //           the expertise to combine the right set of technologies to build
// //           top-of-the-line eCommerce solutions that include the latest features
// //           and functionality. We have the ability to create highly personalized
// //           eCommerce platforms.
// //         </p>
// //         <p>
// //           <strong>Customized CMS Website Development :</strong> Our team has
// //           extensive experience in developing open-source CMS such as WordPress,
// //           Drupal, Joomla, Magento, etc. We create highly tailored websites by
// //           utilizing the full capabilities of these CMS platforms. Our PHP
// //           developers are skilled in leveraging the power of these systems to
// //           build successful websites
// //         </p>
// //         <p>
// //           <strong>Expert Social Networking Development :</strong> Our team
// //           utilizes the latest trends in PHP to create highly engaging, visually
// //           appealing, and interactive social networking websites. Our developers
// //           also incorporate top-of-the-line features into these sites.
// //         </p>
// //         <p>
// //           <strong>API Integration Services :</strong> Our team can seamlessly
// //           integrate your public, private, or internal APIs into your PHP system
// //           and CMS. We can also incorporate third-party APIs into your PHP
// //           websites as per your requirements by utilizing the various interfaces
// //           provided by these APIs.
// //         </p>
// //         <p>
// //           <strong>Customized Business Web Portals :</strong> Our developers have
// //           extensive experience in developing industry-specific web portals using
// //           PHP, including B2C, B2B, enterprise, and corporate portals. As a
// //           trusted website development company, we are committed to meeting the
// //           unique needs of our clients.
// //         </p>
// //         <h3 className="h3-head">Our PHP Tech Stack</h3>
// //         <div className="main">
// //           <div className="row rowphp">
// //             <div className="col-md-4">
// //               <Card className="cardphp">
// //                 <Card.Img variant="top" src={backendlogo} className="mt-1" />
// //                 <Card.Body>
// //                   <Card.Title>Backend</Card.Title>
// //                   <Card.Text>
// //                   Laravel, Yii2, Zend, slim, Symfony
// //                   </Card.Text>
// //                 </Card.Body>
// //               </Card>
// //             </div>
// //             <div className="col-md-4">
// //               <Card className="cardphp">
// //                 <Card.Img variant="top" src={frontendlogo} className="mt-1" />
// //                 <Card.Body>
// //                   <Card.Title>Backend</Card.Title>
// //                   <Card.Text>
// // Vanilla, VueJS, jQuery
// //                   </Card.Text>
// //                 </Card.Body>
// //               </Card>
// //             </div>
// //             <div className="col-md-4">
// //               <Card className="cardphp">
// //                 <Card.Img variant="top" src={database} className="mt-1" />
// //                 <Card.Body>
// //                   <Card.Title>Data Management</Card.Title>
// //                   <Card.Text>
// // MySQL, MariaDB,PostgreSQL,MongoDB
// //                   </Card.Text>
// //                 </Card.Body>
// //               </Card>
// //             </div>
// //             <div className="col-md-4">
// //               <Card className="cardphp">
// //                 <Card.Img variant="top" src={helper} className="mt-1" />
// //                 <Card.Body>
// //                   <Card.Title> Optimization Helper</Card.Title>
// //                   <Card.Text>Elasticsearch, RabbitMQ
// //                   </Card.Text>
// //                 </Card.Body>
// //               </Card>
// //             </div>
// //             <div className="col-md-4">
// //               <Card className="cardphp">
// //                 <Card.Img variant="top" src={testing} className="mt-1" />
// //                 <Card.Body>
// //                   <Card.Title>HTTP Server</Card.Title>
// //                   <Card.Text>
// //                 Apache,Nginx
// //                   </Card.Text>
// //                 </Card.Body>
// //               </Card>
// //             </div>
// //             <div className="col-md-4">
// //               <Card className="cardphp">
// //                 <Card.Img variant="top" src={os} className="mt-1" />
// //                 <Card.Body>
// //                   <Card.Title>OS And Platforms</Card.Title>
// //                   <Card.Text>
// //                   Ubantu,Centose,Docker,
// //                   </Card.Text>
// //                 </Card.Body>
// //               </Card>
// //             </div>
// //           </div>
// //         </div>
// //         <h3 className="h3-head">Our Process</h3>
// //         <p>
// //           The cornerstone of Bestowal Systems & Service's ideals has always been
// //           and always will be exceptional customer service.
// //           Our firm adopts a proactive stance to comprehend your industry and
// //           business issues. If you're a startup, we'll use the MVP/MLP approach
// //           to assist you join the market affordably. Hire PHP experts from
// //           Bestowal Systems & Services not only to programme but also to serve
// //           your company's demands and objectives.
// //           We provide you with the most appropriate pricing model based on the
// //           scale of the job, the clarity of the objectives, and the nature of the
// //           collaboration. T&M is a terrific fit for large, complicated projects
// //           with shifting requirements. It is also possible to operate on the
// //           Fixed Price model if your project has a well-defined and meticulously
// //           created software specification, whose requirements won't alter
// //           drastically throughout the development stage.
// //         </p>
// //         <img
// //           src={processdevimg}
// //           className="phpimg"
// //           title="Process Of PHP Web Development"
// //         />
// //       </div>
// //     </>
// //   );
// // };

// // export default PHP;

// import React,{useState} from "react";
// import php from "./Images/php.png";
// import { Image } from "react-bootstrap";
// import logo11 from "./Images/arrow_outward.png";
// import phpimg from "./Images/whyphp1.png";
// const PHP = () => {
//   const [isExpanded, setIsExpanded] = useState(false);

//   const toggleExpand = () => {
//     setIsExpanded(!isExpanded);
//   };
//   return (
//     <>
//       {/* php */}
//       <div className="container native1">
//         <div className="row nativerow">
//           <div className="col-md-4">
//             <img src={php} width={300} />
//           </div>
//           <div className="col-md-8">
//             <h2>PHP Development</h2>
//           </div>
//         </div>
//       </div>
//       {/* php in bestowal */}
//       <div className="container-fluid nativecolor">
//         <div className="container native">
//           <h2>Offshore PHP Development With Bestowal Systems & Services</h2>
//           <p>
//           We specialize in creating dynamic, user-friendly websites using the PHP programming language. With a team of experienced developers, we bring your ideas to life and deliver high-quality solutions tailored to your specific needs. PHP, a widely-used, open-source programming language, is particularly well-suited for web development and enables the creation of interactive, data-driven websites, making it a popular choice for businesses of all sizes. Our PHP web development services include custom website development, e-commerce website development, content management systems (CMS), website redesign and maintenance, API development, and more. We stay up-to-date with the latest trends and technologies in PHP web development, utilizing popular frameworks such as Laravel, CodeIgniter, and Yii, and adhere to industry best practices to ensure your website is secure, reliable, and scalable. In addition to PHP development, we offer services like SEO, UI/UX Design, Responsive Design, and Digital Marketing.
//             {!isExpanded ? (
//               <span className="dots">...</span>
//             ) : (
//               <span className="more">
//                 PHP is a cost-effective solution for web development, saving you money both during the development process and in ongoing maintenance. Being open-source, PHP incurs no additional costs for downloading or licensing, and hosting expenses are typically lower. Furthermore, the ease of entry for PHP developers increases market competition, which helps to drive down prices.
//               </span>
//             )}
//           </p>
//           <button className="sapbtn" onClick={() => toggleExpand(0)}>
//             {isExpanded ? "Read Less" : "Read More"}
//             <Image src={logo11} className="img0" />
//           </button>
//         </div>
//       </div>
//       {/* why php */}
//       <div className="container about">
//         <div className="row aboutusrow">
//           <div className="col-md-5">
//             <img src={phpimg} height={400} />
//           </div>
//           <div className="col-md-7">
//             <h2>What Justifies PHP Development?</h2>
//             <p>
//               Because of its outstanding interoperability with other
//               technologies, dynamic and collaborative inviting design, support
//               for all major browsers, and database connectivity, PHP is the most
//               widely used programming language. It enables programmers to design
//               PHP web applications that are interesting while spending little
//               money or time on them. Through PHP Web Development Services, it
//               creates web applications in PHP, which adds dynamism and agility.
//               "PHP is a cost-effective solution for web development, as it can
//               save you money both during the development process and for ongoing
//               maintenance. Being open-source, there are no additional costs for
//               downloading or licensing, and hosting expenses are typically
//               lower. Additionally, the ease of entry for PHP developers leads to
//               increased competition in the market, driving down prices.Today,
//               companies are trying to improve the user experience by fusing
//               highly functional and feature-rich components. One of the
//               quickest, most powerful, most dynamic frameworks for web
//               development is PHP.
//             </p>
//           </div>
//         </div>
//       </div>
//       {/* custom php service */}
//       <div className="container-fluid seo">
//         <div className="container android">
//           <h2>Why We Pride In Our Custom PHP Development Services?</h2>
//           <div class="container accordion">
//             <div class="accordion-container">
//               {/* <div class="accordion">
//                 <input type="checkbox" id="accordion-item-1" class="accordion-checkbox"/>
//                 <label for="accordion-item-1" class="accordion-header">
//                     <span>01</span>
//                     <p>Raising Credibility</p>
//                     <div class="arrow"></div>
//                 </label>
//                 <div class="accordion-body">
//                     <ul class="custom-list">
//                         <li>Your brand value will increase with better content.</li>
//                         <li>Your website will draw more visitors if it is educational and up-to-date.</li>
//                         <li>Higher rankings from search engines boost credibility and authority.</li>
//                         <li>Efficient SEO services improve website speed and enhance content quality.</li>
//                     </ul>
//                 </div>
//             </div> */}
//               <div class="accordion">
//                 <input
//                   type="checkbox"
//                   id="accordion-item-1"
//                   class="accordion-checkbox"
//                 />
//                 <label for="accordion-item-1" class="accordion-header">
//                   <span>01</span>
//                   <p>Expertise In Agile Methodology</p>
//                   <div class="arrow"></div>
//                 </label>
//                 <div class="accordion-body">
//                   Our team of skilled PHP professionals has been trained in
//                   various agile methodologies and have successfully applied them
//                   in our projects. We utilize an agile development approach that
//                   provides a dynamic environment for PHP development, encourages
//                   creativity, and ensures excellence in both long-term and
//                   short-term projects.
//                 </div>
//               </div>

//               <div class="accordion">
//                 <input
//                   type="checkbox"
//                   id="accordion-item-2"
//                   class="accordion-checkbox"
//                 />
//                 <label for="accordion-item-2" class="accordion-header">
//                   <span>02</span>
//                   <p>Decades Of Experience</p>
//                   <div class="arrow"></div>
//                 </label>
//                 <div class="accordion-body">
//                   We have been delivering high-quality PHP web applications for
//                   over a decade. Our certified developers possess a deep
//                   understanding of PHP, allowing them to tackle even the most
//                   complex projects while maintaining vital performance,
//                   scalability, and security standards.
//                 </div>
//               </div>
//               <div class="accordion">
//                 <input
//                   type="checkbox"
//                   id="accordion-item-3"
//                   class="accordion-checkbox"
//                 />
//                 <label for="accordion-item-3" class="accordion-header">
//                   <span>03</span>
//                   <p>Flexible Service Models</p>
//                   <div class="arrow"></div>
//                 </label>
//                 <div class="accordion-body">
//                   We offer a range of engagement models to accommodate the
//                   diverse needs of businesses for PHP development. Our
//                   experienced developers work with the best development
//                   practices and adhere to all guidelines for quality assurance.
//                 </div>
//               </div>
//               <div class="accordion">
//                 <input
//                   type="checkbox"
//                   id="accordion-item-4"
//                   class="accordion-checkbox"
//                 />
//                 <label for="accordion-item-4" class="accordion-header">
//                   <span>04</span>
//                   <p>Exceptional Team Of Developers</p>
//                   <div class="arrow"></div>
//                 </label>
//                 <div class="accordion-body">
//                   Our seasoned PHP developers have extensive experience in
//                   creating multi-user, high-performance, secure, and
//                   high-quality websites and web apps. Our team possesses strong
//                   technical abilities and are well-versed in the latest
//                   technological trends and advancements.
//                 </div>
//               </div>
//               <div class="accordion">
//                 <input
//                   type="checkbox"
//                   id="accordion-item-5"
//                   class="accordion-checkbox"
//                 />
//                 <label for="accordion-item-5" class="accordion-header">
//                   <span>05</span>
//                   <p>On-Time Project Delivery</p>
//                   <div class="arrow"></div>
//                 </label>
//                 <div class="accordion-body">
//                   We adopt an efficient approach that allows us to deliver
//                   projects on schedule. We not only meet deadlines but also
//                   ensure that our PHP web solutions exceed our client's
//                   expectations, providing them with an exceptional experience.
//                 </div>
//               </div>
//               <div class="accordion">
//                 <input
//                   type="checkbox"
//                   id="accordion-item-6"
//                   class="accordion-checkbox"
//                 />
//                 <label for="accordion-item-6" class="accordion-header">
//                   <span>06</span>
//                   <p>Robust Security Measures</p>
//                   <div class="arrow"></div>
//                 </label>
//                 <div class="accordion-body">
//                   Our developers implement proven web security techniques and
//                   tools to create highly secure PHP websites and applications.
//                   We take all necessary measures to safeguard your data,
//                   including signing a Non-Disclosure Agreement (NDA).
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//       {/* Customized PHP Solutions To Suit Your Needs */}
//       <div className="container php">
//         <h2>Customized PHP Solutions To Suit Your Needs</h2>
//         <div className="row androidrow1">
//           <div className="col-md-4">
//             <div className="phpinfo">
//               <div className="phpsubinfo1">
//                 <h2>Custom PHP Apps </h2>
//                 <p>
//                   Our team utilizes their extensive knowledge of object-oriented
//                   programming, MVC, and core PHP to create and deliver a range
//                   of custom PHP web applications. Our PHP development company
//                   employs the most appropriate PHP frameworks, such as
//                   CodeIgniter, CakePHP, Laravel, Zend, Yii, and Symfony, to
//                   develop enterprise-grade applications
//                 </p>
//               </div>
//             </div>
//           </div>
//           <div className="col-md-4">
//             <div className="phpinfo">
//               <div className="phpsubinfo1">
//                 <h2>Custom eCommerce Solutions with PHP : </h2>
//                 <p>
//                   Our team has the expertise to combine the right set of
//                   technologies to build top-of-the-line eCommerce solutions that
//                   include the latest features and functionality. We have the
//                   ability to create highly personalized eCommerce platforms.
//                 </p>{" "}
//               </div>
//             </div>
//           </div>
//           <div className="col-md-4">
//             <div className="phpinfo">
//               <div className="phpsubinfo1">
//                 <h2>Customized CMS Website Development </h2>
//                 <p>
//                   Our team has extensive experience in developing open-source
//                   CMS such as WordPress, Drupal, Joomla, Magento, etc. We create
//                   highly tailored websites by utilizing the full capabilities of
//                   these CMS platforms. Our PHP developers are skilled in
//                   leveraging the power of these systems to build successful
//                   websites
//                 </p>
//               </div>
//             </div>
//           </div>
//           <div className="col-md-4">
//             <div className="phpinfo">
//               <div className="phpsubinfo1">
//                 <h2>Expert Social Networking Development :</h2>
//                 <p>
//                   Our team utilizes the latest trends in PHP to create highly
//                   engaging, visually appealing, and interactive social
//                   networking websites. Our developers also incorporate
//                   top-of-the-line features into these sites.
//                 </p>
//               </div>
//             </div>
//           </div>
//           <div className="col-md-4">
//             <div className="phpinfo">
//               <div className="phpsubinfo1">
//                 <h2>API Integration Services : </h2>
//                 <p>
//                   Our team can seamlessly integrate your public, private, or
//                   internal APIs into your PHP system and CMS. We can also
//                   incorporate third-party APIs into your PHP websites as per
//                   your requirements by utilizing the various interfaces provided
//                   by these APIs.
//                 </p>
//               </div>
//             </div>
//           </div>
//           <div className="col-md-4">
//             <div className="phpinfo">
//               <div className="phpsubinfo1">
//                 <h2>Customized Business Web Portals :</h2>
//                 <p>
//                   Our developers have extensive experience in developing
//                   industry-specific web portals using PHP, including B2C, B2B,
//                   enterprise, and corporate portals. As a trusted website
//                   development company, we are committed to meeting the unique
//                   needs of our clients.
//                 </p>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//       {/* php stack */}
//       <div className="container-fluid seo">
//       <div className="container android">
//         <h2>Our PHP Stack</h2>
//         <div className="row rowphp">
//           <div className="col-md-4">
//             <Card className="cardphp">
//               <Card.Img variant="top" src={backendlogo} className="mt-1" />
//               <Card.Body>
//                 <Card.Title>Backend</Card.Title>
//                 <Card.Text>Laravel, Yii2, Zend, slim, Symfony</Card.Text>
//               </Card.Body>
//             </Card>
//           </div>
//           <div className="col-md-4">
//             <Card className="cardphp">
//               <Card.Img variant="top" src={frontendlogo} className="mt-1" />
//               <Card.Body>
//                 <Card.Title>Backend</Card.Title>
//                 <Card.Text>Vanilla, VueJS, jQuery</Card.Text>
//               </Card.Body>
//             </Card>
//           </div>
//           <div className="col-md-4">
//             <Card className="cardphp">
//               <Card.Img variant="top" src={database} className="mt-1" />
//               <Card.Body>
//                 <Card.Title>Data Management</Card.Title>
//                 <Card.Text>MySQL, MariaDB,PostgreSQL,MongoDB</Card.Text>
//               </Card.Body>
//             </Card>
//           </div>
//           <div className="col-md-4">
//             <Card className="cardphp">
//               <Card.Img variant="top" src={helper} className="mt-1" />
//               <Card.Body>
//                 <Card.Title> Optimization Helper</Card.Title>
//                 <Card.Text>Elasticsearch, RabbitMQ</Card.Text>
//               </Card.Body>
//             </Card>
//           </div>
//           <div className="col-md-4">
//             <Card className="cardphp">
//               <Card.Img variant="top" src={testing} className="mt-1" />
//               <Card.Body>
//                 <Card.Title>HTTP Server</Card.Title>
//                 <Card.Text>Apache,Nginx</Card.Text>
//               </Card.Body>
//             </Card>
//           </div>
//           <div className="col-md-4">
//             <Card className="cardphp">
//               <Card.Img variant="top" src={os} className="mt-1" />
//               <Card.Body>
//                 <Card.Title>OS And Platforms</Card.Title>
//                 <Card.Text>Ubantu,Centose,Docker,</Card.Text>
//               </Card.Body>
//             </Card>
//           </div>
//         </div>
//       </div>
//       </div>
//       <div className="container reactcontainer">
//         <h2>Our Process</h2>
//         <img src={processdevimg} className="reactimg" />
//       </div>
//     </>
//   );
// };

// export default PHP;
import React, { useState } from "react";
import php from "./Images/php.png";
import { Image } from "react-bootstrap";
import logo11 from "./Images/arrow_outward.png";
import phpimg from "./Images/whyphp1.png";
import helper from "./Images/helper.png";
import Card from "react-bootstrap/Card";
import processdevimg from "./Images/ProcessToDevelopPHP.png";
import testing from "./Images/testing.png";
import os from "./Images/os.png";
import backendlogo from "./Images/javafram1.png";
import frontendlogo from "./Images/frontend.png";
import database from "./Images/database.png";
const PHP = () => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <>
      {/* php */}
      <div className="container native1">
        <div className="row nativerow">
          <div className="col-md-4">
            <img src={php} width={300} alt="PHP Logo" />
          </div>
          <div className="col-md-8">
            <h2>PHP Development</h2>
          </div>
        </div>
      </div>
      {/* php in bestowal */}
      <div className="container-fluid nativecolor">
        <div className="container native">
          <h2>Offshore PHP Development With Bestowal Systems & Services</h2>
          <p>
            We specialize in creating dynamic, user-friendly websites using the
            PHP programming language. With a team of experienced developers, we
            bring your ideas to life and deliver high-quality solutions tailored
            to your specific needs. PHP, a widely-used, open-source programming
            language, is particularly well-suited for web development and
            enables the creation of interactive, data-driven websites, making it
            a popular choice for businesses of all sizes. Our PHP web
            development services include custom website development, e-commerce
            website development, content management systems (CMS), website
            redesign and maintenance, API development, and more. We stay
            up-to-date with the latest trends and technologies in PHP web
            development, utilizing popular frameworks such as Laravel,
            CodeIgniter, and Yii, and adhere to industry best practices to
            ensure your website is secure, reliable, and scalable.PHP is a
            cost-effective solution
            {!isExpanded ? (
              <span className="dots">...</span>
            ) : (
              <span className="more">
                for web development, saving you money both during the
                development process and in ongoing maintenance. Being
                open-source, PHP incurs no additional costs for downloading or
                licensing, and hosting expenses are typically lower.
                Furthermore, the ease of entry for PHP developers increases
                market competition, which helps to drive down prices.
              </span>
            )}
          </p>
          <button className="sapbtn" onClick={toggleExpand}>
            {isExpanded ? "Read Less" : "Read More"}
            <Image src={logo11} className="img0" alt="Arrow Icon" />
          </button>
        </div>
      </div>
      {/* why php */}
      <div className="container about">
        <div className="row aboutusrow">
          <div className="col-md-5">
            <img src={phpimg} alt="Why PHP Image" />
          </div>
          <div className="col-md-7">
            <h2>What Justifies PHP Development?</h2>
            <p>
              Because of its outstanding interoperability with other
              technologies, dynamic and collaborative inviting design, support
              for all major browsers, and database connectivity, PHP is the most
              widely used programming language. It enables programmers to design
              PHP web applications that are interesting while spending little
              money or time on them. Through PHP Web Development Services, it
              creates web applications in PHP, which adds dynamism and agility.
              "PHP is a cost-effective solution for web development, as it can
              save you money both during the development process and for ongoing
              maintenance. Being open-source, there are no additional costs for
              downloading or licensing, and hosting expenses are typically
              lower. Additionally, the ease of entry for PHP developers leads to
              increased competition in the market, driving down prices. Today,
              companies are trying to improve the user experience by fusing
              highly functional and feature-rich components. One of the
              quickest, most powerful, most dynamic frameworks for web
              development is PHP.
            </p>
          </div>
        </div>
      </div>
      {/* custom php service */}
      <div className="container-fluid seo">
        <div className="container android">
          <h2>Why We Take Pride in Our Custom PHP Development Services</h2>
          <div className="container accordion">
            <div className="accordion-container">
              <div className="accordion">
                <input
                  type="checkbox"
                  id="accordion-item-1"
                  className="accordion-checkbox"
                />
                <label htmlFor="accordion-item-1" className="accordion-header">
                  <span>01</span>
                  <p>Expertise In Agile Methodology</p>
                  <div className="arrow"></div>
                </label>
                <div className="accordion-body">
                  <ul class="custom-list">
                    <li>
                      Our team of skilled PHP professionals has been trained in
                      various agile methodologies and has successfully applied
                      them in our projects. We utilize an agile development
                      approach that provides a dynamic environment for PHP
                      development, encourages creativity, and ensures excellence
                      in both long-term and short-term projects. Agile
                      methodologies allow us to adapt to changing project
                      requirements and client needs quickly and efficiently,
                      enabling us to respond to new information and evolving
                      priorities without compromising on quality or timelines.
                    </li>
                    <li>
                      Enhanced collaboration is a cornerstone of our agile
                      approach. Regular communication and collaboration between
                      team members and stakeholders ensure that everyone is on
                      the same page and working towards the same goals. This
                      collaborative environment fosters innovation and helps us
                      address challenges proactively. By breaking down projects
                      into manageable sprints, we ensure that deliverables are
                      completed on time and within budget, leading to early and
                      predictable delivery.
                    </li>
                  </ul>
                </div>
              </div>

              <div className="accordion">
                <input
                  type="checkbox"
                  id="accordion-item-2"
                  className="accordion-checkbox"
                />
                <label htmlFor="accordion-item-2" className="accordion-header">
                  <span>02</span>
                  <p>Decades Of Experience</p>
                  <div className="arrow"></div>
                </label>
                <div className="accordion-body">
                  <ul class="custom-list">
                    <li>
                      We have been delivering high-quality PHP web applications
                      for over a decade. Our certified developers possess a deep
                      understanding of PHP, enabling them to tackle even the
                      most complex projects with confidence and precision. Their
                      extensive experience allows them to implement best
                      practices and innovative solutions that ensure robust
                      performance, scalability, and security.
                    </li>
                    <li>
                      Our long-standing expertise in PHP development has
                      equipped us with the skills needed to meet diverse client
                      needs across various industries. By consistently
                      maintaining high standards, we ensure that every project
                      we undertake is delivered on time, within budget, and to
                      the highest level of client satisfaction.
                    </li>
                  </ul>
                </div>
              </div>
              <div className="accordion">
                <input
                  type="checkbox"
                  id="accordion-item-3"
                  className="accordion-checkbox"
                />
                <label htmlFor="accordion-item-3" className="accordion-header">
                  <span>03</span>
                  <p>Flexible Service Models</p>
                  <div className="arrow"></div>
                </label>
                <div className="accordion-body">
                  <ul class="custom-list">
                    <li>
                      We offer a range of engagement models to accommodate the
                      diverse needs of businesses for PHP development. Our
                      experienced developers work with the best development
                      practices and adhere to all guidelines for quality
                      assurance.
                    </li>
                    <li>
                      Our flexible service models allow us to adapt to your
                      specific project requirements, whether you need a
                      dedicated development team or support on a
                      project-by-project basis. This ensures that you receive
                      the right level of service and expertise for your unique
                      needs.
                    </li>
                    <li>
                      Additionally, our commitment to transparency and
                      communication means that you will always be informed about
                      the progress of your project. We strive to build strong,
                      collaborative relationships with our clients to deliver
                      solutions that truly meet their business objectives.
                    </li>
                  </ul>
                </div>
              </div>
              <div className="accordion">
                <input
                  type="checkbox"
                  id="accordion-item-4"
                  className="accordion-checkbox"
                />
                <label htmlFor="accordion-item-4" className="accordion-header">
                  <span>04</span>
                  <p>Exceptional Team Of Developers</p>
                  <div className="arrow"></div>
                </label>
                <div className="accordion-body">
                  <ul class="custom-list">
                    <li>
                      Our seasoned PHP developers have extensive experience in
                      creating multi-user, high-performance, secure, and
                      high-quality websites and web apps. Our team possesses
                      strong technical abilities and is well-versed in the
                      latest technological trends and advancements.
                    </li>
                    <li>
                      Each member of our team is dedicated to delivering
                      top-notch solutions tailored to meet the unique needs of
                      our clients. We prioritize continuous learning and skill
                      enhancement to stay ahead in the rapidly evolving tech
                      landscape. This commitment to excellence ensures that our
                      clients receive cutting-edge solutions that drive their
                      business growth.
                    </li>
                    <li>
                      In addition to technical expertise, our developers are
                      adept at problem-solving and critical thinking. They work
                      collaboratively with clients, understanding their business
                      requirements and translating them into effective digital
                      solutions. This approach ensures a seamless development
                      process and successful project outcomes.
                    </li>
                  </ul>
                </div>
              </div>
              <div className="accordion">
                <input
                  type="checkbox"
                  id="accordion-item-5"
                  className="accordion-checkbox"
                />
                <label htmlFor="accordion-item-5" className="accordion-header">
                  <span>05</span>
                  <p>On-Time Project Delivery</p>
                  <div className="arrow"></div>
                </label>
                <div className="accordion-body">
                  <ul class="custom-list">
                    <li>
                      We adopt an efficient approach that allows us to deliver
                      projects on schedule. We not only meet deadlines but also
                      ensure that our PHP web solutions exceed our client's
                      expectations, providing them with an exceptional
                      experience.
                    </li>
                    <li>
                      Our commitment to on-time project delivery underscores our
                      dedication to client satisfaction. By integrating agile
                      methodologies and rigorous project management practices,
                      we ensure that our PHP development solutions are delivered
                      promptly, meeting and exceeding client expectations with
                      every project.
                    </li>
                  </ul>
                </div>
              </div>
              <div className="accordion">
                <input
                  type="checkbox"
                  id="accordion-item-6"
                  className="accordion-checkbox"
                />
                <label htmlFor="accordion-item-6" className="accordion-header">
                  <span>06</span>
                  <p>Robust Security Measures</p>
                  <div className="arrow"></div>
                </label>
                <div className="accordion-body">
                <ul class="custom-list">
                    <li>
                    Our PHP developers prioritize security by implementing industry-proven techniques and tools to ensure the highest level of protection for your websites and applications. From secure coding practices to regular security audits and adherence to best practices, we safeguard your sensitive data and user information. Additionally, we are committed to confidentiality, offering Non-Disclosure Agreements (NDAs) to protect your proprietary information and intellectual property.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container android">
        <h2>Our PHP Stack</h2>
        <div className="row rowphp">
          <div className="col-md-4">
            <Card className="cardphp">
              <Card.Img variant="top" src={backendlogo} className="mt-1" />
              <Card.Body>
                <Card.Title>Backend</Card.Title>
                <Card.Text>Laravel, Yii2, Zend, slim, Symfony</Card.Text>
              </Card.Body>
            </Card>
          </div>
          <div className="col-md-4">
            <Card className="cardphp">
              <Card.Img variant="top" src={frontendlogo} className="mt-1" />
              <Card.Body>
                <Card.Title>Backend</Card.Title>
                <Card.Text>Vanilla, VueJS, jQuery</Card.Text>
              </Card.Body>
            </Card>
          </div>
          <div className="col-md-4">
            <Card className="cardphp">
              <Card.Img variant="top" src={database} className="mt-1" />
              <Card.Body>
                <Card.Title>Data Management</Card.Title>
                <Card.Text>MySQL, MariaDB,PostgreSQL,MongoDB</Card.Text>
              </Card.Body>
            </Card>
          </div>
          <div className="col-md-4">
            <Card className="cardphp">
              <Card.Img variant="top" src={helper} className="mt-1" />
              <Card.Body>
                <Card.Title> Optimization Helper</Card.Title>
                <Card.Text>Elasticsearch, RabbitMQ</Card.Text>
              </Card.Body>
            </Card>
          </div>
          <div className="col-md-4">
            <Card className="cardphp">
              <Card.Img variant="top" src={testing} className="mt-1" />
              <Card.Body>
                <Card.Title>HTTP Server</Card.Title>
                <Card.Text>Apache,Nginx</Card.Text>
              </Card.Body>
            </Card>
          </div>
          <div className="col-md-4">
            <Card className="cardphp">
              <Card.Img variant="top" src={os} className="mt-1" />
              <Card.Body>
                <Card.Title>OS And Platforms</Card.Title>
                <Card.Text>Ubantu,Centose,Docker,</Card.Text>
              </Card.Body>
            </Card>{" "}
          </div>
        </div>
      </div>
      <div className="container-fluid seo">
        <div className="container reactcontainer">
          <h2>Our Process</h2>
          <img src={processdevimg} className="phpimg" />
        </div>
      </div>
    </>
  );
};

export default PHP;
