import React from "react";
import "./Modal.css";

const Modal = () => {
  return (
      <div className="container modal-content1">
        <div className="modal-header">
          <h2>Job Application Form</h2>
          {/* <button className="close-button">
            &times;
          </button> */}
        </div>
        <div className="modal-body">
          <form>
          <label htmlFor="job">
          Position Applied For:
              <select name="job" id="job" className="selectOption">
                <option value="SAP ABAP Technical">SAP ABAP Technical</option>
                <option value="SAP FI for GFDP">SAP FI for GFDP</option>
                <option value="Automation Tester">Automation Tester</option>
                <option value="Manual Testing Consultant">
                  Manual Testing Consultant
                </option>
                <option value="SAP ABAP DEVELOPER(EWM)">
                  SAP ABAP DEVELOPER(EWM)
                </option>
                <option value="Test Lead">Test Lead</option>
                <option value="SAP ABAP Workflow">SAP ABAP Workflow</option>
                <option value="SAP ISU(Utilities) Module">
                  SAP ISU(Utilities) Module
                </option>
                <option value="Full Stack Developer">
                  Full Stack Developer
                </option>
                <option value="Selenium Java Testing Engineer">
                  Selenium Java Testing Engineer
                </option>
                <option value="SAP Basis(Level3 Expert)">
                  SAP Basis(Level3 Expert)
                </option>
                <option value="Senior Informatica">Senior Informatica</option>
                <option value="SAP ABAP Consultant">SAP ABAP Consultant</option>
                <option value="SAP BTP Consultant">SAP BTP Consultant</option>
                <option value="Java Developer">Java Developer</option>
                <option value="SAP BTP Consultant">SAP BTP Consultant</option>
                <option value="NodeJS Developer">NodeJS Developer</option>
                <option value="React Native Developer">
                  React Native Developer
                </option>
                <option value="HR Recruitment">HR Recruitment</option>
                <option value="ReactJS Developer">ReactJS Developer</option>
              </select>
            </label>
            <label>
              Full Name:
              <input type="text" name="fullName" required />
            </label>
            <label>
              Email:
              <input type="email" name="Email" required />
            </label>
            <label>
              Mobile Number:
              <input type="tel" name="mobileNumber" required />
            </label>
            <label>
              Cover Letter:
              <textarea name="address" rows="4" required></textarea>
            </label>

            <label>
              Resume:
              <input type="file" name="resume" required />
            </label>
            <button type="submit" className="s-btn">
              Submit
            </button>
          </form>
        </div>
      </div>

  );
};

export default Modal;
