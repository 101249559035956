import React from 'react'
import Contact from "./Images/Carrer.jpg";
import { Link } from 'react-router-dom';
const CareerLanding = () => {
  return (
    <>
      <div className='containerfluid careerlanding'>
        <h2>Career @ Bestowal</h2>
      </div>
      <div class="container career">
        <div class="search-section  text-white">
          <div class="row careerrow">
            <div class="col-md-5">
              {/* <p>Search By Keyword</p> */}
              <input
                type="text"
                className="form-control"
                placeholder="Enter Job Title"
              />
            </div>
            <div class="col-md-5">
              {/* <p>Search By Locations</p> */}
              <input
                type="text"
                className="form-control"
                placeholder="Locations"
              />
            </div>

            <div class="col-md-2">
              {/* <p>Search</p> */}<Link className='nav-link' to="/careers">
              <button className="careerbutton">Search Jobs</button></Link>
            </div>
          </div>
        </div>
      </div>
      <div className='container aboutcarrier'>
        <div className='carrierrow'>
          <div className='col-md-6'>
          <img src={Contact} alt="Contact" />
          </div>
          <div className="col-md-6">
            <h2>Great Opportunity for Your Inspiring Talent</h2>
            <p>
              Looking to take your career to the next level? Bestowal is
              offering a fantastic opportunity to work with global brands and
              accelerate your career path. If you are a passionate learner with
              solid coding skills and an interest in the latest technologies,
              you'll find a welcoming home at Bestowal.<br /> At Bestowal, we pride
              ourselves on providing an excellent learning environment for our
              consultants, with ample opportunities to continuously update their
              skills and knowledge on the newest and most advanced technologies.
              Our team is dedicated to helping you grow and achieve your full
              potential, both personally and professionally.<br /> With Bestowal,
              you'll be able to work on exciting projects that will challenge
              and inspire you. You'll collaborate with talented and experienced
              professionals who share your passion for technology. Whether
              you're a seasoned expert or just starting out in your career, we
              believe that Bestowal is the perfect place to realize your
              ambitions and achieve your goals.
            </p>
          </div>
        </div>
      </div>
    </>
  )
}

export default CareerLanding
