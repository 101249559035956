// import React from "react";
// import { useState } from "react";
// import SAS from "./Images/SAS.jpg";
// const Blogs = () => {
//   const [visiblePosts, setVisiblePosts] = useState(6); // Display 6 initially

//   // Blog data (can be fetched dynamically instead of hard-coded)
//   const blogPosts = [
//     { id: 1, badge: 'Latest', title: 'SAP Business Technology Platform', date: 'Sep 13 2024', author: 'Rohit Patil', description: 'We work closely with our customers to help them understand the right combination of solutions.' },
//     { id: 2, badge: 'SAP', title: 'SAP Business Technology Platform', date: 'Sep 13 2024', author: 'Rohit Patil', description: 'We work closely with our customers to help them understand the right combination of solutions.' },
//     { id: 3, badge: 'SAP IBP', title: 'SAP Business Technology Platform', date: 'Sep 13 2024', author: 'Rohit Patil', description: 'We work closely with our customers to help them understand the right combination of solutions.' },
//     { id: 4, badge: 'Latest', title: 'SAP Business Technology Platform', date: 'Sep 13 2024', author: 'Rohit Patil', description: 'We work closely with our customers to help them understand the right combination of solutions.' },
//     { id: 5, badge: 'SAP', title: 'SAP Business Technology Platform', date: 'Sep 13 2024', author: 'Rohit Patil', description: 'We work closely with our customers to help them understand the right combination of solutions.' },
//     { id: 6, badge: 'SAP IBP', title: 'SAP Business Technology Platform', date: 'Sep 13 2024', author: 'Rohit Patil', description: 'We work closely with our customers to help them understand the right combination of solutions.' },
//     { id: 7, badge: 'Latest', title: 'SAP Business Technology Platform', date: 'Sep 13 2024', author: 'Rohit Patil', description: 'We work closely with our customers to help them understand the right combination of solutions.' },
//     { id: 8, badge: 'SAP', title: 'SAP Business Technology Platform', date: 'Sep 13 2024', author: 'Rohit Patil', description: 'We work closely with our customers to help them understand the right combination of solutions.' },
//     { id: 9, badge: 'SAP IBP', title: 'SAP Business Technology Platform', date: 'Sep 13 2024', author: 'Rohit Patil', description: 'We work closely with our customers to help them understand the right combination of solutions.' },
//     { id: 10, badge: 'SAP IBP', title: 'SAP Business Technology Platform', date: 'Sep 13 2024', author: 'Rohit Patil', description: 'We work closely with our customers to help them understand the right combination of solutions.' },
//     { id: 11, badge: 'SAP IBP', title: 'SAP Business Technology Platform', date: 'Sep 13 2024', author: 'Rohit Patil', description: 'We work closely with our customers to help them understand the right combination of solutions.' },
//     { id: 12, badge: 'SAP IBP', title: 'SAP Business Technology Platform', date: 'Sep 13 2024', author: 'Rohit Patil', description: 'We work closely with our customers to help them understand the right combination of solutions.' }

//   ];

//   // Function to handle "Read More" click
//   const handleReadMore = () => {
//     setVisiblePosts((prevCount) => prevCount + 3); // Load 3 more posts on each click
//   };
//   return (
//     <>
//       <div className="container-fluid blogs">
//         <h1>Blogs</h1>
//       </div>
//       {/* <div class="container blogcontainer">
//         <div class="header">
//           <div class="dropdown">
//             <button class="category-button">
//               Explore By Categories <i class="fas fa-chevron-down"></i>
//             </button>
//             <div class="dropdown-content">
//               <a href="#">SAP Blogs</a>
//               <a href="#">Web Blogs</a>
//               <a href="#">IBP Blogs</a>
//             </div>
//           </div>
//         </div>
//         <div className="row">
//           <div className="col-md-4">
//               <div class="card">
//                 <div class="card-img">
//                   <img src={SAS} alt="SAP Business Technology Platform" />
//                   <span class="badge latest">Latest</span>
//                 </div>
//                 <div class="card-body">
//                   <p class="date">Sep 13 2024</p>
//                   <h3 class="card-title">SAP Business Technology Platform</h3>
//                   <p class="description">
//                     We work closely with our customers to help them understand
//                     the right combination of solutions.
//                   </p>
//                   <hr/>
//                   <p class="author">By Rohit Patil</p>
//                 </div>
//               </div>
//               </div>
//               <div className="col-md-4">
//               <div class="card">
//                 <div class="card-img">
//                   <img src={SAS} alt="SAP Business Technology Platform" />
//                   <span class="badge">SAP</span>
//                 </div>
//                 <div class="card-body">
//                   <p class="date">Sep 13 2024</p>
//                   <h3 class="card-title">SAP Business Technology Platform</h3>
//                   <p class="description">
//                     We work closely with our customers to help them understand
//                     the right combination of solutions.
//                   </p>
//                   <hr/>
//                   <p class="author">By Rohit Patil</p>
//                 </div>
//               </div>
//               </div>
//               <div className="col-md-4">
//               <div class="card">
//                 <div class="card-img">
//                   <img src={SAS} alt="SAP Business Technology Platform" />
//                   <span class="badge">SAP IBP</span>
//                 </div>
//                 <div class="card-body">
//                   <p class="date">Sep 13 2024</p>
//                   <h3 class="card-title">SAP Business Technology Platform</h3>
//                   <p class="description">
//                     We work closely with our customers to help them understand
//                     the right combination of solutions.
//                   </p>
//                   <hr/>
//                   <p class="author">By Rohit Patil</p>
//                 </div>
//               </div>
//               </div>
//               <div className="col-md-4">
//               <div class="card">
//                 <div class="card-img">
//                   <img src={SAS} alt="SAP Business Technology Platform" />
//                   <span class="badge latest">Latest</span>
//                 </div>
//                 <div class="card-body">
//                   <p class="date">Sep 13 2024</p>
//                   <h3 class="card-title">SAP Business Technology Platform</h3>
//                   <p class="description">
//                     We work closely with our customers to help them understand
//                     the right combination of solutions.
//                   </p>
//                   <hr/>
//                   <p class="author">By Rohit Patil</p>
//                 </div>
//               </div>
//               </div>
//               <div className="col-md-4">
//               <div class="card">
//                 <div class="card-img">
//                   <img src={SAS} alt="SAP Business Technology Platform" />
//                   <span class="badge">SAP</span>
//                 </div>
//                 <div class="card-body">
//                   <p class="date">Sep 13 2024</p>
//                   <h3 class="card-title">SAP Business Technology Platform</h3>
//                   <p class="description">
//                     We work closely with our customers to help them understand
//                     the right combination of solutions.
//                   </p>
//                   <hr/>
//                   <p class="author">By Rohit Patil</p>
//                 </div>
//               </div>
//               </div>
//               <div className="col-md-4">
//               <div class="card">
//                 <div class="card-img">
//                   <img src={SAS} alt="SAP Business Technology Platform" />
//                   <span class="badge">SAP IBP</span>
//                 </div>
//                 <div class="card-body">
//                   <p class="date">Sep 13 2024</p>
//                   <h3 class="card-title">SAP Business Technology Platform</h3>
//                   <p class="description">
//                     We work closely with our customers to help them understand
//                     the right combination of solutions.
//                   </p>
//                   <hr/>
//                   <p class="author">By Rohit Patil</p>
//                 </div>
//               </div>
//               </div>
//               <div className="col-md-4">
//               <div class="card">
//                 <div class="card-img">
//                   <img src={SAS} alt="SAP Business Technology Platform" />
//                   <span class="badge latest">Latest</span>
//                 </div>
//                 <div class="card-body">
//                   <p class="date">Sep 13 2024</p>
//                   <h3 class="card-title">SAP Business Technology Platform</h3>
//                   <p class="description">
//                     We work closely with our customers to help them understand
//                     the right combination of solutions.
//                   </p>
//                   <hr/>
//                   <p class="author">By Rohit Patil</p>
//                 </div>
//               </div>
//               </div>
//               <div className="col-md-4">
//               <div class="card">
//                 <div class="card-img">
//                   <img src={SAS} alt="SAP Business Technology Platform" />
//                   <span class="badge">SAP</span>
//                 </div>
//                 <div class="card-body">
//                   <p class="date">Sep 13 2024</p>
//                   <h3 class="card-title">SAP Business Technology Platform</h3>
//                   <p class="description">
//                     We work closely with our customers to help them understand
//                     the right combination of solutions.
//                   </p>
//                   <hr/>
//                   <p class="author">By Rohit Patil</p>
//                 </div>
//               </div>
//               </div>
//               <div className="col-md-4">
//               <div class="card">
//                 <div class="card-img">
//                   <img src={SAS} alt="SAP Business Technology Platform" />
//                   <span class="badge">SAP IBP</span>
//                 </div>
//                 <div class="card-body">
//                   <p class="date">Sep 13 2024</p>
//                   <h3 class="card-title">SAP Business Technology Platform</h3>
//                   <p class="description">
//                     We work closely with our customers to help them understand
//                     the right combination of solutions.
//                   </p>
//                   <hr/>
//                   <p class="author">By Rohit Patil</p>
//                 </div>
//               </div>
//               </div>
//         </div>
//         <button>Read More</button>
//       </div> */}
//        <div className="container blogcontainer">
//       <div className="header">
//         <div className="dropdown">
//           <button className="category-button">
//             Explore By Categories <i className="fas fa-chevron-down"></i>
//           </button>
//           <div className="dropdown-content">
//             <a href="#">SAP Blogs</a>
//             <a href="#">Web Blogs</a>
//             <a href="#">IBP Blogs</a>
//           </div>
//         </div>
//       </div>

//       <div className="row">
//         {blogPosts.slice(0, visiblePosts).map((post) => (
//           <div className="col-md-4" key={post.id}>
//             <div className="card">
//               <div className="card-img">
//                 <img src={SAS} alt={post.title} />
//                 <span className={`badge ${post.badge === 'Latest' ? 'latest' : ''}`}>{post.badge}</span>
//               </div>
//               <div className="card-body">
//                 <p className="date">{post.date}</p>
//                 <h3 className="card-title">{post.title}</h3>
//                 <p className="description">{post.description}</p>
//                 <hr />
//                 <p className="author">By {post.author}</p>
//               </div>
//             </div>
//           </div>
//         ))}
//       </div>

//       {visiblePosts < blogPosts.length && (
//         <button className="read-more" onClick={handleReadMore}>
//           Read More
//         </button>
//       )}
//     </div>
//     </>
//   );
// };

// export default Blogs;
// import React from "react";
// import { useState } from "react";
// import SAS from "./Images/SAS.jpg";
// import { Link } from 'react-router-dom'; 
// import { useNavigate } from 'react-router-dom';
// const Blogs = () => {
//   const [visiblePosts, setVisiblePosts] = useState(6); 
//   const [isExpanded, setIsExpanded] = useState(false); 
//   const navigate = useNavigate();

//   const blogPosts = [
//     {
//       id: 1,
//       badge: "Latest",
//       title: "SAP Business Technology Platform",
//       date: "Sep 13 2024",
//       author: "Rohit Patil",
//       description:
//         "We work closely with our customers to help them understand the right combination of solutions.",
//     },
//     {
//       id: 2,
//       badge: "SAP",
//       title: "SAP Business Technology Platform",
//       date: "Sep 13 2024",
//       author: "Rohit Patil",
//       description:
//         "We work closely with our customers to help them understand the right combination of solutions.",
//     },
//     {
//       id: 3,
//       badge: "SAP IBP",
//       title: "SAP Business Technology Platform",
//       date: "Sep 13 2024",
//       author: "Rohit Patil",
//       description:
//         "We work closely with our customers to help them understand the right combination of solutions.",
//     },
//     {
//       id: 4,
//       badge: "Latest",
//       title: "SAP Business Technology Platform",
//       date: "Sep 13 2024",
//       author: "Rohit Patil",
//       description:
//         "We work closely with our customers to help them understand the right combination of solutions.",
//     },
//     {
//       id: 5,
//       badge: "SAP",
//       title: "SAP Business Technology Platform",
//       date: "Sep 13 2024",
//       author: "Rohit Patil",
//       description:
//         "We work closely with our customers to help them understand the right combination of solutions.",
//     },
//     {
//       id: 6,
//       badge: "SAP IBP",
//       title: "SAP Business Technology Platform",
//       date: "Sep 13 2024",
//       author: "Rohit Patil",
//       description:
//         "We work closely with our customers to help them understand the right combination of solutions.",
//     },
//     {
//       id: 7,
//       badge: "Latest",
//       title: "SAP Business Technology Platform",
//       date: "Sep 13 2024",
//       author: "Rohit Patil",
//       description:
//         "We work closely with our customers to help them understand the right combination of solutions.",
//     },
//     {
//       id: 8,
//       badge: "SAP",
//       title: "SAP Business Technology Platform",
//       date: "Sep 13 2024",
//       author: "Rohit Patil",
//       description:
//         "We work closely with our customers to help them understand the right combination of solutions.",
//     },
//     {
//       id: 9,
//       badge: "SAP IBP",
//       title: "SAP Business Technology Platform",
//       date: "Sep 13 2024",
//       author: "Rohit Patil",
//       description:
//         "We work closely with our customers to help them understand the right combination of solutions.",
//     },
//     {
//       id: 10,
//       badge: "SAP IBP",
//       title: "SAP Business Technology Platform",
//       date: "Sep 13 2024",
//       author: "Rohit Patil",
//       description:
//         "We work closely with our customers to help them understand the right combination of solutions.",
//     },
//     {
//       id: 11,
//       badge: "SAP IBP",
//       title: "SAP Business Technology Platform",
//       date: "Sep 13 2024",
//       author: "Rohit Patil",
//       description:
//         "We work closely with our customers to help them understand the right combination of solutions.",
//     },
//     {
//       id: 12,
//       badge: "SAP IBP",
//       title: "SAP Business Technology Platform",
//       date: "Sep 13 2024",
//       author: "Rohit Patil",
//       description:
//         "We work closely with our customers to help them understand the right combination of solutions.",
//     },
//   ];

  
//   const handleReadMoreLess = () => {
//     if (isExpanded) {
//       setVisiblePosts(6); 
//     } else {
//       setVisiblePosts(blogPosts.length); 
//     }
//     setIsExpanded(!isExpanded); 
//   };

//   return (
//     <>
//        <div className="container-fluid blogs">
//         <h1>Blogs</h1>
//       </div>

//       <div className="container blogcontainer">
//       <div class="header">
//            <div class="dropdown">
//              <button class="category-button">
//               Explore By Categories <i class="fas fa-chevron-down"></i>
//              </button>
//              <div class="dropdown-content">
//                <a href="#">All</a>
//                <a href="#">SAP IBP</a>
//                <a href="#">SAP APO</a>
//                <a href="#">SAP CI-DS</a>
//              </div>
//            </div>
//          </div>
//          <div className="row">
        
//         <div className="col-md-4 mb-4">
//           <Link to="/blogdetails" className="card-link">
//             <div className="card">
//               <div className="card-img">
//                 <img src={SAS} alt="Blog Image" className="img-fluid" />
//                 <span className="badge badge-primary">IBP</span>
//               </div>
//               <div className="card-body">
//                 <p className="date">Sep 13 2024</p>
//                 <h3 className="card-title">Period Weight Factor</h3>
//                 <p className="description">
//                 The disaggregation process adheres to the standard principles of proportional disaggregation, with the exception that the proportionality factors are determined by the attribute designated as the period weight factor.
//                 </p>
//                 <hr />
//                 <p className="author">By Siddamma Gudda</p>
//               </div>
//             </div>
//           </Link>
//         </div>
//         <div className="col-md-4 mb-4">
//           <Link to="/blogdetails1" className="card-link">
//             <div className="card">
//               <div className="card-img">
//                 <img src={SAS} alt="Blog Image" className="img-fluid" />
//                 <span className="badge badge-primary">IBP</span>
//               </div>
//               <div className="card-body">
//                 <p className="date">March 6th 2024</p>
//                 <h3 className="card-title">ABC / XYZ Segmentation</h3>
//                 <p className="description">
//                 How the Segmentation varies as compared to K means and other algorithms  ABC/XYZ segmentation is the method of grouping planning objects based on the values of a selected key figure.
//                 </p>
//                 <hr />
//                 <p className="author">By BCS</p>
//               </div>
//             </div>
//           </Link>
//         </div>

//         <div className="col-md-4 mb-4">
//           <Link to="/blogdetails3" className="card-link">
//             <div className="card">
//               <div className="card-img">
//                 <img src={SAS} alt="Blog Image" className="img-fluid" />
//                 <span className="badge badge-primary">IBP</span>
//               </div>
//               <div className="card-body">
//                 <p className="date">March 6th 2024</p>
//                 <h3 className="card-title"> Power of Scenarios in IBP</h3>
//                 <p className="description">
//                 In the dynamic and ever-evolving business landscape, organizations constantly grapple with a multitude of uncertainties, ranging from fluctuating market trends to sudden shifts in customer demand
//                 </p>
//                 <hr />
//                 <p className="author">By Aniket Sathe</p>
//               </div>
//             </div>
//           </Link>
//         </div>
//         <div className="col-md-4 mb-4">
//           <Link to="/blogdetails4" className="card-link">
//             <div className="card">
//               <div className="card-img">
//                 <img src={SAS} alt="Blog Image" className="img-fluid" />
//                 <span className="badge badge-primary">IBP</span>
//               </div>
//               <div className="card-body">
//                 <p className="date">March 6th 2024</p>
//                 <h3 className="card-title">Custom Aletrs In SAP IBP</h3>
//                 <p className="description">
//                 Supply chain problems that are significant or critical, including inventory shortages, supply and demand imbalances, or unanticipated changes in the supply chain, are detected by custom alerts.
//                 </p>
//                 <hr />
//                 <p className="author">By Priya Thakre</p>
//               </div>
//             </div>
//           </Link>
//         </div>
//         <div className="col-md-4 mb-4">
//           <Link to="/blogdetails5" className="card-link">
//             <div className="card">
//               <div className="card-img">
//                 <img src={SAS} alt="Blog Image" className="img-fluid" />
//                 <span className="badge badge-primary">IBP</span>
//               </div>
//               <div className="card-body">
//                 <p className="date">March 6th 2024</p>
//                 <h3 className="card-title"> Product Lifecycle Planning</h3>
//                 <p className="description">
//                 Historic data is typically unavailable while developing a new product, and due to a lack of sales history, forecasting is difficult. Therefore, you look for similar products whose historical sales data you can use. </p>
//                 <hr />
//                 <p className="author">By Priya Thakre</p>
//               </div>
//             </div>
//           </Link>
//         </div>
//         <div className="col-md-4 mb-4">
//           <Link to="/blogdetails6" className="card-link">
//             <div className="card">
//               <div className="card-img">
//                 <img src={SAS} alt="Blog Image" className="img-fluid" />
//                 <span className="badge badge-primary">APO</span>
//               </div>
//               <div className="card-body">
//                 <p className="date">April 11th 2024</p>
//                 <h3 className="card-title">Core Interface</h3>
//                 <p className="description">
//                 SAP APO, integrated with ERP systems like SAP R/3 or SAP ECC, optimizes supply chain processes through planning and optimization. The Core Interface bridges the source, target systems, facilitating the transfer of master  data. 
//                 </p>
//                 <hr />
//                 <p className="author">By Avadhut Adake</p>
//               </div>
//             </div>
//           </Link>
//         </div>
//         <div className="col-md-4 mb-4">
//           <Link to="/blogdetails7" className="card-link">
//             <div className="card">
//               <div className="card-img">
//                 <img src={SAS} alt="Blog Image" className="img-fluid" />
//                 <span className="badge badge-primary">APO</span>
//               </div>
//               <div className="card-body">
//                 <p className="date">April 16th 2024</p>
//                 <h3 className="card-title">SAP APO – HEURISTICS</h3>
//                 <p className="description">
//                 Heuristics are automated planning engines in SAP APO SNP that generate supply plans based on rules, not costs. They assume unlimited production capacity and use bucket-based planning.
//                 </p>
//                 <hr />
//                 <p className="author">By Avadhut Adake</p>
//               </div>
//             </div>
//           </Link>
//         </div>
//         <div className="col-md-4 mb-4">
//           <Link to="/blogdetails8" className="card-link">
//             <div className="card">
//               <div className="card-img">
//                 <img src={SAS} alt="Blog Image" className="img-fluid" />
//                 <span className="badge badge-primary">IBP</span>
//               </div>
//               <div className="card-body">
//                 <p className="date">March 6th 2024</p>
//                 <h3 className="card-title">SAP APO MASTER DATA</h3>
//                 <p className="description">
//                 Master data in SAP APO plays a crucial role, just like master data in SAP ECC. Accurate setup of master data, including customer, product, and plant information, is essential for conducting ATP checks in SAP APO.
//                 </p>
//                 <hr />
//                 <p className="author">By Chetali Jeur</p>
//               </div>
//             </div>
//           </Link>
//         </div>
//         <div className="col-md-4 mb-4">
//           <Link to="/blogdetails9" className="card-link">
//             <div className="card">
//               <div className="card-img">
//                 <img src={SAS} alt="Blog Image" className="img-fluid" />
//                 <span className="badge badge-primary">CI-DS</span>
//               </div>
//               <div className="card-body">
//                 <p className="date">April 19th 2024</p>
//                 <h3 className="card-title">Data Integration using  CI-DS</h3>
//                 <p className="description">
//                 The integration of SAP IBP with SAP CI-DS signifies a transformative shift in data management and connectivity, empowering organizations to enhance business planning and decision-making processes.                </p>
//                 <hr />
//                 <p className="author">By Vishal Patil</p>
//               </div>
//             </div>
//           </Link>
//         </div>

//         {isExpanded && (
//           <>
//          <div className="col-md-4 mb-4">
//           <Link to="/blogdetails" className="card-link">
//             <div className="card">
//               <div className="card-img">
//                 <img src={SAS} alt="Blog Image" className="img-fluid" />
//                 <span className="badge badge-primary">Latest</span>
//               </div>
//               <div className="card-body">
//                 <p className="date">Sep 13 2024</p>
//                 <h3 className="card-title">SAP Business Technology Platform</h3>
//                 <p className="description">
//                   We work closely with our customers to help them understand the right combination of solutions.
//                 </p>
//                 <hr />
//                 <p className="author">By Rohit Patil</p>
//               </div>
//             </div>
//           </Link>
//         </div>
//         <div className="col-md-4 mb-4">
//           <Link to="/blogdetails" className="card-link">
//             <div className="card">
//               <div className="card-img">
//                 <img src={SAS} alt="Blog Image" className="img-fluid" />
//                 <span className="badge badge-primary">Latest</span>
//               </div>
//               <div className="card-body">
//                 <p className="date">Sep 13 2024</p>
//                 <h3 className="card-title">SAP Business Technology Platform</h3>
//                 <p className="description">
//                   We work closely with our customers to help them understand the right combination of solutions.
//                 </p>
//                 <hr />
//                 <p className="author">By Rohit Patil</p>
//               </div>
//             </div>
//           </Link>
//         </div>
//         <div className="col-md-4 mb-4">
//           <Link to="/blogdetails" className="card-link">
//             <div className="card">
//               <div className="card-img">
//                 <img src={SAS} alt="Blog Image" className="img-fluid" />
//                 <span className="badge badge-primary">Latest</span>
//               </div>
//               <div className="card-body">
//                 <p className="date">Sep 13 2024</p>
//                 <h3 className="card-title">SAP Business Technology Platform</h3>
//                 <p className="description">
//                   We work closely with our customers to help them understand the right combination of solutions.
//                 </p>
//                 <hr />
//                 <p className="author">By Rohit Patil</p>
//               </div>
//             </div>
//           </Link>
//         </div>
//           </>
//         )}
//       </div>

 
//       <div className="text-center">
//         <button className="btn btn-primary" onClick={handleReadMoreLess}>
//           {isExpanded ? 'Read Less' : 'Read More'}
//         </button>
//       </div>
//     </div>
//     </>
//   );
// };

// export default Blogs;

// import React, { useState } from 'react';
// import { Link } from 'react-router-dom';
// import SAS from './Images/SAS.jpg'; // Adjust your image import

// const BlogSection = () => {
//   const [selectedCategory, setSelectedCategory] = useState('All');

//   const blogs = [
//     { id: 1, title: "Period Weight Factor", date: "Sep 13 2024", author: "Siddamma Gudda", category: "IBP", description: "The disaggregation process adheres to the standard principles of proportional disaggregation, with the exception that the proportionality factors are determined by the attribute designated as the period weight factor." },
//     { id: 2, title: "ABC / XYZ Segmentation", date: "March 6th 2024", author: "BCS", category: "IBP", description: "How the Segmentation varies as compared to K means and other algorithms  ABC/XYZ segmentation is the method of grouping planning objects based on the values of a selected key figure." },
//     { id: 3, title: "Power of Scenarios in SAP IBP", date: "March 6th 2024", author: "Aniket Sathe", category: "IBP", description: "In the dynamic and ever-evolving business landscape, organizations constantly grapple with a multitude of uncertainties, ranging from fluctuating market trends to sudden shifts in customer demand" },
//     { id: 4, title: "Custom Aletrs In SAP IBP", date: "March 6th 2024", author: "Priya Thakre", category: "IBP", description: "Supply chain problems that are significant or critical, including inventory shortages, supply and demand imbalances, or unanticipated changes in the supply chain, are detected by custom alerts." },
//     { id: 5, title: "Product Lifecycle Planning", date: "April 11th 2024", author: "Priya Thakre", category: "IBP", description: "Historic data is typically unavailable while developing a new product, and due to a lack of sales history, forecasting is difficult. Therefore, you look for similar products whose historical sales data you can use." },
//     { id: 6, title: "Core Interface", date: "April 16th 2024", author: "Avadhut Adake", category: "APO", description: "SAP APO, integrated with ERP systems like SAP R/3 or SAP ECC, optimizes supply chain processes through planning and optimization. The Core Interface bridges the source, target systems, facilitating the transfer of master  data." },
//     { id: 7, title: "SAP APO – HEURISTICS", date: "March 6th 2024", author: "Avadhut Adake", category: "APO", description: "Heuristics are automated planning engines in SAP APO SNP that generate supply plans based on rules, not costs. They assume unlimited production capacity and use bucket-based planning." },
//     { id: 8, title: "SAP APO MASTER DATA", date: "March 6th 2024", author: "Chetali Jeur", category: "IBP", description: "Master data in SAP APO plays a crucial role, just like master data in SAP ECC. Accurate setup of master data, including customer, product, and plant information, is essential for conducting ATP checks in SAP APO." },
//     { id: 9, title: "Data Integration using  CI-DS", date: "April 19th 2024", author: "Vishal Patil", category: "CI_DS", description: "The integration of SAP IBP with SAP CI-DS signifies a transformative shift in data management and connectivity, empowering organizations to enhance business planning and decision-making processes." },
//     // Add more blogs here...
//   ];

//   const handleCategoryClick = (category) => {
//     setSelectedCategory(category);
//   };

//   const filteredBlogs = selectedCategory === 'Explore by All Categories'
//     ? blogs
//     : blogs.filter(blog => blog.category === selectedCategory);
//     const handleCategoryChange = (event) => {
//       setSelectedCategory(event.target.value);
//     };

//   return (
//     <>
//         <div className="container-fluid blogs">
//          <h1>Blogs</h1>
//     </div>
//     <div className="container">
//     <select className="category-dropdown" onChange={handleCategoryChange} value={selectedCategory}>
//         <option value="Explore by All Categories">Explore by All Categories</option>
        
//         <option value="IBP">IBP</option>
//         <option value="APO">APO</option>
//         <option value="APO">CI-DS</option>
//         {/* Add more categories as needed */}
//       </select>

//       <div className="row">
//         {filteredBlogs.map(blog => (
//           <div className="col-md-4 mb-4" key={blog.id}>
//             <Link to={`/blogdetails${blog.id}`} className="card-link">
//               <div className="card">
//                 <div className="card-img">
//                   <img src={SAS} alt="Blog Image" className="img-fluid" />
//                   <span className="badge badge-primary">{blog.category}</span>
//                 </div>
//                 <div className="card-body">
//                   <p className="date">{blog.date}</p>
//                   <h3 className="card-title">{blog.title}</h3>
//                   <p className="description">{blog.description}</p>
//                   <hr />
//                   <p className="author">By {blog.author}</p>
//                 </div>
//               </div>
//             </Link>
//           </div>
//         ))}
//       </div>
//     </div>
//     </>

//   );
// };

// // export default BlogSection;
// import React, { useState } from 'react';
// import { Link } from 'react-router-dom';
// import SAS from './Images/SAS.jpg'; // Adjust your image import

// const BlogSection = () => {
//   const [selectedCategory, setSelectedCategory] = useState('Explore by All Categories');

//   const blogs = [
//     { 
//         id: 1, 
//         title: "Period Weight Factor", 
//         date: "Sep 13 2024", 
//         author: "Siddamma Gudda", 
//         category: "IBP", 
//         description: "The disaggregation process adheres to the standard principles of proportional disaggregation, with the exception that the proportionality factors are determined by the period weight factor." 
//     },
//     { 
//         id: 2, 
//         title: "ABC / XYZ Segmentation", 
//         date: "March 6th 2024", 
//         author: "BCS", 
//         category: "IBP", 
//         description: "How the Segmentation varies as compared to K means and other algorithms. ABC/XYZ segmentation is the method of grouping planning objects based on the values of a selected key figure." 
//     },
//     { 
//         id: 3, 
//         title: "Power of Scenarios in SAP IBP", 
//         date: "March 6th 2024", 
//         author: "Aniket Sathe", 
//         category: "IBP", 
//         description: "In the dynamic and ever-evolving business landscape, organizations constantly grapple with a multitude of uncertainties trends to sudden shifts in customer demand." 
//     },
//     { 
//         id: 4, 
//         title: "Custom Alerts In SAP IBP", 
//         date: "March 6th 2024", 
//         author: "Priya Thakre", 
//         category: "IBP", 
//         description: "Supply chain problems that are significant or critical, including inventory shortages, supply and demand imbalances, or unanticipated changes in the supply chain, are detected by custom alerts." 
//     },
//     { 
//         id: 5, 
//         title: "Product Lifecycle Planning", 
//         date: "April 11th 2024", 
//         author: "Priya Thakre", 
//         category: "IBP", 
//         description: "Historic data is typically unavailable while developing a new product, and due to a lack of sales history, forecasting is difficult. Therefore, you look for similar products whose historical sales data you can use." 
//     },
//     { 
//         id: 6, 
//         title: "Core Interface", 
//         date: "April 16th 2024", 
//         author: "Avadhut Adake", 
//         category: "APO", 
//         description: "SAP APO, integrated with ERP systems like SAP R/3 or SAP ECC, optimizes supply chain processes through planning and optimization. The Core Interface bridges the source and target systems, facilitating the transfer of master data." 
//     },
//     { 
//         id: 7, 
//         title: "SAP APO – HEURISTICS", 
//         date: "March 6th 2024", 
//         author: "Avadhut Adake", 
//         category: "APO", 
//         description: "Heuristics are automated planning engines in SAP APO SNP that generate supply plans based on rules, not costs. They assume unlimited production capacity and use bucket-based planning." 
//     },
//     { 
//         id: 8, 
//         title: "SAP APO MASTER DATA", 
//         date: "March 6th 2024", 
//         author: "Chetali Jeur", 
//         category: "IBP", 
//         description: "Master data in SAP APO plays a crucial role, just like master data in SAP ECC. Accurate setup of master data, including customer, and plant information, is essential for checking in SAP APO." 
//     },
//     { 
//         id: 9, 
//         title: "Data Integration using CI-DS", 
//         date: "April 19th 2024", 
//         author: "Vishal Patil", 
//         category: "CI_DS", 
//         description: "The integration of SAP IBP with SAP CI-DS signifies a transformative shift in data management and connectivity, empowering organizations to enhance business planning" 
//     },
//     // Add more blogs here...
// ];


//   const handleCategoryChange = (event) => {
//     setSelectedCategory(event.target.value);
//   };

//   const filteredBlogs = selectedCategory === 'Explore by All Categories'
//     ? blogs
//     : blogs.filter(blog => blog.category === selectedCategory);

//   return (
//     <>
//       <div className="container-fluid blogs">
//         <h1>Blogs</h1>
//       </div>
//       <div className="container blogsinformation">
//         <select className="category-dropdown" onChange={handleCategoryChange} value={selectedCategory}>
//           <option value="Explore by All Categories">Explore by All Categories</option>
//           <option value="IBP">IBP</option>
//           <option value="APO">APO</option>
//           <option value="CI_DS">CI-DS</option>
//           {/* Add more categories as needed */}
//         </select>

//         <div className="row blogsrow">
//           {filteredBlogs.map(blog => (
//             <div className="col-md-4" key={blog.id}>
//               <Link to={`/blogdetails${blog.id}`} className="card-link">
//                 <div className="card">
//                   <div className="card-img">
//                     <img src={SAS} alt="Blog Image" className="img-fluid" />
//                     <span className="badge badge-primary">{blog.category}</span>
//                   </div>
//                   <div className="card-body">
//                     <p className="date">{blog.date}</p>
//                     <h3 className="card-title">{blog.title}</h3>
//                     <p className="description">{blog.description}</p>
//                     <hr />
//                     <p className="author">By {blog.author}</p>
//                   </div>
//                 </div>
//               </Link>
//             </div>
//           ))}
//         </div>
//       </div>
//     </>
//   );
// };

// // export default BlogSection;
// import React, { useState } from 'react';
// import { Link } from 'react-router-dom';
// // Adjust image imports for each blog
// import blog1 from './Images/blog.jpg';
// import blog2 from './Images/blog2.jpg';
// import blog3 from './Images/blog3.jpg';
// import blog4 from './Images/blog4.jpg';
// import blog5 from './Images/Product_Planning.jpg';
// import blog6 from './Images/sap core interface.jpg';
// import blog7 from './Images/heuristics apo sap blog.jpg';
// import blog8 from './Images/sap_master_data.jpg';
// import blog9 from './Images/intergrations blog.jpg';

// const BlogSection = () => {
//   const [selectedCategory, setSelectedCategory] = useState('Explore by All Categories');

//   const blogs = [
//     { 
//         id: 1, 
//         title: "Period Weight Factor", 
//         date: "Sep 13 2024", 
//         author: "Siddamma Gudda", 
//         category: "IBP", 
//         description: "The disaggregation process adheres to the standard principles of proportional disaggregation, with the exception that the proportionality factors are determined by the period weight factor.",
//         image: blog1 // Image for this blog
//     },
//     { 
//         id: 2, 
//         title: "ABC / XYZ Segmentation", 
//         date: "March 6th 2024", 
//         author: "BCS", 
//         category: "IBP", 
//         description: "How the Segmentation varies as compared to K means and other algorithms. ABC/XYZ segmentation is the method of grouping planning objects based on the values of a selected key figure.",
//         image: blog2 // Image for this blog
//     },
//     { 
//         id: 3, 
//         title: "Power of Scenarios in SAP IBP", 
//         date: "March 6th 2024", 
//         author: "Aniket Sathe", 
//         category: "IBP", 
//         description: "In the dynamic and ever-evolving business landscape, organizations constantly grapple with a multitude of uncertainties trends to sudden shifts in customer demand.",
//         image: blog3 // Image for this blog
//     },
//     { 
//         id: 4, 
//         title: "Custom Alerts In SAP IBP", 
//         date: "March 6th 2024", 
//         author: "Priya Thakre", 
//         category: "IBP", 
//         description: "Supply chain problems that are significant or critical, including inventory shortages, supply and demand imbalances, or unanticipated changes in the supply chain, are detected by custom alerts.",
//         image: blog4 // Image for this blog
//     },
//     { 
//         id: 5, 
//         title: "Product Lifecycle Planning", 
//         date: "April 11th 2024", 
//         author: "Priya Thakre", 
//         category: "IBP", 
//         description: "Historic data is typically unavailable while developing a new product, and due to a lack of sales history, forecasting is difficult. Therefore, you look for similar products whose historical sales data you can use.",
//         image: blog5 // Image for this blog
//     },
//     { 
//         id: 6, 
//         title: "Core Interface", 
//         date: "April 16th 2024", 
//         author: "Avadhut Adake", 
//         category: "APO", 
//         description: "SAP APO, integrated with ERP systems like SAP R/3 or SAP ECC, optimizes supply chain processes through planning and optimization. The Core Interface bridges the source and target systems, facilitating the transfer of master data.",
//         image: blog6 // Image for this blog
//     },
//     { 
//         id: 7, 
//         title: "SAP APO – HEURISTICS", 
//         date: "March 6th 2024", 
//         author: "Avadhut Adake", 
//         category: "APO", 
//         description: "Heuristics are automated planning engines in SAP APO SNP that generate supply plans based on rules, not costs. They assume unlimited production capacity and use bucket-based planning.",
//         image: blog7 // Image for this blog
//     },
//     { 
//         id: 8, 
//         title: "SAP APO MASTER DATA", 
//         date: "March 6th 2024", 
//         author: "Chetali Jeur", 
//         category: "IBP", 
//         description: "Master data in SAP APO plays a crucial role, just like master data in SAP ECC. Accurate setup of master data, including customer, and plant information, is essential for checking in SAP APO.",
//         image: blog8 // Image for this blog
//     },
//     { 
//         id: 9, 
//         title: "Data Integration using CI-DS", 
//         date: "April 19th 2024", 
//         author: "Vishal Patil", 
//         category: "CI_DS", 
//         description: "The integration of SAP IBP with SAP CI-DS signifies a transformative shift in data management and connectivity, empowering organizations to enhance business planning.",
//         image: blog9 // Image for this blog
//     },
//   ];

//   const handleCategoryChange = (event) => {
//     setSelectedCategory(event.target.value);
//   };

//   const filteredBlogs = selectedCategory === 'Explore by All Categories'
//     ? blogs
//     : blogs.filter(blog => blog.category === selectedCategory);

//   return (
//     <>
//       <div className="container-fluid blogs">
//         <h1>Blogs</h1>
//       </div>
//       <div className="container blogsinformation">
//         <select className="category-dropdown" onChange={handleCategoryChange} value={selectedCategory}>
//           <option value="Explore by All Categories">Explore by All Categories</option>
//           <option value="IBP">IBP</option>
//           <option value="APO">APO</option>
//           <option value="CI_DS">CI-DS</option>
//         </select>

//         <div className="row blogsrow">
//           {filteredBlogs.map(blog => (
//             <div className="col-md-4" key={blog.id}>
//               <Link to={`/blogdetails${blog.id}`} className="card-link">
//                 <div className="card">
//                   <div className="card-img">
//                     <img src={blog.image} alt="Blog" className="img-fluid" />
//                     <span className="badge badge-primary">{blog.category}</span>
//                   </div>
//                   <div className="card-body">
//                     <p className="date">{blog.date}</p>
//                     <h3 className="card-title">{blog.title}</h3>
//                     <p className="description">{blog.description}</p>
//                     <hr />
//                     <p className="author">By {blog.author}</p>
//                   </div>
//                 </div>
//               </Link>
//             </div>
//           ))}
//         </div>
//       </div>
//     </>
//   );
// };

// // export default BlogSection;
// import React, { useState } from 'react';
// import { Link } from 'react-router-dom';
// // Adjust image imports for each blog
// import blog1 from './Images/blog.jpg';
// import blog2 from './Images/blog2.jpg';
// import blog3 from './Images/blog3.jpg';
// import blog4 from './Images/blog4.jpg';
// import blog5 from './Images/Product_Planning.jpg';
// import blog6 from './Images/sap core interface.jpg';
// import blog7 from './Images/heuristics apo sap blog.jpg';
// import blog8 from './Images/sap_master_data.jpg';
// import blog9 from './Images/intergrations blog.jpg';

// const BlogSection = () => {
//   const [searchTerm, setSearchTerm] = useState('');

//   const blogs = [
//     { 
//         id: 1, 
//         title: "Period Weight Factor", 
//         date: "Sep 13 2024", 
//         author: "Siddamma Gudda", 
//         category: "IBP", 
//         description: "The disaggregation process adheres to the standard principles of proportional disaggregation, with the exception that the proportionality factors are determined by the period weight factor.",
//         image: blog1 // Image for this blog
//     },
//     { 
//         id: 2, 
//         title: "ABC / XYZ Segmentation", 
//         date: "March 6th 2024", 
//         author: "BCS", 
//         category: "IBP", 
//         description: "How the Segmentation varies as compared to K means and other algorithms. ABC/XYZ segmentation is the method of grouping planning objects based on the values of a selected key figure.",
//         image: blog2 // Image for this blog
//     },
//     { 
//         id: 3, 
//         title: "Power of Scenarios in SAP IBP", 
//         date: "March 6th 2024", 
//         author: "Aniket Sathe", 
//         category: "IBP", 
//         description: "In the dynamic and ever-evolving business landscape, organizations constantly grapple with a multitude of uncertainties trends to sudden shifts in customer demand.",
//         image: blog3 // Image for this blog
//     },
//     { 
//         id: 4, 
//         title: "Custom Alerts In SAP IBP", 
//         date: "March 6th 2024", 
//         author: "Priya Thakre", 
//         category: "IBP", 
//         description: "Supply chain problems that are significant or critical, including inventory shortages, supply and demand imbalances, or unanticipated changes in the supply chain, are detected by custom alerts.",
//         image: blog4 // Image for this blog
//     },
//     { 
//         id: 5, 
//         title: "Product Lifecycle Planning", 
//         date: "April 11th 2024", 
//         author: "Priya Thakre", 
//         category: "IBP", 
//         description: "Historic data is typically unavailable while developing a new product, and due to a lack of sales history, forecasting is difficult. Therefore, you look for similar products whose historical sales data you can use.",
//         image: blog5 // Image for this blog
//     },
//     { 
//         id: 6, 
//         title: "Core Interface", 
//         date: "April 16th 2024", 
//         author: "Avadhut Adake", 
//         category: "APO", 
//         description: "SAP APO, integrated with ERP systems like SAP R/3 or SAP ECC, optimizes supply chain processes through planning and optimization. The Core Interface bridges the source and target systems, facilitating the transfer of master data.",
//         image: blog6 // Image for this blog
//     },
//     { 
//         id: 7, 
//         title: "SAP APO – HEURISTICS", 
//         date: "March 6th 2024", 
//         author: "Avadhut Adake", 
//         category: "APO", 
//         description: "Heuristics are automated planning engines in SAP APO SNP that generate supply plans based on rules, not costs. They assume unlimited production capacity and use bucket-based planning.",
//         image: blog7 // Image for this blog
//     },
//     { 
//         id: 8, 
//         title: "SAP APO MASTER DATA", 
//         date: "March 6th 2024", 
//         author: "Chetali Jeur", 
//         category: "IBP", 
//         description: "Master data in SAP APO plays a crucial role, just like master data in SAP ECC. Accurate setup of master data, including customer, and plant information, is essential for checking in SAP APO.",
//         image: blog8 // Image for this blog
//     },
//     { 
//         id: 9, 
//         title: "Data Integration using CI-DS", 
//         date: "April 19th 2024", 
//         author: "Vishal Patil", 
//         category: "CI_DS", 
//         description: "The integration of SAP IBP with SAP CI-DS signifies a transformative shift in data management and connectivity, empowering organizations to enhance business planning.",
//         image: blog9 // Image for this blog
//     },
//   ];

//   const handleSearchChange = (event) => {
//     setSearchTerm(event.target.value);
//   };

//   const filteredBlogs = blogs.filter(blog =>
//     blog.title.toLowerCase().includes(searchTerm.toLowerCase()) || 
//     blog.description.toLowerCase().includes(searchTerm.toLowerCase())
//   );

//   return (
//     <>
//       <div className="container-fluid blogs">
//         <h1>Blogs</h1>
//       </div>
//       <div className="container blogsinformation">
//       <div className="search-container">
//     <input 
//       type="text" 
//       className="search-bar"  
//       placeholder="Search blogs..." 
//       value={searchTerm} 
//       onChange={handleSearchChange} 
//     />
//   </div>

//         <div className="row blogsrow">
//           {filteredBlogs.map(blog => (
//             <div className="col-md-4" key={blog.id}>
//               <Link to={`/blogdetails${blog.id}`} className="card-link">
//                 <div className="card">
//                   <div className="card-img">
//                     <img src={blog.image} alt="Blog" className="img-fluid" />
//                     <span className="badge badge-primary">{blog.category}</span>
//                   </div>
//                   <div className="card-body">
//                     <p className="date">{blog.date}</p>
//                     <h3 className="card-title">{blog.title}</h3>
//                     <p className="description">{blog.description}</p>
//                     <hr />
//                     <p className="author">By {blog.author}</p>
//                   </div>
//                 </div>
//               </Link>
//             </div>
//           ))}
//         </div>
//       </div>
//     </>
//   );
// };

// export default BlogSection;
// import React, { useState } from 'react';
// import { Link } from 'react-router-dom';
// import blog1 from './Images/blog.jpg';
// import blog2 from './Images/blog2.jpg';
// import blog3 from './Images/blog3.jpg';
// import blog4 from './Images/blog4.jpg';
// import blog5 from './Images/Product_Planning.jpg';
// import blog6 from './Images/sap core interface.jpg';
// import blog7 from './Images/heuristics apo sap blog.jpg';
// import blog8 from './Images/sap_master_data.jpg';
// import blog9 from './Images/intergrations blog.jpg';

// const BlogSection = () => {
//   const [searchTerm, setSearchTerm] = useState('');

//   const blogs = [
//     { 
//       id: 1, 
//       title: "Period Weight Factor", 
//       date: "Sep 13 2024", 
//       author: "Siddamma Gudda", 
//       category: "IBP", 
//       description: "The disaggregation process adheres to the standard principles of proportional disaggregation, with the exception that the proportionality factors are determined by the period weight factor.",
//       image: blog1 
//     },
//     { 
//       id: 2, 
//       title: "ABC / XYZ Segmentation", 
//       date: "March 6th 2024", 
//       author: "BCS", 
//       category: "IBP", 
//       description: "How the Segmentation varies as compared to K means and other algorithms. ABC/XYZ segmentation is the method of grouping planning objects based on the values of a selected key figure.",
//       image: blog2 
//     },
//     { 
//               id: 3, 
//               title: "Power of Scenarios in SAP IBP", 
//               date: "March 6th 2024", 
//               author: "Aniket Sathe", 
//               category: "IBP", 
//               description: "In the dynamic and ever-evolving business landscape, organizations constantly grapple with a multitude of uncertainties trends to sudden shifts in customer demand.",
//               image: blog3 // Image for this blog
//           },
//           { 
//               id: 4, 
//               title: "Custom Alerts In SAP IBP", 
//               date: "March 6th 2024", 
//               author: "Priya Thakre", 
//               category: "IBP", 
//               description: "Supply chain problems that are significant or critical, including inventory shortages, supply and demand imbalances, or unanticipated changes in the supply chain, are detected by custom alerts.",
//               image: blog4 // Image for this blog
//           },
//           { 
//               id: 5, 
//               title: "Product Lifecycle Planning", 
//               date: "April 11th 2024", 
//               author: "Priya Thakre", 
//               category: "IBP", 
//               description: "Historic data is typically unavailable while developing a new product, and due to a lack of sales history, forecasting is difficult. Therefore, you look for similar products whose historical sales data you can use.",
//               image: blog5 // Image for this blog
//           },
//           { 
//               id: 6, 
//               title: "Core Interface", 
//               date: "April 16th 2024", 
//               author: "Avadhut Adake", 
//               category: "APO", 
//               description: "SAP APO, integrated with ERP systems like SAP R/3 or SAP ECC, optimizes supply chain processes through planning and optimization. The Core Interface bridges the source and target systems, facilitating the transfer of master data.",
//               image: blog6 // Image for this blog
//           },
//           { 
//               id: 7, 
//               title: "SAP APO – HEURISTICS", 
//               date: "March 6th 2024", 
//               author: "Avadhut Adake", 
//               category: "APO", 
//               description: "Heuristics are automated planning engines in SAP APO SNP that generate supply plans based on rules, not costs. They assume unlimited production capacity and use bucket-based planning.",
//               image: blog7 // Image for this blog
//           },
//           { 
//               id: 8, 
//               title: "SAP APO MASTER DATA", 
//               date: "March 6th 2024", 
//               author: "Chetali Jeur", 
//               category: "IBP", 
//               description: "Master data in SAP APO plays a crucial role, just like master data in SAP ECC. Accurate setup of master data, including customer, and plant information, is essential for checking in SAP APO.",
//               image: blog8 // Image for this blog
//           },
//           { 
//               id: 9, 
//               title: "Data Integration using CI-DS", 
//               date: "April 19th 2024", 
//               author: "Vishal Patil", 
//               category: "CI_DS", 
//                description: "The integration of SAP IBP with SAP CI-DS signifies a transformative shift in data management and connectivity, empowering organizations to enhance business planning.",
//                image: blog9 // Image for this blog
//           },
//   ];

//   const handleSearchChange = (event) => {
//     setSearchTerm(event.target.value);
//   };

//   const handleSearchClick = (event) => {
//     event.preventDefault(); // Prevent form submission
//     // You can add any additional actions on search click here if needed
//   };

//   const filteredBlogs = blogs.filter(blog =>
//     blog.title.toLowerCase().includes(searchTerm.toLowerCase()) || 
//     blog.description.toLowerCase().includes(searchTerm.toLowerCase())
//   );

//   return (
//     <>
//       <div className="container-fluid blogs">
//         <h1>Blogs</h1>
//       </div>
//       <div className="container blogsinformation">
//         <div className="search-container">
//           <form onSubmit={handleSearchClick} className="search-form">
//             <h2>Search Blog List</h2>
//             <input 
//               type="text" 
//               className="search-bar"  
//               placeholder="Search blogs..." 
//               value={searchTerm} 
//               onChange={handleSearchChange} 
//             />
//             <button type="submit" className="search-button">Search</button>
//           </form>
//         </div>

//         <div className="row blogsrow">
//           {filteredBlogs.map(blog => (
//             <div className="col-md-4" key={blog.id}>
//               <Link to={`/blogdetails${blog.id}`} className="card-link">
//                 <div className="card">
//                   <div className="card-img">
//                     <img src={blog.image} alt="Blog" className="img-fluid" />
//                     <span className="badge badge-primary">{blog.category}</span>
//                   </div>
//                   <div className="card-body">
//                     <p className="date">{blog.date}</p>
//                     <h3 className="card-title">{blog.title}</h3>
//                     <p className="description">{blog.description}</p>
//                     <hr />
//                     <p className="author">By {blog.author}</p>
//                   </div>
//                 </div>
//               </Link>
//             </div>
//           ))}
//         </div>
//       </div>
//     </>
//   );
// };

// export default BlogSection;

import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import blog1 from './Images/blog.jpg';
import blog2 from './Images/blog2.jpg';
import blog3 from './Images/blog3.jpg';
import blog4 from './Images/blog4.jpg';
import blog5 from './Images/Product_Planning.jpg';
import blog6 from './Images/sap core interface.jpg';
import blog7 from './Images/heuristics apo sap blog.jpg';
import blog8 from './Images/sap_master_data.jpg';
import blog9 from './Images/intergrations blog.jpg';

const BlogSection = () => {
  const [searchTerm, setSearchTerm] = useState('');

  const blogs = [
    { 
      id: 1, 
      title: "Period Weight Factor", 
      date: "Sep 13 2024", 
      author: "Siddamma Gudda", 
      category: "IBP", 
      description: "The disaggregation process adheres to the standard principles of proportional disaggregation, with the exception that the proportionality factors are determined by the period weight factor.",
      image: blog1 
    },
    { 
      id: 2, 
      title: "ABC / XYZ Segmentation", 
      date: "March 6th 2024", 
      author: "BCS", 
      category: "IBP", 
      description: "How the Segmentation varies as compared to K means and other algorithms. ABC/XYZ segmentation is the method of grouping planning objects based on the values of a selected key figure.",
      image: blog2 
    },
    { 
      id: 3, 
      title: "Power of Scenarios in SAP IBP", 
      date: "March 6th 2024", 
      author: "Aniket Sathe", 
      category: "IBP", 
      description: "In the dynamic and ever-evolving business landscape, organizations constantly grapple with a multitude of uncertainties trends to sudden shifts in customer demand.",
      image: blog3 
    },
    { 
      id: 4, 
      title: "Custom Alerts In SAP IBP", 
      date: "March 6th 2024", 
      author: "Priya Thakre", 
      category: "IBP", 
      description: "Supply chain problems that are significant or critical, including inventory shortages, supply and demand imbalances, or unanticipated changes in the supply chain, are detected by custom alerts.",
      image: blog4 
    },
    { 
      id: 5, 
      title: "Product Lifecycle Planning", 
      date: "April 11th 2024", 
      author: "Priya Thakre", 
      category: "IBP", 
      description: "Historic data is typically unavailable while developing a new product, and due to a lack of sales history, forecasting is difficult. Therefore, you look for similar products whose historical sales data you can use.",
      image: blog5 
    },
    { 
      id: 6, 
      title: "Core Interface", 
      date: "April 16th 2024", 
      author: "Avadhut Adake", 
      category: "APO", 
      description: "SAP APO, integrated with ERP systems like SAP R/3 or SAP ECC, optimizes supply chain processes through planning and optimization. The Core Interface bridges the source and target systems, facilitating the transfer of master data.",
      image: blog6 
    },
    { 
      id: 7, 
      title: "SAP APO – HEURISTICS", 
      date: "March 6th 2024", 
      author: "Avadhut Adake", 
      category: "APO", 
      description: "Heuristics are automated planning engines in SAP APO SNP that generate supply plans based on rules, not costs. They assume unlimited production capacity and use bucket-based planning.",
      image: blog7 
    },
    { 
      id: 8, 
      title: "SAP APO MASTER DATA", 
      date: "March 6th 2024", 
      author: "Chetali Jeur", 
      category: "IBP", 
      description: "Master data in SAP APO plays a crucial role, just like master data in SAP ECC. Accurate setup of master data, including customer, and plant information, is essential for checking in SAP APO.",
      image: blog8 
    },
    { 
      id: 9, 
      title: "Data Integration using CI-DS", 
      date: "April 19th 2024", 
      author: "Vishal Patil", 
      category: "CI_DS", 
      description: "The integration of SAP IBP with SAP CI-DS signifies a transformative shift in data management and connectivity, empowering organizations to enhance business planning.",
      image: blog9 
    },
  ];

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleSearchClick = (event) => {
    event.preventDefault(); // Prevent form submission
    // You can add any additional actions on search click here if needed
  };

  // Modify the filter to include category in the search criteria
  const filteredBlogs = blogs.filter(blog =>
    blog.title.toLowerCase().includes(searchTerm.toLowerCase()) || 
    blog.description.toLowerCase().includes(searchTerm.toLowerCase()) ||
    blog.category.toLowerCase().includes(searchTerm.toLowerCase()) // Added category search condition
  );

  return (
    <>
      <div className="container-fluid blogs">
        <h1>Blogs</h1>
      </div>
      <div className="container blogsinformation">
        <div className="search-container">
          <form onSubmit={handleSearchClick} className="search-form">
            <h2>Search Blog List</h2>
            <input 
              type="text" 
              className="search-bar"  
              placeholder="Search blogs by title, description or category..." 
              value={searchTerm} 
              onChange={handleSearchChange} 
            />
            <button type="submit" className="search-button">Search</button>
          </form>
        </div>

        <div className="row blogsrow">
          {filteredBlogs.map(blog => (
            <div className="col-md-4" key={blog.id}>
              <Link to={`/blogdetails${blog.id}`} className="card-link">
                <div className="card">
                  <div className="card-img">
                    <img src={blog.image} alt="Blog" className="img-fluid" />
                    <span className="badge badge-primary">{blog.category}</span>
                  </div>
                  <div className="card-body">
                    <p className="date">{blog.date}</p>
                    <h3 className="card-title">{blog.title}</h3>
                    <p className="description">{blog.description}</p>
                    <hr />
                    <p className="author">By {blog.author}</p>
                  </div>
                </div>
              </Link>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default BlogSection;
