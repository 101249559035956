import React from "react";
import Home4 from "./Images/home4.jpg";
export const History = () => {
  return (
    <>
      <div className="carrier-container">
        <div className="carrierimg"></div>
        <div className="carrier-text">History of Bestowal</div>
      </div>
      <div className="container aboutcarrier">
        <div className="row carrierrow">
          <div className="col-md-6">
            <img src={Home4} alt="Contact" />
          </div>
          <div className="col-md-6">
            <h2>Our Story</h2>
            <p>
              Founded in July 2022<b> Bestowal Consultancy and Services</b> we started
              our journey with a team of seven passionate professionals,
              specializing in SAP consulting. Over the years, we have
              established ourselves as a valued brand in the IT industry by
              focusing on enterprise software consulting, implementation, and
              maintenance support.<br/>
               Our commitment to excellence and our
              unwavering determination to help our clients succeed have been the
              cornerstones of our growth. We believe in empowering our people
              and fostering an environment where innovation and learning thrive.
              Through teamwork and leadership, we create greater value and drive
              sustainable growth.<br/>
               Despite facing numerous challenges, our
              dedicated team has demonstrated remarkable courage, skill, and
              intelligence. This dedication has earned us a global reputation,
              allowing us to collaborate with prestigious companies like DyStar,
              Tech Mahindra, and Wudza.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};
export default History;
