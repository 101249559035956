import React from "react";
import resource from "./Images/Resource.jpeg";
const Resource = () => {
  return (
    <>
      <div className="container resource">
        <div className="row nativerow">
          <div className="col-md-4">
            <img src={resource} width={300} />
          </div>
          <div className="col-md-8">
            <h2>Resource Solutions</h2>
          </div>
        </div>
      </div>
      <div className="container-fluid resourcecolor">
        <div className=" container resourceinfo">
          <h2>About Resource Solution</h2>
          <p>
            We also involve in "Resource Solutions"… Providing bespoke
            recruitment resource solutions to the world's leading brands. In
            addition to offering RPO and MSP services, we also work in
            partnership with our clients to help continually improve the
            recruitment process. With our dedicated innovation team and
            award-winning recruitment technology, talent source, we can support
            end-to-end recruitment lifecycle tracking. We aim to provide
            services that are flexible and put our customers' needs first. We
            are proud to provide our customers with critical information related
            to staffing solutions, data protection and security operations.
            These resources enable customers to make informed decisions to
            better manage complex infrastructure, lower operating costs and
            ultimately maximize profitability.
          </p>
        </div>
      </div>
    </>
  );
};

export default Resource;
