import React from 'react'
import Conclusion from "./Images/Digital-transformation.png";
import oip from "./Images/OIP.jpeg";
const Customer = () => {
  return (
    <>
        <div className="container native1">
        <div className="row nativerow">
          <div className="col-md-4">
            <img src={oip} />
          </div>
          <div className="col-md-8">
          <h2>Bestowal Systems And Services</h2>
            <p>
              From concept to deployment, streamline your engineering workflow.
            </p>
          </div>
        </div>
      </div>
      <div className="container-fluid nativecolor">
        <div className="container process">
          <h2>
          Implementation of SAP IBP Supply for a Leading Consumer Goods Company in the USA
          </h2>
          <div className="container">
            <section class="client-overview">
              <h3>Client Overview</h3>
              <p>
              A leading Consumer Goods Company in the USA with a vast product portfolio and a diverse supplier base.
              </p>
            </section>

            <section class="technologies-used">
              <h3>Technologies Used</h3>
              <ul>
                <li>SAP IBP (Integrated Business Planning)</li>
                <li>SAP ECC (ERP Central Component)</li>
              </ul>
            </section>
          </div>
          <div className="container">
            <section class="key-challenges">
              <h3>Key Challenges</h3>
              <p>The client faced several significant challenges:</p>
              <ul>
                <li>
                  <strong>Implementing IBP Supply: </strong>The client needed to implement SAP IBP Supply in both Constrained and Unconstrained modes. The goal was to digitalize, standardize, and create a more agile Rough-Cut Capacity Planning (RCCP) process for mid to long-term planning horizons.
                </li>
                <li>
                  <strong>High Volume and Complex Product Mix: </strong> The company manages a product portfolio with over 20,000 Stock Keeping Units (SKUs), requiring robust management due to the extensive variety of suppliers and the complexity of product mixes.
                </li>
                <li>
                  <strong>Competitive and Volatile Market:</strong> Operating in a highly competitive and volatile market environment necessitated precise synchronization of demand and supply to win new business opportunities while maintaining cost-effectiveness.
                </li>
              </ul>
            </section>
          </div>
          <div className="container">
            <section class="solution-implementation">
              <h3>Solution and Implementation</h3>
              <p>
              Our solution focused on addressing these challenges through the implementation of SAP IBP:
              </p>
              <ul>
                <li>
                  <strong>Migration to Standard Processes:</strong> The project successfully transitioned the client's custom processes to standard ones, streamlining operations and reducing complexities.
                </li>
                <li>
                  <strong>	Process Improvements and Gap Implementation:</strong> Identified and implemented necessary process improvements and addressed existing gaps to enhance overall efficiency and performance.
                </li>
                <li>
                  <strong>	Enhanced Demand-Supply Balance:</strong>The implementation allowed for better balancing of demand and supply, meeting business and customer expectations more effectively.
                </li>
                <li>
                  <strong>Integrated Planning Tool:</strong> The SAP IBP solution integrated demand and supply into a single, user-friendly tool. This integration provided the client with increased agility and flexibility in managing their RCCP process.
                </li>
              </ul>
            </section>
          </div>
          <div className="container">
            <section class="value-delivered">
              <h3>Value Delivered</h3>
              <p>The implementation of our solution brought substantial benefits to the client:</p>
              <ul>
                <li>
                  <strong>Standardization and Agility:</strong> Achieved a more standardized and agile RCCP process, enabling the client to respond swiftly to market changes and demand fluctuations.
                </li>
                <li>
                  <strong>Improved Efficiency:</strong>The migration and process improvements led to a more efficient planning process, reducing manual interventions and improving accuracy.
                </li>
                <li>
                  <strong>Cost-Effective Operations:</strong> The standardized processes
                  By optimizing the synchronization of demand and supply, the company could operate more cost-effectively, crucial for maintaining competitiveness in a volatile market.
                </li>
                <li>
                  <strong>Enhanced User Experience:</strong> The client
                  The integrated IBP tool simplified the planning process, making it easier for the team to use and thus increasing overall productivity. 
                </li>
              </ul>
            </section>
          </div>
          
        </div>
      </div>
      <div className="container native1">
        <div className="row nativerow">
          <div className="col-md-6">
            <img src={Conclusion} width={450}/>
          </div>
          <div className="col-md-6">
          <h2>Conclusion</h2>
            <p>
            This case study demonstrates the successful implementation of SAP IBP Supply at a leading Consumer Goods Company in the USA. The project addressed significant challenges, delivered substantial improvements, and provided valuable benefits to the client's operations. 
            </p>
          </div>
        </div>
      </div>
    </>
  )
}

export default Customer
