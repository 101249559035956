// import React from "react";
// import { Link } from "react-router-dom";
// const Career = () => {

//   return (
//     <>
//       <div className="container-fluids careers">
//         <h1>Career at Bestowal</h1>
//       </div>
//       <div class="container career">
//         <div class="search-section  text-white">
//           <div class="row careerrow">
//             <div class="col-md-5">
//               {/* <p>Search By Keyword</p> */}
//               <input
//                 type="text"
//                 className="form-control"
//                 placeholder="Enter Job Title"
//               />
//             </div>
//             <div class="col-md-5">
//               {/* <p>Search By Locations</p> */}
//               <input
//                 type="text"
//                 className="form-control"
//                 placeholder="Locations"
//               />
//             </div>

//             <div class="col-md-2">
//               {/* <p>Search</p> */}
//               <button className="careerbutton">Search Jobs</button>
//             </div>
//           </div>
//         </div>

//         <div
//           className="d-flex justify-content-between align-items-center mb-3"
//           style={{ padding: "100px 0px" }}
//         >
//           {/* If you need the alert section, uncomment it and adjust accordingly */}
//           {/* <div class="alert-section d-flex align-items-center">
//         <label for="alert-frequency" class="me-2">Select how often (in days) to receive an alert:</label>
        
//         <div class="number-input">
//             <button class="decrement">-</button>
//             <input id="alert-frequency" class="form-control" type="number" value="1" min="1" max="10"/>
//             <button class="increment">+</button>
//         </div>
        
//         <button class="btn btn-outline-primary ms-3">Create Alert</button>
//     </div> */}

//           <div class="ms-auto">
//             <nav aria-label="Job pagination">
//               <ul class="pagination mb-0">
//                 <li class="page-item">
//                   <a class="page-link" href="#">
//                     «
//                   </a>
//                 </li>
//                 <li class="page-item">
//                   <a class="page-link" href="#">
//                     1
//                   </a>
//                 </li>
//                 <li class="page-item">
//                   <a class="page-link" href="#">
//                     2
//                   </a>
//                 </li>
//                 <li class="page-item">
//                   <a class="page-link" href="#">
//                     3
//                   </a>
//                 </li>
//                 <li class="page-item">
//                   <a class="page-link" href="#">
//                     4
//                   </a>
//                 </li>
//                 <li class="page-item">
//                   <a class="page-link" href="#">
//                     5
//                   </a>
//                 </li>
//                 <li class="page-item">
//                   <a class="page-link" href="#">
//                     »
//                   </a>
//                 </li>
//               </ul>
//             </nav>
//           </div>
//         </div>
//       </div>
//       <div className="container jobtable">
//         <div className="row jobtablerow">
//           <div className="col-md-10">
//             <table className="table job-header">
//               <thead>
//                 <tr>
//                   <th>Title</th>
//                   <th>Location</th>
//                   <th>Date</th>
//                 </tr>
//               </thead>
//               <tbody>
//                 <tr>
//                   <td>
//                     <input
//                       type="text"
//                       className="form-controls"
//                       placeholder="Enter Job Title"
//                     />
//                   </td>
//                   <td>
//                     <input
//                       type="text"
//                       className="form-controls"
//                       placeholder="Enter Job Title"
//                     />
//                   </td>
//                   <td>
//                     <input
//                       type="text"
//                       className="form-controls"
//                       placeholder="Enter Job Title"
//                     />
//                   </td>
//                   {/* <td>
//                 <button>Filter</button>
//               </td>
//               <td>
//                 <button>Reset</button>
//               </td> */}
//                 </tr>
//                 <tr>
//                   <td>
//                     <Link className="nav-link" to="/sapisu">
//                       SAP ISU Module
//                     </Link>
//                     {/* <a href="#">SAP ISU Module</a> */}
//                   </td>
//                   <td>Pune, MH, IN</td>
//                   <td>Sep 3, 2024</td>
//                 </tr>
//                 <tr>
//                   <td>
//                     <Link className="nav-link" to="/sapisu">
//                       SAP ISU Module
//                     </Link>
//                   </td>
//                   <td>Pune, MH, IN</td>
//                   <td>Sep 3, 2024</td>
//                 </tr>
//                 <tr>
//                   <td>
//                     <Link className="nav-link" to="/sapisu">
//                       SAP ISU Module
//                     </Link>
//                   </td>
//                   <td>Pune, IN</td>
//                   <td>Sep 3, 2024</td>
//                 </tr>
//                 <tr>
//                   <td>
//                     <Link className="nav-link" to="/sapisu">
//                       SAP ISU Module
//                     </Link>
//                   </td>
//                   <td>Pune, MH, IN</td>
//                   <td>Sep 3, 2024</td>
//                 </tr>
//                 <tr>
//                   <td>
//                     <Link className="nav-link" to="/sapisu">
//                       SAP ISU Module
//                     </Link>
//                   </td>
//                   <td>Pune, MH, IN</td>
//                   <td>Sep 3, 2024</td>
//                 </tr>
//                 <tr>
//                   <td>
//                     <Link className="nav-link" to="/sapisu">
//                       SAP ISU Module
//                     </Link>
//                   </td>
//                   <td>Pune, MH, IN</td>
//                   <td>Sep 3, 2024</td>
//                 </tr>
//                 <tr>
//                   <td>
//                     <Link className="nav-link" to="/sapisu">
//                       SAP ISU Module
//                     </Link>
//                   </td>
//                   <td>Pune, IN</td>
//                   <td>Sep 3, 2024</td>
//                 </tr>
//               </tbody>
//             </table>
//           </div>
//           <div className="col-md-2">
//           <button className="filterbtn">Filter</button>
//             <button className="resetbtn">Reset</button>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };

// export default Career;

// import React, { useState } from "react";
// import { Link } from "react-router-dom";

// const Career = () => {
//   // Sample job data
//   const initialJobs = [
//     { title: "SAP ISU Module", location: "Pune, MH, IN", date: "Sep 3, 2024" },
//     { title: "React Developer", location: "Mumbai, MH, IN", date: "Sep 4, 2024" },
//     { title: "Fullstack Developer", location: "Bangalore, KA, IN", date: "Sep 5, 2024" },
//     { title: "SAP ABAP Consultant", location: "Pune, MH, IN", date: "Sep 6, 2024" },
//     { title: "UI/UX Designer", location: "Chennai, TN, IN", date: "Sep 7, 2024" },
//     { title: "Project Manager", location: "Hyderabad, TS, IN", date: "Sep 8, 2024" },
//   ];

//   // State to manage job list and search filters
//   const [jobs, setJobs] = useState(initialJobs);
//   const [searchTitle, setSearchTitle] = useState("");
//   const [searchLocation, setSearchLocation] = useState("");

//   // Filtered job list based on search input
//   const handleSearch = () => {
//     const filteredJobs = initialJobs.filter((job) => {
//       const isTitleMatch = job.title.toLowerCase().includes(searchTitle.toLowerCase());
//       const isLocationMatch = job.location.toLowerCase().includes(searchLocation.toLowerCase());
//       return isTitleMatch && isLocationMatch;
//     });
//     setJobs(filteredJobs);
//   };

//   // Reset filters and display all jobs
//   const handleReset = () => {
//     setSearchTitle("");
//     setSearchLocation("");
//     setJobs(initialJobs);
//   };

//   return (
//     <>
//       <div className="container-fluids careers">
//         <h1>Career at Bestowal</h1>
//       </div>

//       <div className="container career">
//         <div className="search-section text-white">
//           <div className="row careerrow">
//             <div className="col-md-5">
//               <input
//                 type="text"
//                 className="form-control"
//                 placeholder="Enter Job Title"
//                 value={searchTitle}
//                 onChange={(e) => setSearchTitle(e.target.value)}
//               />
//             </div>

//             <div className="col-md-5">
//               <select
//                 className="form-control"
//                 value={searchLocation}
//                 onChange={(e) => setSearchLocation(e.target.value)}
//               >
//                 <option value="">All Locations</option>
//                 <option value="Pune, MH, IN">Pune, MH, IN</option>
//                 <option value="Mumbai, MH, IN">Mumbai, MH, IN</option>
//                 <option value="Bangalore, KA, IN">Bangalore, KA, IN</option>
//                 <option value="Chennai, TN, IN">Chennai, TN, IN</option>
//                 <option value="Hyderabad, TS, IN">Hyderabad, TS, IN</option>
//               </select>
//             </div>

//             <div className="col-md-2">
//               <button className="careerbutton" onClick={handleSearch}>
//                 Search Jobs
//               </button>
//             </div>
//           </div>
//         </div>

//         <div className="d-flex justify-content-between align-items-center mb-3" style={{ padding: "50px 0px" }}>
//           <div className="ms-auto">
//             <button className="resetbtn" onClick={handleReset}>
//               Reset
//             </button>
//           </div>
//         </div>
//       </div>

//       <div className="container jobtable">
//         <div className="row jobtablerow">
//           <div className="col-md-12">
//             <table className="table job-header">
//               <thead>
//                 <tr>
//                   <th>Title</th>
//                   <th>Location</th>
//                   <th>Date</th>
//                 </tr>
//               </thead>
//               <tbody>
//                 {jobs.map((job, index) => (
//                   <tr key={index}>
//                     <td>
//                       <Link className="nav-link" to={`/job/${job.title}`}>
//                         {job.title}
//                       </Link>
//                     </td>
//                     <td>{job.location}</td>
//                     <td>{job.date}</td>
//                   </tr>
//                 ))}
//               </tbody>
//             </table>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };

// export default Career;
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

const Career = () => {
  const initialJobs = [
    { title: "SAP ISU Module", location: "Pune, MH, IN", date: "Sep 3, 2024" },
    { title: "React Developer", location: "Mumbai, MH, IN", date: "Sep 4, 2024" },
    { title: "Fullstack Developer", location: "Bangalore, KA, IN", date: "Sep 5, 2024" },
    { title: "SAP ABAP Consultant", location: "Pune, MH, IN", date: "Sep 6, 2024" },
    { title: "UI/UX Designer", location: "Chennai, TN, IN", date: "Sep 7, 2024" },
    { title: "Project Manager", location: "Hyderabad, TS, IN", date: "Sep 8, 2024" },
    { title: "Software Engineer", location: "Delhi, DL, IN", date: "Sep 9, 2024" },
    { title: "QA Tester", location: "Noida, UP, IN", date: "Sep 10, 2024" },
    { title: "SAP IBP", location: "Mahashtra, Pune, IN", date: "Sep 10, 2024" },
    { title: "SAP ABAP", location: "Mahashtra, Mumbai, IN", date: "Sep 10, 2024" },
  ];

  const [jobs, setJobs] = useState(initialJobs);
  const [searchTitle, setSearchTitle] = useState("");
  const [searchLocation, setSearchLocation] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const jobsPerPage = 3;

  const navigate = useNavigate(); // Using useNavigate to programmatically navigate

  const handleSearch = () => {
    const filteredJobs = initialJobs.filter((job) => {
      const isTitleMatch = job.title.toLowerCase().includes(searchTitle.toLowerCase());
      const isLocationMatch = job.location.toLowerCase().includes(searchLocation.toLowerCase());
      return isTitleMatch && isLocationMatch;
    });
    setJobs(filteredJobs);
    setCurrentPage(1); // Reset to the first page when search is performed
  };

  const handleReset = () => {
    setSearchTitle("");
    setSearchLocation("");
    setJobs(initialJobs);
    setCurrentPage(1);
  };

  const handleJobClick = (jobTitle) => {
    switch (jobTitle.toLowerCase()) {
      case "sap isu module":
        navigate("/sapisu"); // Navigate to the SAP ISU route
        break;
      case "react developer":
        navigate("/react-developer"); // Navigate to the React Developer route
        break;
      case "fullstack developer":
        navigate("/fullstack-developer"); // Navigate to the Fullstack Developer route
        break;
      // Add more cases for other job titles as needed
      default:
        const formattedTitle = jobTitle.replace(/\s+/g, "-").toLowerCase();
        navigate(`/job/${formattedTitle}`); // Fallback for other job titles
    }
  };
  

  const indexOfLastJob = currentPage * jobsPerPage;
  const indexOfFirstJob = indexOfLastJob - jobsPerPage;
  const currentJobs = jobs.slice(indexOfFirstJob, indexOfLastJob);

  return (
    <>
      <div className="container-fluids careers">
        <h1>Career at Bestowal</h1>
      </div>

      <div className="container career">
        <div className="search-section text-white">
          <div className="row careerrow">
            <div className="col-md-4">
              <input
                type="text"
                className="form-control"
                placeholder="Enter Job Title"
                value={searchTitle}
                onChange={(e) => setSearchTitle(e.target.value)}
              />
            </div>

            <div className="col-md-4">
              <select
                className="form-control"
                value={searchLocation}
                onChange={(e) => setSearchLocation(e.target.value)}
              >
                <option value="">All Locations</option>
                <option value="Pune, MH, IN">Pune, MH, IN</option>
                <option value="Mumbai, MH, IN">Mumbai, MH, IN</option>
                <option value="Bangalore, KA, IN">Bangalore, KA, IN</option>
                <option value="Chennai, TN, IN">Chennai, TN, IN</option>
                <option value="Hyderabad, TS, IN">Hyderabad, TS, IN</option>
                <option value="Delhi, DL, IN">Delhi, DL, IN</option>
                <option value="Noida, UP, IN">Noida, UP, IN</option>
              </select>
            </div>

            <div className="col-md-2">
              <button className="careerbutton" onClick={handleSearch}>
                Search Jobs
              </button>
            </div>
            <div className="col-md-2">
            <button className="resetbtn" onClick={handleReset}>
              Reset
            </button>
            </div>
          </div>
        </div>

        <div className="d-flex justify-content-between align-items-center mb-3" style={{ padding: "50px 0px" }}>
          <div className="ms-auto">
          <nav aria-label="Job pagination">
                <ul className="pagination mb-0">
                  <li className="page-item">
                    <button
                      className="page-link"
                      onClick={() => setCurrentPage(currentPage - 1)}
                      disabled={currentPage === 1}
                    >
                      «
                    </button>
                  </li>
                  {[...Array(Math.ceil(jobs.length / jobsPerPage)).keys()].map((pageNumber) => (
                    <li
                      key={pageNumber + 1}
                      className={`page-item ${currentPage === pageNumber + 1 ? "active" : ""}`}
                    >
                      <button className="page-link" onClick={() => setCurrentPage(pageNumber + 1)}>
                        {pageNumber + 1}
                      </button>
                    </li>
                  ))}
                  <li className="page-item">
                    <button
                      className="page-link"
                      onClick={() => setCurrentPage(currentPage + 1)}
                      disabled={currentPage === Math.ceil(jobs.length / jobsPerPage)}
                    >
                      »
                    </button>
                  </li>
                </ul>
              </nav>
          </div>
        </div>

        <div className="container jobtable">
          <div className="row jobtablerow">
            <div className="col-md-12">
              <table className="table job-header">
                <thead>
                  <tr>
                    <th>Title</th>
                    <th>Location</th>
                    <th>Date</th>
                  </tr>
                </thead>
                <tbody>
                  {currentJobs.map((job, index) => (
                    <tr key={index} onClick={() => handleJobClick(job.title)} style={{ cursor: "pointer" }}>
                      <td>{job.title}</td>
                      <td>{job.location}</td>
                      <td>{job.date}</td>
                    </tr>
                  ))}
                </tbody>
              </table>


            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Career;
