import React from "react";
import oip from "./Images/OIP.jpeg";
import SAPIBP from "./Images/SAPIBP1.png";
import SAPAPO from "./Images/SAPAPO.jpeg";
import SAPECC from "./Images/SAPECC.png";
import SAPABAP from "./Images/SAPABAP.jpeg";
import Conclusion from "./Images/Digital-transformation.png";
const components = () => {
  return (
    <>
      <div className="container native1">
        <div className="row nativerow">
          <div className="col-md-4">
            <img src={oip} />
          </div>
          <div className="col-md-8">
          <h2>Bestowal Systems And Services</h2>
            <p>
              From concept to deployment, streamline your engineering workflow.
            </p>
          </div>
        </div>
      </div>
      <div className="container-fluid nativecolor">
        <div className="container process">
          <h2>
            Transforming Demand Planning for a Leading Electronics Company in
            the USA
          </h2>
          <div className="container">
            <section class="client-overview">
              <h3>Client Overview</h3>
              <p>
                Our client is a leading electronics company based in the USA,
                renowned for its innovative products and cutting-edge
                technology.
              </p>
            </section>

            <section class="technologies-used">
              <h3>Technologies Used</h3>
              <ul>
                <li>SAP IBP (Integrated Business Planning)</li>
                <li>SAP APO (Advanced Planning and Optimization)</li>
                <li>SAP ECC (ERP Central Component)</li>
                <li>ABAP (Advanced Business Application Programming)</li>
              </ul>
            </section>
          </div>
          <div className="container">
            <section class="key-challenges">
              <h3>Key Challenges</h3>
              <ul>
                <li>
                  <strong>Multiple Priorities:</strong> The client had to
                  balance ongoing projects with a significant migration project.
                </li>
                <li>
                  <strong>Fragmented Planning Landscape:</strong> Each business
                  unit relied on multiple platforms, resulting in a fragmented
                  planning process.
                </li>
                <li>
                  <strong>Inefficiencies:</strong> There were inefficiencies in
                  timing, data movement, and system inconsistencies, leading to
                  delays and increased maintenance costs.
                </li>
                <li>
                  <strong>Standardization Needs:</strong> The client needed to
                  support a standardized, global business process to drive KPI
                  improvements, including forecast accuracy and inventory cost
                  reductions.
                </li>
              </ul>
            </section>
          </div>
          <div className="container">
            <section class="solution-implementation">
              <h3>Solution and Implementation</h3>
              <p>
                Our approach focused on addressing these challenges through a
                comprehensive migration and integration plan:
              </p>
              <ul>
                <li>
                  <strong>Unified Demand Planning:</strong> We streamlined and
                  unified the demand planning process across all functions
                  within SAP IBP, creating a cohesive planning environment.
                </li>
                <li>
                  <strong>Consolidation of Systems:</strong> We simplified the
                  demand planning process by transitioning from multiple legacy
                  systems to a single, standardized application. This
                  "one-stop-shop" solution catered to all planning needs.
                </li>
                <li>
                  <strong>Standard Process Adoption:</strong> We completed the
                  migration of old custom processes to standardized processes,
                  ensuring consistency and reliability.
                </li>
                <li>
                  <strong>Inventory Management Improvements:</strong> Our
                  solution significantly lowered inventory carrying costs and
                  reduced the amount of aging and obsolete inventory.
                </li>
              </ul>
            </section>
          </div>
          <div className="container">
            <section class="value-delivered">
              <h3>Value Delivered</h3>
              <ul>
                <li>
                  <strong>Streamlined Processes:</strong> The demand planning
                  process became more efficient and cohesive, enhancing overall
                  functionality.
                </li>
                <li>
                  <strong>Cost Reduction:</strong> Consolidating systems and
                  improved inventory management led to lower maintenance costs
                  and reduced inventory carrying costs.
                </li>
                <li>
                  <strong>Enhanced Accuracy:</strong> The standardized processes
                  improved forecast accuracy, contributing to better business
                  outcomes.
                </li>
                <li>
                  <strong>Operational Efficiency:</strong> The client
                  experienced reduced delays and inconsistencies, leading to
                  more reliable operations.
                </li>
              </ul>
            </section>
          </div>
        </div>
      </div>
      <div className="container native1">
        <div className="row nativerow">
          <div className="col-md-6">
            <img src={Conclusion} width={450}/>
          </div>
          <div className="col-md-6">
          <h2>Conclusion</h2>
            <p>
            Through strategic planning and execution, we transformed the client's demand planning process, delivering a robust, efficient, and cost-effective solution. This case study exemplifies our commitment to driving operational excellence and delivering tangible business benefits for our clients.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default components;
