import React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import TCss from "./Images/TCss.png";
const TCApp = () => {
  const [activeTab, setActiveTab] = useState("All");
  const navigate = useNavigate();
  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };
  const goBack = () => {
    navigate(-1);
  };
  return (
    <>
      <div className="container tabbing">
        <ul className="nav nav-tabs">
          <li className="tab-item">
            <button
              className="back-button"
              onClick={goBack}
              style={{
                color: "#fff",
                position: "absolute",
                left: "0%",
                marginRight: "20px",
                top: "21%",
                transform: "translateX(-460%)",
              }}
            >
              Back
            </button>
          </li>
          {[
            "All",
            "React",
            "Wordpress",
            "PHP",
            "E-Commerce",
            "CMS",
          ].map((tab) => (
            <li className="tab-item" key={tab}>
              <a
                className={`tab-link ${activeTab == tab ? "active" : ""}`}
                href="#"
                data-filter={tab}
                onClick={() => handleTabClick(tab)}
              >
                {tab}
              </a>
            </li>
          ))}
        </ul>
      </div>
      <hr className="full-width-hr" />
      <div className="container-fluid bestotabbing">
        <div className="container bestotab">
          <h1>Besto TC App</h1>
          <br />
          <p>
            <b>Technology</b>:-&nbsp;React Native
          </p>
          <br />
          <p>
            <b>Used Skills</b>:-&nbsp;PHP,HTML5,CSS3,jQuery,Responsive,UI
          </p>
        </div>
      </div>
      <div className="container tabimages">
        <img src={TCss}/>
      </div>
    </>
  );
};
export default TCApp;
