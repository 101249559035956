import React from 'react'
import { Carousel, Card } from "react-bootstrap";
import reviews from "./reviews";
const Action = () => {
  return (
    <>
     <div className="dd1">
      <div>
        <h1 className="reviews-h1">Reviews</h1>
        <Carousel>
          {reviews.map((review, index) => (
            <Carousel.Item key={index}>
              <Card className="cardClass">
                <Card.Img
                  className="cardimg"
                  variant="top"
                  src={review.image}
                />
                <div className="d1">
                  <p className="contentStyle">{review.content}</p>
                  <p className="nameStyle">{review.author}</p>
                  <p className="companyStyle">{review.company}</p>
                </div>
              </Card>
            </Carousel.Item>
          ))}
        </Carousel>
      </div>
    </div>
    </>
  )
}

export default Action
