import jondoeImage from "./Images/jondoe.png"; // Import image
import rajivmehataImage from "./Images/rajivmehata.png";
import jshuklaImage from "./Images/jshukla.png";
const reviews = [
    {
      itemtoshow: 1,
      image: jondoeImage, // Use imported image
      content: (
        <>
          Working with Bestowal System was an absolute 
          pleasure. Their teams' expertise and professionalism 
          exceeded our expectations. The software they 
          developed for us has streamlined our processes and 
          significantly improved our efficiency.
        </>
      ),
      author: "john doe",
      company: "Tech Mahindra"
    },
    {
      itemtoshow: 2,
      image: rajivmehataImage, // Use imported image
      content: (
        <>
          I highly recommend Bestowal System to  anyone in need of custom software solutions. Their attention to detail and commitment to delivering high-quality products is unmatched. We're extremely satisfied with the results.
        </>
      ),
      author: "Mr.Rajiv Mehat",
      company: "(Soft Nest)"
    },
    { itemtoshow: 3,
      image: jshuklaImage, // Use imported image
      content: (
        <>
         Thanks to Bestowal System's innovative approach, we were able to bring our idea to life. Their talented  developers took  the time to understand our vision and delivered a product that perfectly aligns with our needs. We couldn't be happier.
        </>
      ),
      author: "Mrs. Jyoti Shukla",
      company: "Tech Mahindra"
     },
     {
      itemtoshow: 4,
      image: jondoeImage, // Use imported image
      content: (
        <>
           Working with Bestowal System was an absolute 
          pleasure. Their teams' expertise and professionalism 
          exceeded our expectations. The software they 
          developed for us has streamlined our processes and 
          significantly improved our efficiency.
        </>
      ),
      author: "john doe",
      company: "Tech Mahindra"
     },

     { 
      itemtoshow: 5,
      image: rajivmehataImage, // Use imported image
      content: (
        <>
          I highly recommend Bestowal System to  anyone in need of custom software solutions. Their attention to detail and commitment  to delivering high-quality products is unmatched. We're extremely satisfied with the results.
        </>
      ),
      author: "Mr.Rajiv Mehat",
      company: "(Soft Nest)"
     },


     { itemtoshow: 6,
      image: jshuklaImage, // Use imported image
      content: (
        <>
          Thanks to Bestowal System's innovative approach, we were able to bring our idea to life. Their talented  developers took  the time to understand our vision and delivered a product that perfectly aligns with our needs. We couldn't be happier.
        </>
      ),
      author: "Mrs. Jyoti Shukla",
      company: "Tech Mahindra"
     }
  ];
  
  export default reviews;