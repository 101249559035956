import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import {BrowserRouter} from 'react-router-dom'

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <BrowserRouter>
    <App />
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals



// <div className="container10">
//         <div className="main10">
//         <p className="git1">Get In Touch</p>
//         <div className="inputdetails">
//           <div className="banner1"></div>
//           <div className="containers1">
//             <div class="input-container">
//               <label for="fname" className="fsn">
//                 First Name
//               </label>
//               <input
//                 type="text"
//                 id="fname"
//                 name="firstname"
//                 placeholder="Your name.."
//               />
//             </div>

//             <div class="input-container">
//               <label for="fname" className="fsn">
//                 Services
//               </label>
//               <input
//                 type="text"
//                 id="fname"
//                 name="firstname"
//                 placeholder="Your name.."
//               />
//             </div>

//             <div class="input-container">
//               <label for="fname" className="fsn">
//                 Email
//               </label>
//               <input
//                 type="text"
//                 id="fname"
//                 name="firstname"
//                 placeholder="Your name.."
//               />
//             </div>

//             <div class="input-container">
//               <label for="fname" className="fsn">
//                 Mobile No
//               </label>
//               <input
//                 type="text"
//                 id="fname"
//                 name="firstname"
//                 placeholder="Your name.."
//               />
//             </div>

//             <div class="input-container">
//               <label for="subject" class="abc1">
//                 Message
//               </label>
//               <textarea
//                 id="subject"
//                 name="subject"
//                 placeholder="Write something.."
//               ></textarea>
//             </div>

//             {/* <div class="input-container">
//               <input type="submit" value="Submit" />  <Image src={logo11} className="img11" />
//             </div> */}
//             <button class="but11" onClick={submit}>
//               Submit <Image src={logo11} className="img11" />
//             </button>
//             <div class="arrow-container">
             
//             </div>
//           </div>
//         </div>
//         </div>
//       </div>