import React from "react";
import { Link } from "react-router-dom";
import {useNavigate} from 'react-router-dom'
const SAPISU = () => {
  const navigate=useNavigate();
  const goBack=()=>{
    navigate(-1);
  }
  return (
    <>
      <div class="container career">
        <div class="search-section">
          <div class="row careerrow">
            <div class="col-md-5">
              <input
                type="text"
                className="form-control"
                placeholder="Enter Job Title"
              />
            </div>
            <div class="col-md-5">
              <input
                type="text"
                className="form-control"
                placeholder="Locations"
              />
            </div>

            <div class="col-md-2">
              <button className="careerbutton">Search Jobs</button>
            </div>
          </div>
        </div>

        {/* <div class="alert-section my-3 d-flex align-items-center">
            <label for="alert-frequency" class="me-2">Select how often (in days) to receive an alert:</label>
            <select id="alert-frequency" class="form-select w-auto me-3">
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
            </select>
            <button class="btn btn-outline-primary">Create Alert</button>
        </div> */}
        {/* <div class="alert-section my-3 d-flex align-items-center">
    <label for="alert-frequency" class="me-2">Select how often (in days) to receive an alert:</label>
    
    <div class="number-input">
        <button class="decrement">-</button>
        <input id="alert-frequency" type="number" value="1" min="1" max="10"/>
        <button class="increment">+</button>
    </div>
    
    <button class="btn btn-outline-primary ms-3">Create Alert</button>
</div> */}

        {/* <div class="d-flex justify-content-between align-items-center mb-3">

    <div class="alert-section d-flex align-items-center">
        <label for="alert-frequency" class="me-2">Select how often (in days) to receive an alert:</label>
        
        <div class="number-input">
            <button class="decrement">-</button>
            <input id="alert-frequency" class="form-control" type="number" value="1" min="1" max="10"/>
            <button class="increment">+</button>
        </div>
        
        <button class="btn btn-outline-primary ms-3">Create Alert</button>
    </div>

   
    <nav aria-label="Job pagination">
        <ul class="pagination mb-0">
            <li class="page-item"><a class="page-link" href="#">«</a></li>
            <li class="page-item"><a class="page-link" href="#">1</a></li>
            <li class="page-item"><a class="page-link" href="#">2</a></li>
            <li class="page-item"><a class="page-link" href="#">3</a></li>
            <li class="page-item"><a class="page-link" href="#">4</a></li>
            <li class="page-item"><a class="page-link" href="#">5</a></li>
            <li class="page-item"><a class="page-link" href="#">»</a></li>
        </ul>
    </nav>
</div> */}
        <div
          className="d-flex justify-content-between align-items-center mb-3"
          style={{ padding: "100px 0px" }}
        >
          {/* If you need the alert section, uncomment it and adjust accordingly */}
          {/* <div class="alert-section d-flex align-items-center">
        <label for="alert-frequency" class="me-2">Select how often (in days) to receive an alert:</label>
        
        <div class="number-input">
            <button class="decrement">-</button>
            <input id="alert-frequency" class="form-control" type="number" value="1" min="1" max="10"/>
            <button class="increment">+</button>
        </div>
        
        <button class="btn btn-outline-primary ms-3">Create Alert</button>
    </div> */}

          <div class="ms-auto">
            <button className="back-button" onClick={goBack}>Back</button>
            <nav aria-label="Job pagination">
              <ul class="pagination mb-0">
                <li class="page-item">
                  <a class="page-link" href="#">
                    «
                  </a>
                </li>
                <li class="page-item">
                  <a class="page-link" href="#">
                    1
                  </a>
                </li>
                <li class="page-item">
                  <a class="page-link" href="#">
                    2
                  </a>
                </li>
                <li class="page-item">
                  <a class="page-link" href="#">
                    3
                  </a>
                </li>
                <li class="page-item">
                  <a class="page-link" href="#">
                    4
                  </a>
                </li>
                <li class="page-item">
                  <a class="page-link" href="#">
                    5
                  </a>
                </li>
                <li class="page-item">
                  <a class="page-link" href="#">
                    »
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
      <div className="container careerobjective">
        <h1>Sr. Consultant-ServiceNow Job</h1>
        <h3>
          <strong>Date</strong>: sep 16,2024
        </h3>
        <h3>
          <strong>Job Requisition Id</strong>:58693
        </h3>
        <h3>
          <strong>Date</strong>: Pune, MH,In
        </h3>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas
          sagittis, mauris nec ultrices ultrices, nisl dolor pretium tellus, in
          tempor nunc est vel est. Donec interdum tortor quis lacus molestie
          porta. Aenean maximus orci eu ante congue auctor. Maecenas ac lobortis
          justo. Duis ut sodales mi. Nullam at dui arcu. Donec ut posuere risus,
          at lobortis lorem. Maecenas vitae ullamcorper velit. Nulla elementum,
          orci ut sodales consectetur, urna urna lacinia ligula, et dapibus
          turpis dolor vel ex. Aliquam nulla nisl, tincidunt vitae malesuada sit
          amet, convallis sit amet ligula.
          <br />
          <br />
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas
          sagittis, mauris nec ultrices ultrices, nisl dolor pretium tellus, in
          tempor nunc est vel est. Donec interdum tortor quis lacus molestie
          porta.{" "}
        </p>
        <div className="applybutton">
          <Link className="nav-link" to="/login">
          <button>
            Apply Now
            {/* <span class="material-symbols-outlined">keyboard_arrow_down</span> */}
          </button>
          </Link>
        </div>
      </div>
    </>
  );
};

export default SAPISU;
