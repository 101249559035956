// import React from 'react'
// import contact from "./Images/img-contact.png";
// import user from "./Images/ic-user.png";
// import company from "./Images/ic-company.png";
// import email from "./Images/ic-email.png";
// import message from "./Images/ic-message.png";
// import phone from "./Images/ic-phone.png";
// import services from "./Images/ic-services.png";
// const Contact = () => {
//   return (
//     <>
//           <div className="container-fluid contact-section" id="contact-section">
//         <div className="container">
//           <div className="row align-items-center">
//             <div className="col-md-8">
//               <img src={contact} alt="Contact" title="Contact US" className="img-fluid" />
//             </div>
//             <div className="col-md-4">
//               <h2 className="contact-header">Get In Touch</h2>
//               <p className="contact-description">Please fill the below form.</p>
//               <form>
//                 <div className="">
//                   <div className="input-group">
//                     <span className="input-group-text input-icon">
//                       <img src={user} alt="User Icon" title="user"/>
//                     </span>
//                     <input
//                       type="text"
//                       className="form-control inputStyle"
//                       placeholder="Your Name"
//                       required
//                     />
//                   </div>
//                 </div>
//                 {/* <div className="mb-4">
//             <div className="input-group">
//               <span className="input-group-text input-icon">
//                 <img src={services} alt="Services Icon" />
//               </span>
//               <select
//                 className="form-control inputStyle select-style"
//                 required
//               >
//                 <option value="">Select a Service</option>
//                 <option value="web-development">Web Development</option>
//                 <option value="app-development">App Development</option>
//                 <option value="seo-services">SEO Services</option>
//                 <option value="cloud-solutions">Cloud Solutions</option>
//               </select>
//               <span className="input-group-text input-icon">
//               <IoIosArrowDown className="arrow-icon" />
//               </span>
//             </div>
//           </div> */}
//                 {/*          <div className="mb-4">
//   <div className="input-group">
//     <span className="input-group-text input-icon">
//       <img src={services} alt="Services Icon" />
//     </span>
//     <select className="form-control inputStyle select-style" required>
//       <option value="">Select a Service</option>
//       <option value="web-development">Web Development</option>
//       <option value="app-development">App Development</option>
//       <option value="seo-services">SEO Services</option>
//       <option value="cloud-solutions">Cloud Solutions</option>
//     </select>
//     <span className="input-group-text input-icon">
//       <IoIosArrowDown className="arrow-icon" />
//     </span>
//   </div>
// </div> */}
//                 <div className="">
//                   <div className="input-group" style={{ flexWrap: "nowrap" }}>
//                     <span className="input-group-text input-icon">
//                       <img src={services} alt="Services Icon" title="Services"/>
//                     </span>
//                     <select className="inputStyle select-style" required>
//                       <option value="">Services</option>
//                       <option value="web-development">Web Development</option>
//                       <option value="app-development">App Development</option>
//                       <option value="seo-services">SEO Services</option>
//                       <option value="cloud-solutions">Cloud Solutions</option>
//                     </select>
//                   </div>
//                 </div>

//                 {/* <div className="mb-4">
//   <div className="input-group">
//     <span className="input-group-text input-icon">
//       <img src={services} alt="Services Icon" />
//     </span>
//     <div className="custom-select-wrapper">
//       <select
//         className="form-control inputStyle select-style"
//         required
//       >
//         <option value="">Select a Service</option>
//         <option value="web-development">Web Development</option>
//         <option value="app-development">App Development</option>
//         <option value="seo-services">SEO Services</option>
//         <option value="cloud-solutions">Cloud Solutions</option>
//       </select>
//       <span className="custom-dropdown-icon">
//         <IoIosArrowDown />
//       </span>
//     </div>
//   </div>
// </div> */}

//                 <div className="">
//                   <div className="input-group">
//                     <span className="input-group-text input-icon">
//                       <img src={email} alt="Email Icon" title="Email"/>
//                     </span>
//                     <input
//                       type="email"
//                       className="form-control inputStyle"
//                       placeholder="Email"
//                       required
//                     />
//                   </div>
//                 </div>

//                 <div className="">
//                   <div className="input-group">
//                     <span className="input-group-text input-icon">
//                       <img src={phone} alt="Phone Icon" title="phone"/>
//                     </span>
//                     <input
//                       type="tel"
//                       className="form-control inputStyle"
//                       placeholder="Phone"
//                       required
//                       pattern="[0-9]{10}"
//                       title="Please enter a valid 10-digit phone number"
//                     />
//                   </div>
//                 </div>

//                 <div className="">
//                   <div className="input-group">
//                     <span className="input-group-text input-icon">
//                       <img src={company} alt="Company Icon" title="company" />
//                     </span>
//                     <input
//                       type="text"
//                       className="form-control inputStyle"
//                       placeholder="Company"
//                       required
//                     />
//                   </div>
//                 </div>

//                 <div className="">
//                   <div className="input-group">
//                     <span className="input-group-text input-icon1">
//                       <img src={message} alt="Message Icon" title="message" className="image" />
//                     </span>
//                     <textarea
//                       className="form-control inputStyle1"
//                       rows="3"
//                       placeholder="Message"
//                       required
//                     ></textarea>
//                   </div>
//                 </div>

//                 <button type="submit" className="btn btn-primary button3"  alt="Submit" title="Submit">
//                   Submit
//                 </button>
//               </form>
//             </div>
//           </div>
//         </div>
//       </div>
//     </>
//   )
// }

// export default Contact
import React from 'react';
import contact from "./Images/img-contact.png";
import user from "./Images/ic-user.png"; // User icon
import company from "./Images/ic-company.png"; // Company icon
import email from "./Images/ic-email.png"; // Email icon
import message from "./Images/ic-message.png"; // Message icon
import phone from "./Images/ic-phone.png"; // Phone icon
import servicesIcon from "./Images/ic-services.png"; // Services icon
import ContactForm from './ContactForm'; // Import the reusable ContactForm component

const Contact = () => {
  // Define service options to be passed as a prop
  const services = [
    { label: 'Web Development', value: 'web-development' },
    { label: 'App Development', value: 'app-development' },
    { label: 'SEO Services', value: 'seo-services' },
    { label: 'Cloud Solutions', value: 'cloud-solutions' },
  ];

  // Handle form submission success callback
  const handleFormSubmitSuccess = () => {
    console.log('Form successfully submitted on Contact Page');
  };

  return (
    <>
      <div className="container-fluid contact-section" id="contact-section">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-12">
              {/* Use the reusable ContactForm component */}
              <ContactForm
                services={services} // Pass service options
                onSubmitSuccess={handleFormSubmitSuccess} // Pass form submit success handler
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Contact;
