import React from "react";
import "./Modal1.css";

const Modal1 = ({ onClose }) => {
  return (
    <div className="modal-overlay">
      <div className="modal-content1">
        <div className="modal-header">
          <h2>Contact US</h2>
          <button onClick={onClose} className="close-button">
            &times;
          </button>
        </div>

        <div className="modal-body">
          <form>
            <label>
              Full Name:
              <input type="text" name="fullName" required />
            </label>
            <label>
              Email:
              <input type="email" name="emailID" required />
            </label>
            <label>
              Mobile Number:
              <input type="tel" name="mobileNumber" required />
            </label>
            <label for="job">
              Services:
              <select name="job" id="job" className="selectOption">
                <option value="Select Services">Select Services</option>
                <option value="Mobile Services ">Mobile Services</option>
                <option value="Web Services">Web Services</option>
                <option value="SAP Services">SAP Services</option>
              </select>
            </label>
            <label>
              Message:
              <input type="text" name="Message" required />
            </label>

            <button type="submit" className="s-btn">
              Submit
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Modal1;
