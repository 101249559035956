import React from "react";
import { useState } from "react";
import Android from "./Images/Android.jpeg";
import { Image } from "react-bootstrap";
import logo11 from "./Images/arrow_outward.png";
import circle from "./Images/check_circle.png";
import IOSImage from "./Images/IOSImage.png";
import group from "./Images/iOS.jpg";
import group1 from "./Images/Group1.png";
import Card from "react-bootstrap/Card";
import WWW from "./Images/WWW.png";
import Rectangle from "./Images/Rectangle.png";
import Note from "./Images/Note.png";
import PC from "./Images/PC.png";
import pay from "./Images/pay.png";
import search from "./Images/search.png";
import search1 from "./Images/search1.png";
import lamp from "./Images/lamp.png";
import curve from "./Images/curve.png";
import goal from "./Images/goal.png";
import Modal1 from "./Modal1";
import iOSAPP from "./Images/iOSApp.jpg"
const IOS = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };
  return (
    <>
      <div className="container native1">
        <div className="row nativerow">
          <div className="col-md-6">
            <img src={group} width={100} />
           
          </div>
          <div className="col-md-6">
            <h2> iOS Development</h2>
          </div>
        </div>
      </div>
      <div className="container-fluid nativecolor">
        <div className="container native">
          <h2>iOS Development Services</h2>
          <p>
            Bestowal Systems & Services, a leading iOS development company, is
            committed to creating top-notch apps for businesses, ensuring a
            seamless user experience on iPhone and iPad. Our focus lies in
            crafting swift, responsive, and power-efficient apps tailored to the
            latest iOS devices. With expertise in Swift and Objective-C, we
            offer personalized development services, including offline
            functionality, push notifications, and API integration. Our team
            follows best practices and employs cutting-edge tools to meet
            clients' unique needs. We provide guided development sessions
            covering essential concepts like design and performance Additionally, we offer marketing and promotion
            services to maximize app visibility. Adhering to agile approaches,
            we ensure timely 
            optimization. 
            {!isExpanded ? (
              <span className="dots">.....</span>
            ) : (
              <span className="more">
                project completion through transparent
            collaboration with clients. Trust us to build scalable, secure
            digital solutions that propel your business into the future through
            iOS application development.
              </span>
            )}
          </p>
          <button className="sapbtn" onClick={() => toggleExpand(0)}>
            {isExpanded ? "Read Less" : "Read More"}
            <span className="material-symbols-outlined">east</span>
          </button>
        </div>
      </div>
      <div className=" container android">
        <h2>Build Powerful Apps with Us</h2>
        <div className="row androidrow">
      <div className="col-md-8">
        <div className="androidframe">
          <div className="CaptioN0">
            <div className="androidinfo">
              <img src={circle} className="circle-img" />
              <h4>Consultation on the Development</h4>
            </div>
            <p>
            Our iOS app developers help businesses determine if an iOS app is the right fit for their needs and select the optimal technology stack.</p>
          </div>
          <div className="CaptioN0">
            <div className="androidinfo">
              <img src={circle} className="circle-img" />
              <h4>UI/UX Design</h4>
            </div>
            <p>
            Our team specializes in creating immersive and memorable iOS app designs that align with Apple's ecosystem, meeting customer expectations with experience-driven design.</p>
          </div>
          <div className="CaptioN0">
            <div className="androidinfo">
              <img src={circle} className="circle-img" />
              <h4>Custom iOS Applications:</h4>
            </div>
            <p>
            Our iPhone app developers excel at crafting scalable iOS solutions tailored to businesses of all sizes and across various sectors, including startups.</p>
            </div>
          <div className="CaptioN0">
            <div className="androidinfo">
              <img src={circle} className="circle-img" />
              <h4>iOS Software Testing:</h4>
            </div>
            <p>
            We prioritize security and performance in our iPhone app development. Using a blend of manual and AI-driven testing, we ensure a bug-free, secure application. </p>
          </div>
          <div className="CaptioN0">
            <div className="androidinfo">
              <img src={circle} className="circle-img" />
              <h4>Cross-Platform Integration:</h4>
            </div>
            <p>
            We specialize in custom iPhone app development, ensuring seamless integration across multiple Apple devices to enhance your brand's presence across all user platforms.
            </p>
          </div>
        </div>
      </div>
      <div className="col-md-4">
        <img src={iOSAPP}/>
      </div>
    </div>
      </div>
      {/* <div className="container digital">
        <h2>Advantages of IOS Mobile Applications</h2>
        <div className="row">
          <div className="col-md-4">
            <Card>
              <span className="digitalspan">
                {" "}
                <Card.Img src={search} />
              </span>
              <Card.Body>
                <Card.Title>Astonishingly Rapid Development</Card.Title>
                <Card.Text>
                Compared to Android apps, iOS apps are developed in a lot less time. The reason for this is that Android apps must be tested across a variety of hardware using various OS versions and screen resolutions. The time and money saved as a result are beneficial.
                </Card.Text>
              </Card.Body>
            </Card>
          </div>
          <div className="col-md-4">
            <Card>
              <span className="digitalspan">
                {" "}
                <Card.Img src={WWW} />
              </span>
              <Card.Body>
                <Card.Title>Enhanced Security</Card.Title>
                <Card.Text>
                The quality requirements set forth by Apple must be met in order for a program to be uploaded to the Apple App Store. This addresses security concerns including data privacy and user identification integrity. Additionally, Apple's data encryption function guards against theft, copying, and data breaches.
                </Card.Text>
              </Card.Body>
            </Card>
          </div>
          <div className="col-md-4">
            <Card>
              <span className="digitalspan">
                {" "}
                <Card.Img src={Rectangle} />
              </span>
              <Card.Body>
                <Card.Title>Enhanced User Experience</Card.Title>
                <Card.Text>
                Users of iOS devices get the finest experiences thanks to the operating system's built-in features. The extensive customer care provided by Apple and the reliable hardware and software utilised in iOS devices both improve the user experience for Apple device owners.
                </Card.Text>
              </Card.Body>
            </Card>
          </div>
          <div className="col-md-4">
            <Card>
              <span className="digitalspan">
                {" "}
                <Card.Img src={Note} />
              </span>
              <Card.Body>
                <Card.Title>Appealing App Interface</Card.Title>
                <Card.Text>
                The controlled environment at Apple makes sure that the UIs produced are pleasant. This helps to improve the user experience. As a result, companies that choose to develop IOS apps stand to benefit in two ways: they can give users a better experience and strengthen their ties with clients.
                </Card.Text>
              </Card.Body>
            </Card>
          </div>
          <div className="col-md-4">
            <Card>
              <span className="digitalspan">
                {" "}
                <Card.Img src={PC} />
              </span>
              <Card.Body>
                <Card.Title>Low Penetration and Easy Testing</Card.Title>
                <Card.Text>
                Operating systems used by different Android devices vary. This makes the testing and development of Android apps more difficult. Because there aren't as many Apple mobile devices available, developers can create and test iOS applications much more quickly.
                </Card.Text>
              </Card.Body>
            </Card>
          </div>
          <div className="col-md-4">
            <Card>
              <span className="digitalspan">
                {" "}
                <Card.Img src={pay} />
              </span>
              <Card.Body>
                <Card.Title>Superior Caliber</Card.Title>
                <Card.Text>
                The effectiveness of a business app can significantly improve a brand's reputation. Strict quality requirements must be met in order for a programmer to be released in the Apple App Store. The Apple App Store only allows top-notch applications.
                </Card.Text>
              </Card.Body>
            </Card>
          </div>
        </div>
      </div> */}
      <div className="fluid-container dgcolor">
        <div className="container process">
          <h2>Solutions for Your Needs</h2>

          <div className="processframe">
            <div className="process1">
              <span className="digitalspan1">
                {" "}
                <img src={search1} className="d111" />
              </span>
              <h4>iPhone app development services</h4>
            </div>
            <div className="process1">
              <span className="digitalspan1">
                {" "}
                <img src={lamp} className="d111" />
              </span>
              <h4>Applications for Apple TV and smart devices</h4>
            </div>
            <div className="process1">
              <span className="digitalspan1">
                {" "}
                <img src={curve} className="d111" />
              </span>
              <h4>iPad application development services</h4>
            </div>
            <div className="process1">
              <span className="digitalspan1">
                {" "}
                <img src={goal} className="d111" />
              </span>
              <h4>Applications for Apple Watch</h4>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default IOS;
