import React from 'react'

const ForgotPassword = () => {
  return (
    <>
      <div className='login'>
        <div className='login-form'>
        <h2>Forgotten Password</h2>
        <h4>
         Please identify your account, and we will email you a link to reset your password.
          </h4>
          <form>
          <div class="input-group">
              <span class="material-symbols-outlined">mail</span>
              <input
                type="text"
                id="email-phone"
                name="email-phone"
                placeholder="Email or Phone Number"
                required
              />
            </div>
            <button type="submit" class="btn-submit">
              Sign In
            </button>
            <button type="submit" class="btn-cancel">
              Cancel
            </button>
          </form>
        </div>
      </div>
    </>
  )
}

export default ForgotPassword
