import React from "react";
import { useState } from "react";
import WP from "./Images/WP.png";
import WP1 from "./Images/WP1.jpg";
const Wordpress = () => {
    const [isExpanded, setIsExpanded] = useState(false);

    const toggleExpand = () => {
      setIsExpanded(!isExpanded);
    };
  return (
    <>
      <div className="container sapmonitoring">
        <div className="row nativerow">
          <div className="col-md-3">
            <img src={WP} />
          </div>
          <div className="col-md-9">
            <h2>Wordpress Development</h2>
          </div>
        </div>
      </div>
      <div className="container-fluid sapcolor">
          <div className="container about">
            <div className="row aboutusrow">
              <div className="col-md-6">
                <img src={WP1} />
              </div>
              <div className="col-md-6">
                <p>
                  WordPress is a popular content management system (CMS) used to
                  create websites and blogs. Launched in 2003, it powers over
                  40% of websites worldwide due to its user-friendly interface
                  and flexibility. WordPress offers thousands of customizable
                  themes and plugins, enabling users to build anything from
                  personal blogs to e-commerce sites. It supports both
                  beginner-friendly features and advanced development options.
                  The platform is open-source, meaning it’s free to use, modify,
                  and distribute. WordPress has a strong community that provides
                  regular updates and support. It is also optimized for SEO and
                  mobile responsiveness WordPress is a versatile and widely used 
                  {!isExpanded ? (
                    <span className="dots">.....</span>
                  ) : (
                    <span className="more">
                      content management system (CMS) that enables users to create and
                      manage websites with ease. It is popular for building
                      everything from simple blogs to complex business websites
                      and e-commerce platforms. With its user-friendly
                      interface, WordPress allows even those with limited
                      technical knowledge to customize their sites using a wide
                      range of themes and plugins. Its open-source nature
                      ensures that it is constantly evolving, with a strong
                      community providing regular updates and support.
                    </span>
                  )}
                </p>
                <button className="sapbtn" onClick={() => toggleExpand(0)}>
                  {isExpanded ? "Read Less" : "Read More"}
                  <span className="material-symbols-outlined">east</span>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="container android">
        <h2>Why Choose Bestowal for WordPress Development</h2>
        <div className="row androidrow1">
          <div className="col-md-6">
            <div className="sapsubinfo">
              <div className="sapsubinfo1">
              <h2>Custom Themes</h2>
                  <p>Unique, responsive designs that capture your brand</p>
                  </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="sapsubinfo">
              <div className="sapsubinfo1">
              <h2>Plugin Development</h2>
                  <p>
                  Tailored plugins to enhance site functionality
</p></div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="sapsubinfo">
              <div className="sapsubinfo1">
              <h2>Maintenance</h2>
                  <p>
                  Reliable Support to keep your site running smoothly
                 </p>
              </div>
            </div>
          </div>   
          <div className="col-md-6">
            <div className="sapsubinfo">
              <div className="sapsubinfo1">
              <h2>E-commerce</h2>
                  <p>
                  Powerful WooCommerce solutions to drives sales
                 </p>
              </div>
            </div>
          </div>  
          <div className="col-md-6">
            <div className="sapsubinfo">
              <div className="sapsubinfo1">
              <h2>SEO Optimization</h2>
                  <p>
                  Boost visibility with effective seo strategies
                 </p>
              </div>
            </div>
          </div>  
          <div className="col-md-6">
            <div className="sapsubinfo">
              <div className="sapsubinfo1">
              <h2>Performance Tuning</h2>
                  <p>
                  Fast Load time and seamless user experience
                 </p>
              </div>
            </div>
          </div>        
        </div>

        </div>
    </>
  );
};

export default Wordpress;
