import React from 'react'
import { Link } from 'react-router-dom'
import BestoTc from "./Images/BestoTC.png";
import BardeInfra from "./Images/BardeInfra.png";
import FYITR from "./Images/FYITR.png";
const Products = () => {
  return (
    <>
          <div className='container-fluid servicess'>
            <div className='container our-services'>
        <h1>Our Products</h1>
        <p>
        &bull;<a href="https://bardeinfra.com/" target="_blank" rel="noopener noreferrer">Barde Infra</a>&bull;
        <a href="http://maikyakaru.com/" target="_blank" rel="noopener noreferrer">Besto Tc</a>&bull;
  <a href="https://www.fileyourincometaxreturn.com/" target="_blank" rel="noopener noreferrer">File Your Income Tax Return</a><br/>
  {/* <a href="/dotnet">Dot Net Development</a>&bull;
  <a href="/php">PHP Development</a><br />
  &bull;<a href="/angular">Angular JS Development</a>&bull;
  <a href="/wordpress">WordPress Development</a> */}
</p>
  
</div>
</div>
      <div class="container services1">
        {/* Row 1 */}
        <div className="row align-items-center" style={{ paddingTop: '50px' }}>
        <div className="col-md-5 order-1 order-md-1">
          <img src={BardeInfra} className="img-fluids" alt="SAP IBP" title="SAP IBP" />
        </div>
        <div className="col-md-7 order-2 order-md-2 text-start">
          <h2>Barde Infra</h2>
          <p>
          Our web development team has developed a website for Barde Infra that showcases its expertise in the interior design industry. The platform is designed to provide a seamless user experience, helping clients explore services, view project portfolios, and engage with the team for customized solutions.
          </p>
          <a href="https://bardeinfra.com/" target="_blank" rel="noopener noreferrer">
  <button className="service-button">
    Know More
  </button>
</a>
        </div>
      </div>

      {/* Row 2 */}
      <div className="row align-items-center" style={{ paddingTop: '50px' }}>
        <div className="col-md-7 order-2 order-md-1 text-end">
        <h2>Besto TC APP</h2>
      <p>
      At TC App, we understand the importance of efficient task management and timely project completion. It provides all the tools you need to enhance team coordination, streamline task tracking, and meet deadlines effectively.   </p>
<a href="http://maikyakaru.com/" target="_blank" rel="noopener noreferrer">
  <button className="service-button">
    Know More
  </button>
</a>
        </div>
        <div className="col-md-5 order-1 order-md-2">
          <img src={BestoTc} className="img-fluids" alt="SAP S/4Hana Implementation" title="SAP S/4Hana Implementation" />
        </div>
      </div>

      {/* Row 3 */}
      <div className="row align-items-center" style={{ paddingTop: '50px' }}>
        <div className="col-md-5 order-1 order-md-1">
          <img src={FYITR} className="img-fluids" alt="SAP S/4 Monitoring and Support" title="SAP S/4 Monitoring and Support" />
        </div>
        <div className="col-md-7 order-2 order-md-2 text-start">
        <h2>File Your Income Tax Return</h2>
      <p>
      File Your Income Tax Return (FYITR) is a user-friendly platform designed to simplify the tax filing process for individuals and businesses. Developed for efficiency, it offers comprehensive tools to guide users through each step of tax filing, ensuring accurate and timely tax filing.   </p>
      <a href="https://www.fileyourincometaxreturn.com/" target="_blank" rel="noopener noreferrer">
  <button className="service-button">
    Know More
  </button>
</a>
        </div>
      </div>
   </div>
    </>
  )
}

export default Products;
