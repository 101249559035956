import React from 'react'
import { Link } from 'react-router-dom'
const CreateAccount = () => {
  return (
    <>
      <div class="container createaccount">
    <div class="form-container">
        <h2>Career Opportunities: Create an Account</h2>
      <p>
        Already a registered user? <Link className='nav-link' to="/login">Please sign in</Link>
      </p>
      <form action="#">
        <div class="input-group">
          <span class="material-symbols-outlined">mail</span>
          <input type="email" placeholder="Email Address" required />
        </div>

        <div class="input-group">
        <span class="material-symbols-outlined">
lock
</span>
          <input type="password" placeholder="Password" required />
          
        </div>

        <div class="password-instructions">
          <ul>
            <li>Password must be at least 8 characters long.</li>
            <li>Password must not be longer than 18 characters.</li>
            <li>Password must contain at least one upper case and lower case letter.</li>
          </ul>
        </div>

        <div class="name-group">
          <div class="input-group">
            <input type="text" placeholder="* First Name" required />
          </div>
          <div class="input-group">
            <input type="text" placeholder="* Last Name" required />
          </div>
        </div>

        <div class="input-group">
          <span class="material-symbols-outlined">call</span>
          <input type="tel" placeholder="Phone Number" />
        </div>
        <Link className='nav-link' to="/applicationform">
        <button type="submit" class="btn-create-account">Create Account</button></Link>
      </form>
      <h5>By continuing, you agree to Bestowal Conditions of Use and Privacy Notice.</h5>
    </div>
    </div>
    </>
  )
}

export default CreateAccount
