import React from 'react'
import Conclusion from "./Images/Digital-transformation.png";
import oip from "./Images/OIP.jpeg";
const Project = () => {
  return (
    <>
       <div className="container native1">
        <div className="row nativerow">
          <div className="col-md-4">
            <img src={oip}/>
          </div>
          <div className="col-md-8">
          <h2>Bestowal Systems And Services</h2>
            <p>
              From concept to deployment, streamline your engineering workflow.
            </p>
          </div>
        </div>
      </div>
      <div className="container-fluid nativecolor">
        <div className="container process">
          <h2>
          Enhancing Forecasting and Planning for a Leading Chemical Company in Singapore
          </h2>
          <div className="container">
            <section class="client-overview">
              <h3>Client Overview</h3>
              <p>
              Our client is a leading chemical company based in Singapore, known for its high-quality products and extensive distribution network.
              </p>
            </section>

            <section class="technologies-used">
              <h3>Technologies Used</h3>
              <ul>
                <li>SAP IBP (Integrated Business Planning)</li>
                <li>SAP APO (Advanced Planning and Optimization)</li>
                <li>SAP ECC (ERP Central Component)</li>
                <li>ABAP (Advanced Business Application Programming)</li>
              </ul>
            </section>
          </div>
          <div className="container">
            <section class="key-challenges">
              <h3>Key Challenges</h3>
              <p>The client faced several significant challenges:</p>
              <ul>
                <li>
                  <strong>	Multiple Business Units: </strong> Coordinating planning across various business units proved complex.
                </li>
                <li>
                  <strong>	Unstable Production Environment: </strong> The existing production environment was unstable and performance was slow.
                </li>
                <li>
                  <strong>	Forecasting Limitations: </strong> Planners were unable to forecast accurately for small distributors due to the absence of suitable templates. The legacy tool required extensive disaggregation steps for forecasting.
                </li>
                <li>
                  <strong>	Need for Standardization: </strong> Implementing IBP Unconstrained Heuristics was essential to standardize the material planning process for the mid to long-term horizon.
                </li>
              </ul>
            </section>
          </div>
          <div className="container">
            <section class="solution-implementation">
              <h3>Solution and Implementation</h3>
              <p>
              Our solution focused on addressing these challenges through the implementation of SAP IBP:
              </p>
              <ul>
                <li>
                  <strong>Simplified Planning Process: </strong> We simplified the planning process across functions and laid the groundwork for adapting the entire Sales & Operations Planning (S&OP) process to utilize SAP IBP.
                </li>
                <li>
                  <strong>Advanced Planning Tools:</strong> SAP IBP provided tools such as Segmentation, Time Series Analysis, and Outlier Corrections, reducing the workload for demand planners and enhancing forecasting accuracy.
                </li>
                <li>
                  <strong>Proactive S&OP Discussions: </strong>The implementation of IBP facilitated proactive discussions, leading to an optimal S&OP process.
                </li>
                <li>
                  <strong>Capacity Issue Anticipation: </strong> The new system enhanced the ability to anticipate potential capacity issues in the long term, improving overall planning and resource management.
                </li>
              </ul>
            </section>
          </div>
          <div className="container">
            <section class="value-delivered">
              <h3>Value Delivered</h3>
              <p>The implementation of our solution brought substantial benefits to the client:</p>
              <ul>
                <li>
                  <strong>Streamlined Processes:</strong> The demand planning
                  process became more efficient and cohesive, enhancing overall
                  functionality.
                </li>
                <li>
                  <strong>Cost Reduction:</strong> Consolidating systems and
                  improved inventory management led to lower maintenance costs
                  and reduced inventory carrying costs.
                </li>
                <li>
                  <strong>Enhanced Accuracy:</strong> The standardized processes
                  improved forecast accuracy, contributing to better business
                  outcomes.
                </li>
                <li>
                  <strong>Operational Efficiency:</strong> The client
                  experienced reduced delays and inconsistencies, leading to
                  more reliable operations.
                </li>
              </ul>
            </section>
          </div>
          {/* 
        <section class="key-challenges">
            <h2>Key Challenges</h2>
            <ul>
                <li><strong>Multiple Priorities:</strong> The client had to balance ongoing projects with a significant migration project.</li>
                <li><strong>Fragmented Planning Landscape:</strong> Each business unit relied on multiple platforms, resulting in a fragmented planning process.</li>
                <li><strong>Inefficiencies:</strong> There were inefficiencies in timing, data movement, and system inconsistencies, leading to delays and increased maintenance costs.</li>
                <li><strong>Standardization Needs:</strong> The client needed to support a standardized, global business process to drive KPI improvements, including forecast accuracy and inventory cost reductions.</li>
            </ul>
        </section>
        
        <section class="solution-implementation">
            <h2>Solution and Implementation</h2>
            <p>Our approach focused on addressing these challenges through a comprehensive migration and integration plan:</p>
            <ol>
                <li><strong>Unified Demand Planning:</strong> We streamlined and unified the demand planning process across all functions within SAP IBP, creating a cohesive planning environment.</li>
                <li><strong>Consolidation of Systems:</strong> We simplified the demand planning process by transitioning from multiple legacy systems to a single, standardized application. This "one-stop-shop" solution catered to all planning needs.</li>
                <li><strong>Standard Process Adoption:</strong> We completed the migration of old custom processes to standardized processes, ensuring consistency and reliability.</li>
                <li><strong>Inventory Management Improvements:</strong> Our solution significantly lowered inventory carrying costs and reduced the amount of aging and obsolete inventory.</li>
            </ol>
        </section>
        
        <section class="value-delivered">
            <h2>Value Delivered</h2>
            <ul>
                <li><strong>Streamlined Processes:</strong> The demand planning process became more efficient and cohesive, enhancing overall functionality.</li>
                <li><strong>Cost Reduction:</strong> Consolidating systems and improved inventory management led to lower maintenance costs and reduced inventory carrying costs.</li>
                <li><strong>Enhanced Accuracy:</strong> The standardized processes improved forecast accuracy, contributing to better business outcomes.</li>
                <li><strong>Operational Efficiency:</strong> The client experienced reduced delays and inconsistencies, leading to more reliable operations.</li>
            </ul>
        </section>
        
        <section class="conclusion">
            <h2>Conclusion</h2>
            <p>Through strategic planning and execution, we transformed the client's demand planning process, delivering a robust, efficient, and cost-effective solution. This case study exemplifies our commitment to driving operational excellence and delivering tangible business benefits for our clients.</p>
        </section> 

          {/* <h2>Navigate Our Work Process</h2>

          <div className="processframe">
            <div className="process1">
           
                {" "}
                <img src={SAPIBP} className="d111" />
          
              <h4>Research</h4>
            </div>
            <div className="process1">
        
                {" "}
                <img src={SAPAPO} className="d111" />
            
              <h4>Find Ideas</h4>
            </div>
            <div className="process1">
             
                {" "}
                <img src={SAPECC} className="d111" />
             
              <h4>Optimization</h4>
            </div>
            <div className="process1">
         
                {" "}
                <img src={SAPABAP} className="d111" />
          
              <h4>Reach Target</h4>
            </div>
          </div> */}
        </div>
      </div>
      <div className="container native1">
        <div className="row nativerow">
          <div className="col-md-6">
            <img src={Conclusion} width={450}/>
          </div>
          <div className="col-md-6">
          <h2>Conclusion</h2>
            <p>
            Through strategic planning and execution, we transformed the client's demand planning process, delivering a robust, efficient, and cost-effective solution. This case study exemplifies our commitment to driving operational excellence and delivering tangible business benefits for our clients.
            </p>
          </div>
        </div>
      </div>
    </>
  )
}

export default Project
