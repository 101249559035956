const review3=[
    {
    itemshow:1,
   title:(<>Financial Management</>),
    content:(
        <>
SAP Business One offers a range of financial management features, including general ledger, accounts payable, accounts receivable, and financial reporting. These features enable businesses to manage their finances more efficiently, automate routine tasks, and reduce errors. SAP Business One also provides a powerful cash flow management module that allows businesses to forecast their cash flow and manage their working capital effectively        </>
    )
},
{
    itemshow:2,
   title:(<>Sales and Customer Relationship Management</>),
    content:(
        <>
With SAP Business One, businesses can manage their entire sales cycle, from lead generation to order fulfilment. The solution offers a range of features to support sales, including sales forecasting, pipeline management, and sales reporting. It also includes a comprehensive customer relationship management (CRM) module that enables businesses to manage their customer interactions, track their sales activities, and improve customer satisfaction.        </>
    )
},
{
    itemshow:3,
    title:(<>Purchasing and Inventory Management</>),
    content:(
        <>
SAP Business One offers a range of features to help businesses manage their purchasing and inventory processes. The solution includes modules for purchase order management, goods receipt management, and inventory management. These features enable businesses to manage their suppliers more effectively, reduce lead times, and optimise their inventory levels.        </>
    )
},{
    itemshow: 4,
    title: (<>Production Management</>),
    content: (
        <>
            SAP Business One includes robust production management capabilities that facilitate efficient planning, scheduling, and monitoring of production processes. It supports both make-to-order and make-to-stock scenarios, offering features for capacity planning, material requirements planning (MRP), and comprehensive production scheduling. This helps businesses optimize resource utilization and meet production deadlines effectively.
        </>
    )
},
{
    itemshow: 5,
    title: (<>Business Intelligence and Reporting</>),
    content: (
        <>
            SAP Business One provides powerful business intelligence (BI) and reporting tools, empowering businesses with real-time insights into their operations. It includes pre-built reports, dashboards, and advanced data visualization tools. The built-in analytics engine supports ad-hoc analysis and allows for the creation of custom reports to meet specific business needs. This enables proactive decision-making based on comprehensive data-driven insights.
        </>
    )
},
{
    itemshow: 6,
    title: (<>Mobility and Accessibility</>),
    content: (
        <>
            SAP Business One enhances accessibility and mobility by enabling users to access critical business information and perform tasks from anywhere, using mobile devices. This capability improves operational agility and responsiveness, allowing businesses to stay connected and productive on-the-go. It ensures that teams can collaborate seamlessly across locations, enhancing overall efficiency and customer responsiveness.
        </>
    )
}
];
export default review3;