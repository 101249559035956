import learn from "./Images/learn.png"; // Import image
// import jvaweb from "./Images/javaweb.png";
// import jvaweb from "./Images/javaweb.png";
// import jvaweb from "./Images/javaweb.png";
// import jvaweb from "./Images/javaweb.png";
// import jvaweb from "./Images/javaweb.png";
import jshuklaImage from "./Images/jshukla.png";
const javaview = [
    {
      itemtoshow: 1,
      image: learn, // Use imported image
      title:(<> Response & Supply Planning</>),
    content:(
        <>
        Matching supply and demand, combining operational and financial planning, and connecting high-level strategic plans to medium- and long-term company plans are all necessary. Run simulations of changes in demand or supply for what-if analysis and scenario comparison to enable quick, well-informed decision-making.
        </>
    )
    },
    {
      itemtoshow: 2,
      image: learn, // Use imported image
      title:(<> Response & Supply Planning</>),
    content:(
        <>
        Matching supply and demand, combining operational and financial planning, and connecting high-level strategic plans to medium- and long-term company plans are all necessary. Run simulations of changes in demand or supply for what-if analysis and scenario comparison to enable quick, well-informed decision-making.
        </>
    )
    },
    { itemtoshow: 3,
        image: learn, // Use imported image
        title:(<> Response & Supply Planning</>),
      content:(
          <>
          Matching supply and demand, combining operational and financial planning, and connecting high-level strategic plans to medium- and long-term company plans are all necessary. Run simulations of changes in demand or supply for what-if analysis and scenario comparison to enable quick, well-informed decision-making.
          </>
      )
      },
     {
      itemtoshow: 4,
      image: learn, // Use imported image
      title:(<> Response & Supply Planning</>),
    content:(
        <>
        Matching supply and demand, combining operational and financial planning, and connecting high-level strategic plans to medium- and long-term company plans are all necessary. Run simulations of changes in demand or supply for what-if analysis and scenario comparison to enable quick, well-informed decision-making.
        </>
    )
    },

     { 
      itemtoshow: 5,
      image: learn, // Use imported image
      title:(<> Response & Supply Planning</>),
    content:(
        <>
        Matching supply and demand, combining operational and financial planning, and connecting high-level strategic plans to medium- and long-term company plans are all necessary. Run simulations of changes in demand or supply for what-if analysis and scenario comparison to enable quick, well-informed decision-making.
        </>
    )
    },


     { itemtoshow: 6,
        image: learn, // Use imported image
        title:(<> Response & Supply Planning</>),
      content:(
          <>
          Matching supply and demand, combining operational and financial planning, and connecting high-level strategic plans to medium- and long-term company plans are all necessary. Run simulations of changes in demand or supply for what-if analysis and scenario comparison to enable quick, well-informed decision-making.
          </>
      )
      },
  ];
  
  export default javaview;