import React from 'react'

const ApplyForm = () => {
  return (
    <>
      <div className="container-fluids careers">
        <h1>Don't miss any opportunity to get <b>hired</b></h1>
      </div>
<div class="container applicationform">
  <form>
    {/* <!-- Personal Information --> */}
    <h3 class="section-title">Personal Information</h3>
    <div class="form-row">
      <div class="form-group col-md-6">
        <input type="text" class="form-control" placeholder="First Name" />
      </div>
      <div class="form-group col-md-6">
        <input type="text" class="form-control" placeholder="Last Name" />
      </div>
      <div class="form-group col-md-6">
        <input type="date" class="form-control" placeholder="Date Of Birth" />
      </div>
      <div class="form-group col-md-6">
        <select class="form-control">
          <option>Select Gender</option>
          <option>Male</option>
          <option>Female</option>
        </select>
      </div>
      <div class="form-group col-md-6">
        <input type="text" class="form-control" placeholder="Got Reference From" />
      </div>
    </div>

    {/* <!-- Contact Information --> */}
    <h3 class="section-title">Contact Information</h3>
    <div class="form-row">
      <div class="form-group col-md-12">
        <input type="text" class="form-control" placeholder="Enter Address" />
      </div>
      <div class="form-group col-md-4">
        <input type="text" class="form-control" placeholder="City" />
      </div>
      <div class="form-group col-md-4">
        <input type="text" class="form-control" placeholder="State" />
      </div>
      <div class="form-group col-md-4">
        <input type="text" class="form-control" placeholder="Zipcode" />
      </div>
      <div class="form-group col-md-6">
        <input type="text" class="form-control" placeholder="Mobile No." />
      </div>
      <div class="form-group col-md-6">
        <input type="text" class="form-control" placeholder="Mobile No. (Alternate)" />
      </div>
    </div>

    {/* <!-- Educational Information --> */}
    <h3 class="section-title">Educational Information</h3>
    <div class="qualification-row form-row">
      <div class="form-group col-md-5">
        <input type="text" class="form-control" placeholder="Enter Qualification" />
      </div>
      <div class="form-group col-md-5">
        <input type="text" class="form-control" placeholder="Year of Passing" />
      </div>
      <div class="form-group col-md-2">
        <button type="button" class="btn btn-primary">+</button>
      </div>
    </div>

    {/* <!-- Professional Information --> */}
    <h3 class="section-title">Professional Information</h3>
    <div class="form-row">
      <div class="form-group col-md-12">
        <select class="form-control">
          <option>Enter Your Post</option>
        </select>
      </div>
      <div class="form-group col-md-6">
        <input type="text" class="form-control" placeholder="Total Experience (In Years)" />
      </div>
      <div class="form-group col-md-6">
        <input type="text" class="form-control" placeholder="Relevant Experience (In Years)" />
      </div>
      <div class="form-group col-md-5">
        <input type="text" class="form-control" placeholder="Enter Skills" />
      </div>
      <div class="form-group col-md-5">
        <input type="text" class="form-control" placeholder="Skill Level" />
      </div>
      <div class="form-group col-md-2">
        <button type="button" class="btn btn-primary">+</button>
      </div>
      <div class="form-group col-md-6">
        <input type="text" class="form-control" placeholder="Current Company" />
      </div>
      <div class="form-group col-md-6">
        <input type="text" class="form-control" placeholder="Current Designations" />
      </div>
      <div class="form-group col-md-4">
        <input type="text" class="form-control" placeholder="Current CTC" />
      </div>
      <div class="form-group col-md-4">
        <input type="text" class="form-control" placeholder="Expected CTC" />
      </div>
      <div class="form-group col-md-4">
        <input type="text" class="form-control" placeholder="CTC Negotiable ?" />
      </div>
      <div class="form-group col-md-6">
        <input type="text" class="form-control" placeholder="Employment Type" />
      </div>
      <div class="form-group col-md-6">
        <input type="text" class="form-control" placeholder="LinkedIn Profile" />
      </div>
      <div class="form-group col-md-6">
        <select class="form-control">
          <option>Time Availability</option>
        </select>
      </div>
      <div class="form-group col-md-6">
        <input type="text" class="form-control" placeholder="Reason for job change" />
      </div>
      <div class="form-group col-md-12">
  <label htmlFor="resumeUpload">Select Resume to Upload</label>
  <input type="file" class="form-control-file" id="resumeUpload" />
</div>

      <div class="form-group col-md-12">
        <textarea class="form-control" rows="4" placeholder="Write something..."></textarea>
      </div>
    </div>

    <button type="submit" class="btn btn-orange btn-submit">Submit</button>

  </form>
</div>

    </>
  )
}

export default ApplyForm
