import React from "react";
import { Link } from "react-router-dom";
const Login = () => {
  return (
    <>
      <div className="container login">
        <div className="login-form">
          <h2>Career Opportunities: Sign In</h2>
          <p>
            Already a registered user? Please Sign In
          </p>
          <h3>Enter your credentials to sign in to your account</h3>
          <form action="#">
            <div class="input-group">
              <span class="material-symbols-outlined">mail</span>
              <input
                type="text"
                id="email-phone"
                name="email-phone"
                placeholder="Email or Phone Number"
                required
              />
            </div>
            <div class="input-group">
              <span class="material-symbols-outlined">mail</span>
              <input
                type="password"
                id="password"
                name="password"
                placeholder="Password"
                required
              />
            </div>
            <Link className="nav-link forgot-password" to="/forgotpassword">
              Forgotten Password?
            </Link>
            <Link className="nav-link" to="/applicationform"><button type="submit" class="btn-signin">
              Sign In
            </button></Link>
          </form>
          <div class="register-link">
            <p> Not a registered user yet?</p>
            <h4>
              <Link className="nav-link" to="/createaccount">Create an account</Link> to apply for our career
              opportunities.
            </h4>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
